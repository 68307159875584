import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import InnerBackHeader from "./layouts/InnerBackHeader";
import BottomNav from "./layouts/BottomNav";
import Banner from "./components/Banner";
import HomeProduct from "./components/HomeProduct";
import HomeSectionBlock from "./components/HomeSectionBlock";
import HomeSectionBlockone from "./components/HomeSectionBlockone";
import Product from "./components/Product";
import { NavLink, Link, useParams } from "react-router-dom";
import { HomeApi } from "../apis/HomeApi";
import { CartApi } from "../apis/CartApi";
import * as Yup from "yup";
import { setCartId, getCartId, setUserId, getUserId } from "../Token";
import config from "../config";
import { useDispatch } from "react-redux";
import { fetchCartCount } from "../actions/cartActions";

function DeliveryCities() {
  // const state = useSelector((state) => state);
  const dispatch = useDispatch();
  //
  // console.log('state',state);

  const [cities, setCities] = useState([]);
  const [filter, setFilter] = useState("");


  let navigate = useNavigate();
  useEffect(() => {
    getCities();
  }, []);

  const getCities = () => {
    HomeApi.getCities()
      .then((res) => {
        if (res.data.status) {
          setCities(res.data.data);
        } else {
          setCities([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
    <InnerBackHeader isLogo={true} />

    

    <div className="col-xs-12 mb-15"
      style={{ height: "59px", background: "#C8C7CC" }}>
            <div className="row col-xs-12" style={{paddingLeft: "10px",  background: "white", marginTop:"9px", borderRadius: "5px" }}>
      <div className="col-xs-10 input-container no-padding">
        <input
          className="col-xs-12"
          type="text"
          placeholder="Search for delivery city"
          onChange={(e) => setFilter(e.target.value)}
          style={{borderBottom:"0px"}}
      
        />
      </div>
      <div className="col-xs-2 search-icon-container mt-10" >
        {/* <i className="fa fa-search" aria-hidden="true" /> */}

        <img
        alt="Search Icon"
        className="responsive-img search-icon"
        src={config.IMAGE_URL + '/common-images/search-icon.jpg'}
        />
      </div>
    </div>
    </div>
    

    <div className="block-floor-products block-floor-products-opt3 floor-products1 mt-20">
      <main className="site-main container-fluid">
        <div className="delivery-city-title">
        <span className="page-heading-title2">
          Blissmygift is delivering in 1250+ cities across India
        </span>
        </div>
        {/* <br></br> */}
        <br></br>

        {/* <div className="col-xs-12">
        <div className="row col-xs-12" style={{paddingLeft: "10px", borderBottom: '1px solid',  background: "white" }}>
  <div className="col-xs-10 input-container no-padding">
    <input
      className="col-xs-12"
      type="text"
      placeholder="Search city"
      onChange={(e) => setFilter(e.target.value)}
    />
  </div>
  <div className="col-xs-2 search-icon-container" >
    <i className="fa fa-search" aria-hidden="true" />
  </div>
</div>
</div> */}

        <div className="col s12">
          <div className="row">
            {cities
              .filter((city) =>
                city.city_name.toLowerCase().includes(filter.toLowerCase())
              )
              .map((city, i) => {
                return (
                  <div className="col s12 city-name cities" key={i}>
                    {city.city_name}
                  </div>
                );
              })}
          </div>
          <div className="nocityName adobeNoResult mb-15" />
        </div>
      </main>
      {/* end MAIN */}
    </div>
    {/* block -floor -products / floor 1*/}
    {/* block -floor -products / floor 1*/}
  </>
  );
}

export default DeliveryCities;
