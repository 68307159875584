import React, { useEffect, useState } from "react";
import config from "../../config";
import { HomeApi } from "../../apis/HomeApi";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { setCartId,getCartId,setUserId,getUserId } from "../../Token";
import BottomNav from '../layouts/BottomNav';
import ViewCartCount from '../components/ViewCartCount';


function Header() {

  const [categories, setCategories] = useState([]);
  const [opencategory, setOpenCategory] = useState([]);
  const [isMenuOpen, setMenuOpen] = useState(false);
 
  const [headerFixed, setHeaderFixed] = useState("");


  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const placeholderText = "Search cakes, flowers, gifts, etc.";


 
  useEffect(() => {
    getCategories();
    onScrollLoad();


    const intervalId = setInterval(() => {
      setPlaceholderIndex((prevIndex) => {
        const nextIndex = prevIndex < placeholderText.length * 2 - 1 ? prevIndex + 1 : 0;

        // Toggle between forward and backward typing
        return nextIndex;
      });
    }, 100); // Adjust the interval based on your preference

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount

  }, []);

 const typedPlaceholder = placeholderText
    .substring(0, Math.min(placeholderIndex, placeholderText.length));


  const onScrollLoad = (e) => {
    window.addEventListener('scroll', isSticky);
    return () => {
        window.removeEventListener('scroll', isSticky);
    };
  }

  const isSticky = (e) => {
    //const header = document.querySelector('.header-section');
    const scrollTop = window.scrollY;
    scrollTop >= 100 ? setHeaderFixed('header-fixed') : setHeaderFixed('');
};

  const getCategories = () => {
    HomeApi.getCategories()
      .then((res) => {
        if (res.data.status) {
          //console.log(res.data.data);
          setCategories(res.data.data);
        } else {
          setCategories([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function menuOpen(category_id) {

      if(category_id == opencategory) {
        setOpenCategory(null);
      } else {
        setOpenCategory(category_id);
      }
    

      
      //alert(category_id);
  };



  function menuOpenClose(val) {
      setMenuOpen(val);
  };

 

  

  return (
    <>
      {/* HEADER */}
      <header className="site-header header-opt-3 cate-show">
        {/* header-content */}
        <div className="header-content-info">
          <div className="">
            <div className="col-sm-12 no-padding header-info">
              <div className="header-info-div">
                <a href="https://api.whatsapp.com/send?phone=919322134554&text=Hi">
                  <span>
                    <i className="fa fa-phone" aria-hidden="true" /> /{" "}
                    <i className="fa fa-whatsapp" aria-hidden="true" /> +91 -
                    9322134554{" "}
                  </span>
                </a>
                {/*                <a href="https://www.blissmygift.com/contact-us"><i class="fa fa-envelope" aria-hidden="true"></i> Help</a>
         </span>  */}
                {/* <span className="headerhelptext">
                  <NavLink to="/delivery-cities">
                    <i className="fa fa-map-marker" aria-hidden="true" /> India
                </NavLink>
                </span> */}
                 <span className="headerhelptext">
                  <NavLink to="/customer-reviews">
                    <i className="fa fa-user" aria-hidden="true" /> Customer
                    Reviews
                  </NavLink>
                </span> 
              </div>
            </div>
          </div>
        </div>
        {/* header-content */}
        <div className=" search-mobile-home">
          <div className="">
            <div className="row">
              <div className={`logotopbottompadding ${headerFixed}`}>
                <div className="col-xs-2">
                  <span data-action="toggle-nav" onClick={() => menuOpenClose(true)} className="nav-toggle-menu">
                    <span />
                    <i aria-hidden="true" className="fa fa-bars" />
                  </span>
                </div>
                <div className="col-xs-6 no-padding">
                  <NavLink to="/">
                    <img
                      className="logo-left"
                      src={config.IMAGE_URL + '/logo.png'}
                      alt="Blissmygift"
                      width={130}
                    />
                  </NavLink>
                </div>
                <div className="col-xs-4 text-right">
                   <ViewCartCount/>
                  {/* <div>
                    <NavLink  to={getUserId() != '' && getUserId() != 'undefined' ?  '/profile/myaccount'  : "/login"}>
                      <img
                        src={config.IMAGE_URL + '/common-images/user-avatar.jpg'}
                        alt="Profile"
                        width={30}
                      />
                    </NavLink>
                  </div> */}
                </div>
              </div>
              <div className="block-search">
                <div className="block-title">
                  <span>Search</span>
                </div>
                <div className="block-content">
                  <div className="form-search">
                    {/* <form method="get" action="https://www.blissmygift.com/searchProduct"> */}
                    <NavLink  to="/search">
                      <div className="box-group">
                       <div className="col-xs-10">
                        <input
                          type="text"
                          autoComplete="off"
                          id="search-box"
                          className="form-control text-padding"
                          name="keyword"
                          // placeholder="Search  cakes,  flowers,  gifts, etc...."
                          placeholder={typedPlaceholder}
                          defaultValue=""
                        />
                        </div>
                        <div className="col-xs-2">
                          <img
                              alt="Search Icon"
                              className="responsive-img search-icon "
                              src={config.IMAGE_URL + '/common-images/search-icon.jpg'}
                            />
                        </div>
                        {/* <div id="suggesstion-box" /> */}
                        {/* <button className="btn btn-search" type="submit">
                          <i className="fa fa-search" aria-hidden="true" />
                        </button> */}
                      </div>
                    </NavLink>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* header-content */}
        <div className=" header-nav mid-header header-banner-bottom">
          <div className="container">
            <div className="box-header-nav">
              {/* menu */}
              <div className={`block-nav-menu ${isMenuOpen ? "has-open" : ""}`} >
                <div className="clearfix">
                  <span data-action="close-nav" onClick={() => menuOpenClose(false)} className="close-nav">
                    <span>close</span>
                  </span>
                </div>
                <ul className="ui-menu">
                  {/* <li>
               <a href="https://www.blissmygift.com/occasions/teachers-day" style="color: red; font-weight: bold;"> TEACHER'S DAY</a>
               </li> */}

                {categories.map((category) => {
                  // Check if the category has a direct link
                  if (category.is_direct_link) {
                    // If yes, render a single list item with a direct link
                    return (
                      <li key={category.id}>
                        <NavLink
                          to={`products/${category.custom_category_id}`}
                          className="category-menu-title"
                        >
                          {category.name}
                        </NavLink>
                      </li>
                    );
                  } else {
                    // If not, render the existing megamenu structure
                    return (
                      <li
                        className={`parent parent-megamenu ${
                          category.id === opencategory ? "open-submenu" : ""
                        }`}
                        key={category.id}
                      >
                        <a
                          href="javascript:void(0);"
                          className="category-menu-title cat_1"
                          onClick={() => menuOpen(category.id)}
                        >
                          {category.name}
                          <span className="nav-down-arrow">
                            <i className="fa fa-arrow-down" aria-hidden="true" />
                          </span>
                          <span className="toggle-submenu" />
                        </a>
                        <div className="megamenu drop-menu">
                          <ul>
                            {category.category_inner_title.map((subtTitle) => (
                              <li className="col-md-3" key={subtTitle.id}>
                                <div className="title">
                                  <a href="#">
                                    <span>{subtTitle.name}</span>
                                  </a>
                                </div>
                                <ul className="list-submenu">
                                  {subtTitle.sub_category.map((subCategory) => (
                                    <li key={subCategory.id}>
                                      <NavLink
                                        to={`products/${
                                          subCategory.is_direct_link
                                            ? subCategory.custom_category_id
                                            : subCategory.id
                                        }`}
                                      >
                                        {subCategory.name}
                                      </NavLink>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </li>
                    );
                  }
                })}

                </ul>
              </div>
              {/* menu */}
            </div>
          </div>
        </div>
      </header>
      

      <div className="navbar mobile-navbar-bottom" id="bottom-navbar">
        <NavLink
          className="mobile-navbar-bottom-link home-navbar-bottom-link"
          id="home-navbar-bottom-link"
          name="home-navbar-bottom-link"
          to="/"
        >
          <div>
            <img
              className="responsive-img home-outline"
              src={config.IMAGE_URL + '/icons/home.png'}
              width={20}
            />
          </div>
          <span className="home-mobile-nav-font">Home</span>
        </NavLink>
        <a
          className="mobile-navbar-bottom-link category-navbar-bottom-link"
          id="shop-navbar-bottom-link"
          name="shop-navbar-bottom-link"
          href="#"
          data-action="toggle-nav"
          onClick={() => menuOpenClose(true)}
        >
          <div>
            <img
              className="lazyload responsive-img shop-outline"
              src={config.IMAGE_URL + '/icons/category.png'}
              width={20}
            />
          </div>
          <span className="home-mobile-nav-font">Categories</span>
        </a>
        
        <NavLink
          className="mobile-navbar-bottom-link city-navbar-bottom-link"
          id="city-navbar-bottom-link"
          name="city-navbar-bottom-link"
          to="/delivery-cities"
        >
          <div>
            <img
              className="lazyload responsive-img  city-outline"
              src={config.IMAGE_URL + '/icons/city.png'}
              width={15}
            />
          </div>
          <span className="home-mobile-nav-font">City</span>
        </NavLink>
        <NavLink
          className="mobile-navbar-bottom-link account-navbar-bottom-link"
          id="account-navbar-bottom-link"
          name="account-navbar-bottom-link"
          to={"/profile"}
          // to={getUserId() != '' && getUserId() != 'undefined' ?  '/profile/myaccount'  : "/login"}
        >
          <div>
            <img
              className="lazyload responsive-img  account-outline"
              src={config.IMAGE_URL + '/icons/profile.png'}
              width={15}
            />
          </div>
          <span className="home-mobile-nav-font">Profile</span>
        </NavLink>
      </div>
      {/* end HEADER */}
    </>

  );
}

export default Header;
