import React, { useState } from "react";
import InnerBackHeader from "../layouts/InnerBackHeader";
import { NavLink } from "react-router-dom";
import Footer from "../layouts/Footer";
import Faq from "../profile/components/Faq";
function ContactUs() {
  const [active, setActive] = useState();

  const makePhoneCall = () => {
    const phoneNumber = "919322134554"; // Replace with the phone number you want to call
    window.location.href = `tel:${phoneNumber}`;
  };

  const openWhatsApp = () => {
    const phoneNumber = "919322134554";
    const message = "Hi, how are you?"; // Your predefined message
    window.location.href = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
  };

  const handleDropdown = (id) => {
    setActive(id);
    alert(id);
  };

  return (
    <>
      <InnerBackHeader isLogo={true} />

      <div
        //   style={{ color: "black", fontSize: 20, paddingTop: "4%" }}
        className="adbsubCategory center-align ft-20 mt-15    "
      >
        {" "}
        How can we help?{" "}
      </div>

      <div className="row">
        <div className="adbHelpCallUs contact-us col s6 ">
          <div className="font-30  mt-30">
            <i class="fa fa-phone" aria-hidden="true"></i>
          </div>
          <NavLink
            className="modal-trigger contact-us-title"
            // to={"tel:919322134554"}
            // target="blank"
            onClick={makePhoneCall}
          >
            MAKE A CALL
          </NavLink>
          <div className="contact-time-font">8AM to 11PM</div>
        </div>

        <div className="adbHelpWriteUs  contact-us col s6 ">
          <div style={{ borderLeft: "1px solid #C0C0C0", height: 86 }}>
            <div className="font-30 mt-30">
              <i class="fa fa-envelope" aria-hidden="true"></i>
            </div>
            <NavLink
              className="modal-trigger contact-us-title"
              href="/contact-us/write-to-us"
            >
              WRITE TO US
            </NavLink>
            <div className="contact-time-font">8AM to 11PM </div>
          </div>
        </div>
      </div>

      <div className="adbHelpChatNow w-100 contact-us col s12  mb-50">
        <div style={{ height: 86 }}>
          <div className="font-30 mt-30">
            <i class="fa fa-whatsapp" aria-hidden="true"></i>
          </div>
          <NavLink
            // to={"https://wa.me/919322134554"}
            className="modal-trigger contact-us-title"
            onClick={openWhatsApp}
          >
            CHAT ON WHATSAPP
          </NavLink>
          <div className="contact-time-font">8AM to 11PM </div>
        </div>
      </div>


      {/* <Faq/> */}


    

      <ul
        className="adbcollapsible collapsible"
        style={{ padding: 0, marginTop: "-5px" }}
      >
        <li>
          <a
            className="collapsible-header"
            style={{ position: "relative", borderBottom: 0 }}
            tabIndex={0}
            data-toggle="collapse"
            href="#dropDownOne"
            role="button"
            aria-expanded="false"
            aria-controls="dropDownOne"
          >
            <span
              style={{
                paddingLeft: "1%",
                fontSize: 16,
                color: "black",
                width: "90%",
              }}
            >
              Need help purchasing at Blissmygift?
            </span>
            <svg
              width={10}
              height={15}
              viewBox="0 0 16 27"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                WebkitTransform: "rotate(270deg)",
                transform: "rotate(270deg)",
                position: "absolute",
                right: 26,
                top: 15,
              }}
            >
              <path d="M16 23.207L6.11 13.161 16 3.093 12.955 0 0 13.161l12.955 13.161z" />
            </svg>
          </a>
          {/*   */}
          <div className="collapse pl-15" id="dropDownOne">
            <ul className=" inner-collap collapsible">
              <li>
                <a
                  className="collapsible-header"
                  style={{ borderBottom: 0, backgroundColor: "rgba(0,0,0,0)" }}
                  tabIndex={0}
                  data-toggle="collapse"
                  href="#sudropDownOne"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sudropDownOne"
                >
                  <span className="inner-header">
                    How to place an order at Blissmygift?
                  </span>
                </a>
                <div
                  className="  collapse pl-15"
                  id="sudropDownOne"
                >
                  <span>
                    {" "}
                    First of all, you have to register at Blissmygift if you are a new
                    customer. If you are an existing customer, then just login
                    and select the Delivery city or Pincode &amp; choose the
                    gifts to order.
                  </span>
                </div>
              </li>
              <li>
                <a
                  className="collapsible-header"
                  style={{ borderBottom: 0, backgroundColor: "rgba(0,0,0,0)" }}
                  tabIndex={0}
                  data-toggle="collapse"
                  href="#sudropDownTwo"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sudropDownTwo"
                >
                  <span className="inner-header">
                    What are the benefits of registering at Blissmygift?
                  </span>
                </a>
                <div
                  className=" collapse pl-15"
                  id="sudropDownTwo"
                >
                  <span>On registering with Blissmygift,you will get 10% off.</span>
                  <span>
                    As a registered member of Blissmygift, you don’t need to enter
                    your details every time for your order as it is already
                    saved in My Account.
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <a
            className="collapsible-header"
            style={{ position: "relative", borderBottom: 0 }}
            tabIndex={0}
            data-toggle="collapse"
            href="#dropDownTwo"
            role="button"
            aria-expanded="false"
            aria-controls="dropDownTwo"
          >
            <span
              style={{
                paddingLeft: "1%",
                fontSize: 16,
                color: "black",
                width: "90%",
              }}
            >
              Managing my Orders
            </span>
            <svg
              width={10}
              height={15}
              viewBox="0 0 16 27"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                WebkitTransform: "rotate(270deg)",
                transform: "rotate(270deg)",
                position: "absolute",
                right: 26,
                top: 15,
              }}
            >
              <path d="M16 23.207L6.11 13.161 16 3.093 12.955 0 0 13.161l12.955 13.161z" />
            </svg>
          </a>
          <div
            className="  collapse pl-15"
            id="dropDownTwo"
          >
            <ul className=" inner-collap collapsible">
              <li>
                <a
                  className="collapsible-header"
                  style={{
                    position: "relative",
                    borderBottom: 0,
                    backgroundColor: "rgba(0,0,0,0)",
                  }}
                  tabIndex={0}
                  data-toggle="collapse"
                  href="#subDropDown21"
                  role="button"
                  aria-expanded="false"
                  aria-controls="subDropDown21"
                >
                  <span className="inner-header">
                    How do I track the progress of my order?
                  </span>
                </a>
                <div
                  className="   collapse pl-15"
                  id="subDropDown21"
                >
                  <span>
                    You can Track your order by clicking on "TRACK ORDER" option
                    on Main Website page or click on the shared link:{" "}
                    <a href="https://www.Blissmygift.in/my-Blissmygift/orders/list">
                      https://www.Blissmygift.in/my-Blissmygift/orders
                    </a>
                  </span>
                </div>
              </li>
              <li>
                <a
                  className="collapsible-header"
                  style={{
                    position: "relative",
                    borderBottom: 0,
                    backgroundColor: "rgba(0,0,0,0)",
                  }}
                  tabIndex={0}
                  data-toggle="collapse"
                  href="#subDropDown22"
                  role="button"
                  aria-expanded="false"
                  aria-controls="subDropDown22"
                >
                  <span className="inner-header">
                    What if the recipient is not available when the delivery
                    person reaches there? Will you re-attempt delivery?
                  </span>
                </a>
                <div
                  className=" collapse pl-15"
                  id="subDropDown22"
                >
                  <span>
                    {" "}
                    We do not attempt to deliver again, but we would call
                    recipient and sender to know if we can deliver their order
                    to any neighbor or nearby place. Unable to do so will lead
                    to cancellation of the order and no refund will be made in
                    case of perishable products like cakes, chocolates and
                    flowers.
                  </span>
                </div>
              </li>
              <li>
                <a
                  className="collapsible-header"
                  style={{
                    position: "relative",
                    borderBottom: 0,
                    backgroundColor: "rgba(0,0,0,0)",
                  }}
                  tabIndex={0}
                  data-toggle="collapse"
                  href="#subDropDown23"
                  role="button"
                  aria-expanded="false"
                  aria-controls="subDropDown23"
                >
                  <span className="inner-header">
                    While placing the order,why do I see delivery - time
                    disabled?
                  </span>
                </a>
                <div 
                  className="collapse pl-15"
                  id="subDropDown23"
                >
                  <span>
                    {" "}
                    Sometimes we get a large number of orders for delivery at a
                    particular time slot. In this case, that particular time
                    slot is disabled to prevent taking any more orders. If you
                    need help in finding the time slot for that day, please
                    contact our customer support
                  </span>
                </div>
              </li>
              <li>
                <a
                  className="collapsible-header"
                  style={{
                    position: "relative",
                    borderBottom: 0,
                    backgroundColor: "rgba(0,0,0,0)",
                  }}
                  tabIndex={0}
                  data-toggle="collapse"
                  href="#subDropDown24"
                  role="button"
                  aria-expanded="false"
                  aria-controls="subDropDown24"
                >
                  <span className="inner-header">
                    What are Various Payment Options?
                  </span>
                </a>
                <div
                  className="collapse pl-15"
                  id="subDropDown24"
                >
                  <span>
                    {" "}
                    You can make your payment through net banking, Paytm,
                    Paypal, Debit Card, Credit Card, Mobikwik and Blissmygift Wallet.
                    Note: We don’t provide Cash on Delivery (COD) option.
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </li>

        <li>
          <a
            className="collapsible-header"
            style={{ position: "relative", borderBottom: 0 }}
            tabIndex={0}
            data-toggle="collapse"
            href="#dropDownThree"
            role="button"
            aria-expanded="false"
            aria-controls="dropDownThree"
          >
            <span
              style={{
                paddingLeft: "1%",
                fontSize: 16,
                color: "black",
                width: "90%",
              }}
            >
              Customized Orders
            </span>
            <svg
              width={10}
              height={15}
              viewBox="0 0 16 27"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                WebkitTransform: "rotate(270deg)",
                transform: "rotate(270deg)",
                position: "absolute",
                right: 26,
                top: 15,
              }}
            >
              <path d="M16 23.207L6.11 13.161 16 3.093 12.955 0 0 13.161l12.955 13.161z" />
            </svg>
          </a>
          <div
            className="collapse pl-15"
            id="dropDownThree"
          >
            <ul className="inner-collap collapsible">
              <li>
                <a
                  className="collapsible-header"
                  style={{
                    position: "relative",
                    borderBottom: 0,
                    backgroundColor: "rgba(0,0,0,0)",
                  }}
                  tabIndex={0}
                  data-toggle="collapse"
                  href="#subDropDown31"
                  role="button"
                  aria-expanded="false"
                  aria-controls="dropDown31"
                >
                  <span className="inner-header">
                    How to customize the photo on the cake?
                  </span>
                </a>
                <div
                  className="collapse pl-15"
                  id="dropDown31"
                >
                  <span>
                    {" "}
                    After you place your photo cake order, email us a picture to
                    be printed on the cake at{" "}
                    <a href="mailto:info@Blissmygift.in">info@Blissmygift.in</a> along with
                    order number.{" "}
                  </span>
                </div>
              </li>
              <li>
                <a
                  className="collapsible-header"
                  style={{
                    position: "relative",
                    borderBottom: 0,
                    backgroundColor: "rgba(0,0,0,0)",
                  }}
                  tabIndex={0}
                  data-toggle="collapse"
                  href="#subDropDown32"
                  role="button"
                  aria-expanded="false"
                  aria-controls="subDropDown32"
                >
                  <span className="inner-header">
                    How to personalize photo mugs or photo frames?
                  </span>
                </a>
                <div
                  className="collapse pl-15"
                  id="dropDown32"
                >
                  <span>
                    {" "}
                    After you place your personalized mug or photo frame order,
                    email us a picture to be printed over the mug or photo frame
                    at <a href="mailto:info@Blissmygift.in">info@Blissmygift.in</a> along
                    with order number.{" "}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </li>

        <li>
          <a
            className="collapsible-header"
            style={{ position: "relative", borderBottom: 0 }}
            tabIndex={0}
            data-toggle="collapse"
            href="#dropDownFour"
            role="button"
            aria-expanded="false"
            aria-controls="dropDownFour"
          >
            <span
              style={{
                paddingLeft: "1%",
                fontSize: 16,
                color: "black",
                width: "90%",
              }}
            >
              Inquiries about charges
            </span>
            <svg
              width={10}
              height={15}
              viewBox="0 0 16 27"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                WebkitTransform: "rotate(270deg)",
                transform: "rotate(270deg)",
                position: "absolute",
                right: 26,
                top: 15,
              }}
            >
              <path d="M16 23.207L6.11 13.161 16 3.093 12.955 0 0 13.161l12.955 13.161z" />
            </svg>
          </a>
          <div
            className="collapse pl-15"
            id="dropDownFour"
          >
            <ul className="inner-collap collapsible">
              <li>
                <a
                  className="collapsible-header"
                  style={{ borderBottom: 0, backgroundColor: "rgba(0,0,0,0)" }}
                  tabIndex={0}
                  data-toggle="collapse"
                  href="#subDropDown41"
                  role="button"
                  aria-expanded="false"
                  aria-controls="subDropDown41"
                >
                  <span className="inner-header">
                    Are there any delivery charges?
                  </span>
                </a>
                <div
                  className="  collapse"
                  id="subDropDown41"
                >
                  <span>
                    {" "}
                    Delivery charges varies from location to location. When you
                    provide pin code of the delivery address, our website will
                    show you the delivery charges for that particular location.
                    Delivery charges ranges between Rs. 0 – 300 rupees.
                  </span>
                </div>
              </li>
              <li>
                <a
                  className="collapsible-header"
                  style={{ borderBottom: 0, backgroundColor: "rgba(0,0,0,0)" }}
                  tabIndex={0}
                  data-toggle="collapse"
                  href="#subDropDown42"
                  role="button"
                  aria-expanded="false"
                  aria-controls="subDropDown42"
                >
                  <span className="inner-header">
                    Are there any hidden charges (GST) when I make a purchase on
                    Blissmygift?
                  </span>
                </a>
                <div
                  className="collapse pl-15"
                  id="subDropDown42"
                >
                  <span>
                    There are NO hidden charges when you make a purchase on
                    Blissmygift. The prices listed for all the items are final and
                    all-inclusive. The price you see is exactly what you need to
                    pay. Delivery charges may be extra depending on the location
                    and other parameters.
                  </span>
                </div>
              </li>
              <li>
                <a
                  className="collapsible-header"
                  style={{ borderBottom: 0, backgroundColor: "rgba(0,0,0,0)" }}
                  tabIndex={0}
                  data-toggle="collapse"
                  href="#subDropDown43"
                  role="button"
                  aria-expanded="false"
                  aria-controls="subDropDown43"
                >
                  <span className="inner-header">
                    Does Blissmygift provides midnight delivery as well? Is there any
                    extra charge for it?
                  </span>
                </a>
                <div
                  className="collapse pl-15"
                  id="subDropDown43"
                >
                  <span>
                    Yes, we do provide midnight delivery. The midnight delivery
                    time is from 11:00 PM to 11:59 PM. Although we try our best
                    to deliver order as close to 23:59 as possible, it is always
                    suggested to have midnight orders placed well on time. We
                    charge Rs. 250 extra for midnight delivery.
                  </span>
                </div>
              </li>
              <li>
                <a
                  className="collapsible-header"
                  style={{ borderBottom: 0, backgroundColor: "rgba(0,0,0,0)" }}
                  tabIndex={0}
                  data-toggle="collapse"
                  href="#subDropDown44"
                  role="button"
                  aria-expanded="false"
                  aria-controls="subDropDown44"
                >
                  <span className="inner-header">
                    What are Convenience Charges?
                  </span>
                </a>
                <div
                  className=" collapse pl-15"
                  id="subDropDown44"
                >
                  <span>
                    {" "}
                    "Convenience Fee" is a charge levied by Blissmygift on certain
                    orders, for the services rendered by Blissmygift on the platform.
                    It also includes some percentage of charges applied by banks
                    for facilitating online payments.
                  </span>
                </div>
              </li>
              <li></li>
            </ul>
          </div>
        </li>

        <li>
          <a
            className="collapsible-header"
            style={{ position: "relative", borderBottom: 0 }}
            tabIndex={0}
            data-toggle="collapse"
            href="#dropDownFive"
            role="button"
            aria-expanded="false"
            aria-controls="dropDownFive"
          >
            <span
              style={{
                paddingLeft: "1%",
                fontSize: 16,
                color: "black",
                width: "90%",
              }}
            >
              Special Discounts and Deals at Blissmygift
            </span>
            <svg
              width={10}
              height={15}
              viewBox="0 0 16 27"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                WebkitTransform: "rotate(270deg)",
                transform: "rotate(270deg)",
                position: "absolute",
                right: 26,
                top: 15,
              }}
            >
              <path d="M16 23.207L6.11 13.161 16 3.093 12.955 0 0 13.161l12.955 13.161z" />
            </svg>
          </a>
          <div
            className=" collapse pl-15"
            id="dropDownFive"
          >
            <ul className="inner-collap collapsible ">
              <li>
                <a
                  className="collapsible-header"
                  style={{
                    position: "relative",
                    borderBottom: 0,
                    backgroundColor: "rgba(0,0,0,0)",
                  }}
                  tabIndex={0}
                  data-toggle="collapse"
                  href="#subDropDown51"
                  role="button"
                  aria-expanded="false"
                  aria-controls="subDropDown51"
                >
                  <span className="inner-header">
                    Does Blissmygift provide discounts and deals on occasions?
                  </span>
                </a>
                <div
                  className=" collapse pl-15"
                  id="subDropDown51"
                >
                  <span>
                    {" "}
                    Yes, we provide several discounts and deals on occasions.{" "}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </li>

        <li>
          <a
            className="collapsible-header"
            style={{ position: "relative", borderBottom: 0 }}
            tabIndex={0}
            data-toggle="collapse"
            href="#dropDownSix"
            role="button"
            aria-expanded="false"
            aria-controls="dropDownSix"
          >
            <span
              style={{
                paddingLeft: "1%",
                fontSize: 16,
                color: "black",
                width: "90%",
              }}
            >
              Expected Delivery Date of Courier Products
            </span>
            <svg
              width={10}
              height={15}
              viewBox="0 0 16 27"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                WebkitTransform: "rotate(270deg)",
                transform: "rotate(270deg)",
                position: "absolute",
                right: 26,
                top: 15,
              }}
            >
              <path d="M16 23.207L6.11 13.161 16 3.093 12.955 0 0 13.161l12.955 13.161z" />
            </svg>
          </a>
          <div
            className="collapse pl-15"
            id="dropDownSix"
          >
            <span>
              Courier products will be shipped within 2-3 business days from the
              order placed date. For Tracking number, you can contact our
              customer service or can email us at{" "}
              <a href="mailto:info@Blissmygift.in">info@Blissmygift.in</a> .{" "}
            </span>
          </div>
        </li>

        <li>
          <a
            className="collapsible-header"
            style={{ position: "relative", borderBottom: 0 }}
            tabIndex={0}
            data-toggle="collapse"
            href="#dropDownSeven"
            role="button"
            aria-expanded="false"
            aria-controls="dropDownSeven"
          >
            <span
              style={{
                paddingLeft: "1%",
                fontSize: 16,
                color: "black",
                width: "90%",
              }}
            >
              Cancellation and Refund Policy
            </span>
            <svg
              width={10}
              height={15}
              viewBox="0 0 16 27"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                WebkitTransform: "rotate(270deg)",
                transform: "rotate(270deg)",
                position: "absolute",
                right: 26,
                top: 15,
              }}
            >
              <path d="M16 23.207L6.11 13.161 16 3.093 12.955 0 0 13.161l12.955 13.161z" />
            </svg>
          </a>
          <div
            className=" collapse pl-15"
            id="dropDownSeven"
          >
            <p style={{ marginTop: 5 }}>
              {" "}
              Orders are processed in time slots and we accept cancellation
              requests with reference to time slot selected by the customer in
              order placed. Consider the conditions stated below{" "}
            </p>
            <ul className="bullet">
              <p></p>
              <li>
                If the cancellation request comes to Blissmygift before 24 hours to
                the start of time slot selected - 10% of the order value is
                charged as cancellation charges. 90% refund to Bank Account.
                NOTE - 100% refund can be requested in Blissmygift wallet, where we
                waive off cancellation charges.
              </li>
              <p />
              <p></p>
              <li>
                If the cancellation request comes to Blissmygift between 12 - 24 hours
                before the start of time slot selected then - 20% of the order
                value will be deducted as cancellation charges. 80% refund to
                Bank Account. NOTE - 100% refund can be requested in Blissmygift
                wallet, where we waive off cancellation charges.
              </li>
              <p />
              <p> </p>
              <li>
                If cancellation request comes to Blissmygift within 12 hours to the
                start of time slot selected - 100% of the order value will be
                deducted as cancellation charges. 0% refund to Bank account or
                0% to Blissmygift wallet applicable
              </li>
              <p />
              <p />
              <li>
                Only one attempt for delivery will be made. Please confirm
                availability of recipient before choosing a delivery time. If
                the recipient is not available at the time of delivery, the
                second attempt will not be made and order will be canceled. No
                refund will be made in such case.
              </li>
              <p />
            </ul>
            <p>
              For more information you can check our{" "}
              <a href="/s/terms-and-conditions#refundPolicy ">Refund Policy</a>{" "}
              and{" "}
              <a href="/s/terms-and-conditions#cancelPolicy ">
                {" "}
                Cancellation Policy
              </a>{" "}
              pages.
            </p>
          </div>
        </li>
      </ul>

      {/* Connect */}

      <div className="row mb-50" style={{ backgroundColor: "white" }}>
        <div
          style={{
            paddingTop: "4%",
            paddingBottom: "2%",
            marginTop: 15,
            fontSize: 20,
          }}
          className="contact-us-ph5 center-align"
        >
          Connect with us
        </div>
        <div className="col-xs-2 text-center ft-40">
          <NavLink to={"https://www.instagram.com/blissmygift/"} target="blank">
            <i className="fa fa-instagram" aria-hidden="true" />
          </NavLink>
        </div>
        <div className="col-xs-2 text-center  ft-40">
          <NavLink
            to={"https://twitter.com/blissmygifts"}
            target="blank"
            className="twitter"
          >
            <i className="fa fa-twitter" aria-hidden="true" />
          </NavLink>
        </div>
        <div className="col-xs-2 text-center ft-40">
          <NavLink
            to={"https://www.linkedin.com/in/blissmygift-giftshop-a768701b3"}
            target="blank"
            className="linkdin"
          >
            <i className="fa fa-linkedin-square" aria-hidden="true" />
          </NavLink>
        </div>

        <div className="col-xs-2 text-center ft-40">
          <NavLink
            to={"https://in.pinterest.com/blissmygift/"}
            target="blank"
            className="pinterest"
          >
            <i className="fa fa-pinterest-square" aria-hidden="true" />
          </NavLink>
        </div>

        <div className="col-xs-2 text-center ft-40">
          <NavLink
            to={"https://www.youtube.com/channel/UCsZ3el0Mf67__-VWEF6LUBQ"}
            target="blank"
            className="youtube"
            cl
          >
            <i className="fa fa-youtube" aria-hidden="true" />
          </NavLink>
        </div>
        <div className="col-xs-2 text-center ft-40">
          <NavLink
            to={"https://www.facebook.com/blissmygift"}
            target="blank"
            className="facebook"
          >
            <i className="fa fa-facebook-square" aria-hidden="true" />
          </NavLink>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </>
  );
}
export default ContactUs;
