import React, { useEffect, useState } from "react";

import { NavLink, Link, useParams, useNavigate } from "react-router-dom";
import config from "../../config";
import ViewCartCount from '../components/ViewCartCount';


function InnerBackHeader(props) {
  let navigate = useNavigate();

  const[headerFixed, setHeaderFixed] = useState('');

  useEffect (()=>{
    onScrollLoad()
  },[]);


  const onScrollLoad = (e) => {
    window.addEventListener('scroll', isSticky);
    return () => {
        window.removeEventListener('scroll', isSticky);
    };

  }

  const isSticky = (e) => {
    const scrollTop = window.scrollY;
    scrollTop >= 0.01 ? setHeaderFixed('header-fixed') : setHeaderFixed('');
};



  
  return ( 
    <>
   
    {/* HEADER */}
    <header className="site-header cate-show bg-white-imp">
      
      <div className={`header-content  ${headerFixed}`}>
        <div className="">
          <div className="row">
            <div className="logotopbottompadding">
             
             
              <div className="col-xs-2">
                <NavLink onClick={() => navigate(-1)}>
                <img 
                src={config.IMAGE_URL + '/icons/arrow.png'}
                width={28}
                /> 
                </NavLink>
               
              </div>

              {props.isLogo &&
              <div className="col-xs-6 no-padding">
                  <NavLink to="/">
                    <img
                      className="logo-left"
                      src={config.IMAGE_URL + '/logo.png'}
                      alt="Blissmygift"
                      width={130}
                    />
                  </NavLink>
                </div>
                 }

                {!props.isLogo &&
                <div className="col-xs-6 no-padding header-product-title" >
                  {props.title && (
                  <>
                  <div className={`font-13 ${props.totalProduct > 0 ? "" : "title-margin-set"}`}>{props.title}</div>
                  </>
                  )}
                  {props.totalProduct > 0 && (
                  <>
                  <div  className="font-13">{props.totalProduct} Items</div>
                  </>
                  )}
                </div>
               }

               


             
              <div className="col-xs-2 text-right no-padding">
              <NavLink to="/search">
              <img
                        src={config.IMAGE_URL + '/common-images/search-icon-app.png'}
                        alt="Cart"
                        width={25}
                      />
              </NavLink>
              {/* <i class="fa fa-search" style={{fontSize:"20px"}} aria-hidden="true"></i> */}
              </div>
             


              <div className="col-xs-2 text-right">
                <ViewCartCount/>
              </div>


            </div>
            <br></br><br></br>
          </div>
        </div>
      </div>
      {/* header-content */}
      
    </header>
    {/* end HEADER */}
    </>

  );
}

export default InnerBackHeader;
