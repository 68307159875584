import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate,NavLink, Link } from 'react-router-dom';
import InnerBackHeader from './../layouts/InnerBackHeader';
import BottomNav from './../layouts/BottomNav';
import { HomeApi } from "../../apis/HomeApi";
import * as Yup from "yup";
import { AuthApi } from "../../apis/AuthApi";
import { getTempUserData,setTempUserData,setUserInfo,setUserId,setCartId, getCartId } from "../../Token";
import config from "../../config";
import Facilities from "../layouts/Facilities";
import Loader from "../layouts/Loader";


function Registration() {

    const [activeData, setactiveData] = useState({});
    const [countryCode, setCountryCode] = useState([]);
    const [inputtype, setinputtype] = useState('email');
    const urlParam = new URLSearchParams(window.location.search); 
    const redirectTo = urlParam.get('redirectTo');
    const [loader, setLoader] = useState(false);

    let navigate = useNavigate(); 
    let userTempData = JSON.parse(getTempUserData());
    useEffect(() => {
        getCountryCode(activeData);
      }, []);

    const getCountryCode = (activeData) => {
        setLoader(true);
        HomeApi.getCountryCodeData(activeData)
          .then((res) => {
            if (res.data.status) {
                setCountryCode(res.data.data);
            } else {
                setCountryCode([]);
            }
            setLoader(false);
          })
          .catch((err) => {
            setLoader(false);
            console.log(err);
          });
    };

    const activeTab = (tabname) => {
        setinputtype(tabname);
    };


    const formik = useFormik({
        initialValues: {
          name: "",
          email: userTempData.email,
          country_code: userTempData.country_code,
          mobile: userTempData.mobile,
          password: "",
    
        },
        validationSchema: Yup.object().shape(
          {
            name: Yup.string().required("Please enter name"),
            email: Yup.string().required("Please enter email"),
            mobile: Yup.number()
            .typeError("Enter a valid phone number")
            .positive("Phone number can't start with a minus.")
            .integer("A phone number can't include a decimal point")
            .min(7, 'Enter a valid phone number')
            .required("Please enter mobile"),
            password: Yup.string().required("Please enter password"),
          },
          []
        ),
        onSubmit: (values) => {
            // setLoader(true);
            console.log(values)
           
          const data = new FormData();
          data.append("name", values.name);
          data.append("email", values.email);
          data.append("country_code", values.country_code);
          data.append("mobile", values.mobile);
          data.append("password", values.password);
          data.append("cart_id", getCartId());
        //   return false;
          AuthApi.Registration(data)
            .then((res) => {
                console.log(res)
               
              if (res.data.status) {
                    //setTempUserData("");
                    setUserInfo(JSON.stringify(res.data.data.user));
                    setUserId(res.data.data.user.id);
                    setCartId(res.data.data.cart_id);
                    // let path = `/`+res.data.data.redirect; 
                    var path = '/'; 
                    if(redirectTo == 'cart') {
                        var path = '/view-cart';     
                    }
                    else if(redirectTo == 'checkout') {
                        var path = '/checkout';     
                    }
                    navigate(path);
              } else {
                alert(res.data.message);
                //setErrorMsg(res.data.message);
              }
              setLoader(false);
            })
            .catch((error) => {
                
                setLoader(false);
                
            //  console.log('values',error.messsage);
            //   console.log('responsedata',error.response);
             console.log("error", error.email);
            });
        },
      });

    return (
        <>
            <InnerBackHeader   isLogo={true}/>

            <>
            {loader && <Loader />}
                <main className="site-main">
                    <div className="columns ">
                            <div className="row ">
                                <div className="col-sm-12 mt-30">
                                    <div className="box-authentication height-650" >
                                        <div className="login-container">
                                            <div className="row">
                                                <div className="width  l12 m12 s12 no-padding">
                                                    <div className="login-box">
                                                        <h4
                                                            className="center-align  login-title text-bold"
                                                        >
                                                          Sign Up

                                                        </h4>
                                                        {/* <div classname="jss39" style={{ textAlign: "center", color: "#008539" }}>
                                                        Welcome, we are glad you are here!
                                                        </div> */}
                                                        
                                                        <form
                                                         id="registrationForm"
                                                        onSubmit={formik.handleSubmit}
                                                        >
                                                       
                                                            <fieldset className="login-input-container left-align signup_wrapper">
                                                                


                                                                <div
                                                                    className="row ck-login-email-wrapper active"
                                                                >
                                                                    <div className="input-field  s12 inputField">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Enter Your Name"
                                                                            name="name"
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            value={formik.values.name}
                                                                        />
                                                                         {formik.touched.name &&
                                                                    formik.errors.name ? (
                                                                    <p className="text-danger text-small text-left">
                                                                    {formik.errors.name}
                                                                    </p>
                                                                    ) : null}
                                                                    </div>
                                                                   
                                                                </div>
                                                               
                                                                <div
                                                                    className="row ck-login-email-wrapper active"
                                                                >
                                                                    <div className="input-field  s12 inputField">
                                                                        <input
                                                                            id="email"
                                                                            type="text"
                                                                            placeholder="Enter Your Email"
                                                                            name="email"
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            value={formik.values.email}
                                                                        />
                                                                         {formik.touched.email &&
                                                                    formik.errors.email ? (
                                                                    <p className="text-danger text-small text-left">
                                                                    {formik.errors.email}
                                                                    </p>
                                                                    ) : null}
                                                                    </div>
                                                                   
                                                                </div>
                                                              
                                                                <div
                                                                    id="phoneNumberTab"
                                                                    className=" row ck-login-email-wrapper"
                                                                >
                                                                    <div className="inputField">
                                                                        <div className="input-field  col s3 inputvalidate no-padding">
                                                                            <select
                                                                                className="browser-default"
                                                                                id="country_code"
                                                                                name="country_code" 
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                value={formik.values.country_code}
                                                                            > 
                                                                            {countryCode?.map((item, i) => (  
                                                                            <option value={item.country_code} >+{item.country_code}</option>
                                                                            ))}
                                                                            </select>
                                                                            {formik.touched.country_code &&
                                                                            formik.errors.country_code ? (
                                                                            <p className="text-danger text-small text-left">
                                                                            {formik.errors.country_code}
                                                                            </p>
                                                                            ) : null}
                                                                        </div>
                                                                        
                                                                        <div className="input-field  col s9 inputvalidate no-padding">
                                                                            <input
                                                                                id="contact_no"
                                                                                name="mobile"
                                                                                type="tel"
                                                                                minLength={4}
                                                                                placeholder="Enter Your Phone No."
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                value={formik.values.mobile}
                                                                            />
                                                                            {formik.touched.mobile &&
                                                                            formik.errors.mobile ? (
                                                                            <p className="text-danger text-small text-left">
                                                                            {formik.errors.mobile}
                                                                            </p>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                               


                                                                <div
                                                                    id="emailTab"
                                                                    className="row ck-login-email-wrapper active"
                                                                >
                                                                    <div className="input-field  s12 inputField">
                                                                        <input
                                                                            id="password"
                                                                            type="password"
                                                                            placeholder="Enter Your Password"
                                                                            name="password"
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            value={formik.values.password}
                                                                        />
                                                                           {formik.touched.password &&
                                                                    formik.errors.password ? (
                                                                    <p className="text-danger text-small text-left">
                                                                    {formik.errors.password}
                                                                    </p>
                                                                    ) : null}
                                                                    </div>
                                                                 
                                                                </div>

                                                                <div className="row fl-remove">
                                                                    <div className=" s12">
                                                                        <button
                                                                            id="login-continue"
                                                                            type="submit"
                                                                            className="submitBtn get-password-btn waves-effect waves-light btn"
                                                                            
                                                                        >
                                                                            CONTINUE
                                                                        </button>
                                                                    </div>
                                                                    {/* <p
                                                                        className="signupAlign forgot-btn"
                                                                      
                                                                    >
                                                                        {" "}
                                                                        <NavLink
                                                                            className="forpass login-create-account-link modal-trigger forgotPwdM"
                                                                            to="/forgot-password"
                                                                            style={{ color: "#1794F4", fontWeight: 500 }}
                                                                        >
                                                                            Forgot Password
                                                                        </NavLink>
                                                                    </p> */}
                                                                </div>
                                                               
                                                               <Facilities/>
                                                            </fieldset>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </main>
            </>


            {/* end MAIN */}

            {/* block -floor -products / floor 1*/}
            {/* block -floor -products / floor 1*/}
        </>


    );
}

export default Registration;
