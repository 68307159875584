import React, { useState } from 'react';

function LogoutConfirmationModal({handleLogoutModal}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const closeLogoutModal = () => {
    // Perform logout action
    handleLogoutModal(false);
  };
  const LogoutUser = () => {
    // Perform logout action
    handleLogoutModal(true);
  };

  return (
    <>
      <div
        className="modal-backdrop"
        onClick={closeLogoutModal}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent black
          zIndex: 1050, // ensure it's above other content
        }}
      ></div>

      <div
        style={{
          display: "block",
          marginTop: "-200px",
          width: "88%",
          zIndex: 1051, // ensure it's above the backdrop
        }}
        className="modal"
        id="exampleModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div
            className="modal-content"
            style={{
              padding: "3px",
              marginTop: "14px",
              marginBottom: "34px",
            }}
          >
            <div className="modal-header">
              <div className="row">
                <div className="col-xs-6">
                  <h4
                    className="modal-title ft-bolader"
                    id="exampleModalLongTitle"
                  >
                    Logout
                  </h4>
                </div>
                <div className="col-xs-6">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span
                      aria-hidden="true "
                      className="modal-title ft-bolader"
                      onClick={closeLogoutModal}
                    >
                      ×
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-body ft-15">
              Are you sure you want to logout?
            </div>
            <div className="modal-footer row no-padding">
              <div className="col-xs-6">
                <button
                  type="button"
                  className="btn btn-secondary width-100"
                  data-dismiss="modal"
                  onClick={closeLogoutModal}
                >
                  No
                </button>
              </div>
              <div className="col-xs-6">
                <button
                  type="button"
                  className="btn btn-primary width-100"
                  onClick={LogoutUser}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
}

export default LogoutConfirmationModal;
