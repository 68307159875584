import React, { useEffect, useState } from "react";
import InnerBackHeader from "../../layouts/InnerBackHeader";
import { useDispatch } from "react-redux";
import Loader from "../../layouts/Loader";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "../../../Token";

function OrderSuccess(props) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState();
  const navigate = useNavigate();
  const[userInfoString, setUserInfoString ]   =  useState(getUserInfo()); 
  const userInfo = JSON.parse(userInfoString); 
  console.log("userInfo.firstname",userInfo.firstname) 
  


  const NavToOrderList = () => {
    
    navigate("/order-list")

  
  }



  return (
    <>
      <InnerBackHeader title="My Cart" isLogo={true} />
      {loader && <Loader />}
      <div className="block-floor-products block-floor-products-opt3 floor-products1">
        <>
          <main className="site-main text-center mt-50">
            <div className="ft-65 text-success"> 
            <i class="fa fa-check-circle-o" aria-hidden="true" ></i>

            </div>

            <div className="container">
            <div className="">
                <div className="-md-6 offset-md-3">
                <div className="order-confirmation">
                    <h1>Hey {userInfo.firstname},</h1>
                    <p>Your Order is Confirmed!</p>
                    <p>
                    We'll send you a shipping confirmation email as soon as your order
                    ships.
                    </p>
                    
                   <div className="check-status-btn btn btn-primary" 
                   onClick={NavToOrderList}  > 
                    CHECK STATUS
                    </div>
                </div>
                </div>
            </div>
            </div>

            
        
            
               
           
          </main>
          {/* end MAIN */}
        </>
      </div>
      {/* block -floor -products / floor 1*/}
      {/* block -floor -products / floor 1*/}
    </>
  );
}

export default OrderSuccess;
