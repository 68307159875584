
import React from "react"
import InnerBackHeader from "../pages/layouts/InnerBackHeader";
import Footer from "../pages/layouts/Footer";




function PrivacyPolicy() {


    return (

        <>
         <InnerBackHeader isLogo={true} />
        <div className="wrapper">
  <div id="wrapper">
    {/* MAIN */}
    <main className="site-main  home-product-main-div viewcartpadding">
      {/* Block  Breadcrumb*/}
      <div className="row">
     
        {/* Main Content */}
        <h2 className="page-heading">
          <span className="page-heading-title2">Privacy Policy</span>
        </h2>
        <div className="divider" />
        <p className="text-justify">
          We understand gifts are best when they come as surprise. Your privacy
          is very important to us. That's why we insist upon the highest
          standards for secure transactions and customer information privacy. We
          do not publish, sell or rent your personal information to third
          parties for their marketing purposes without your explicit consent.
          Please read this privacy policy to learn more about the ways in which
          we use and protect your personal information.
        </p>
        <p className="text-justify">
          <strong>Note:</strong> Our privacy policy is subject to change at any
          time without notice. To make sure you are aware of any changes, please
          review this policy periodically.
        </p>
        <p className="text-justify">
          <b>
            By visiting this Website you agree to be bound by the terms and
            conditions of this Privacy Policy. If you do not agree please do not
            use or access our Website.
          </b>
        </p>
        <p className="text-justify">
          By mere use of the Website, you expressly consent to our use and
          disclosure of your personal information in accordance with this
          Privacy Policy. This Privacy Policy is incorporated into and subject
          to the Terms of Use.
        </p>
        <p className="text-justify">
          The privacy practices of this statement apply to our services
          available under the domain and subdomains of www.blissmygift.com
          Blissmygift (the "Site") and apply generally to our parent,
          affiliates, subsidiaries or joint venture websites. By visiting this
          website you agree to be bound by the terms and conditions of this
          Privacy Policy. If you do not agree please do not use or access our
          Site.{" "}
        </p>
        <p className="text-justify">
          This Privacy Policy describes the information, as part of the normal
          operation of our services, we collect from you and what may happen to
          that information. Although this policy may seem long, we have prepared
          a detailed policy because we believe you should know as much as
          possible about Blissmygift?s practices so that you can make informed
          decisions. As described in this Privacy Policy and, subject to the
          requirements of applicable law, we strive to provide a consistent set
          of privacy practices throughout Blissmygift.{" "}
        </p>
        <p>
          By accepting the Privacy Policy and the User Agreement in
          registration, you expressly consent to our use and disclosure of your
          personal information in accordance with this Privacy Policy. This
          Privacy Policy is incorporated into and subject to the terms of the
          User Agreement. This Privacy Policy is effective upon acceptance in
          registration for new registering users.
        </p>
        <h2 className="tc-heading">Your Privacy - Our Commitment</h2>
        <p className="text-justify">
          At Blissmygift, we are extremely proud of our commitment to protect
          your privacy. We value your trust in us. We will work hard to earn
          your confidence so that you can enthusiastically use our services and
          recommend us to friends and family. Please read the following policy
          to understand how your personal information will be treated as you
          make full use of our Site.
        </p>
        <h2 className="tc-heading">Information we collect</h2>
        <p className="text-justify">
          When you use our Site, we collect and store your personal information.
          Our primary goal in doing so is to provide a safe, efficient, smooth
          and customized experience. This allows us to provide services and
          features that most likely meet your needs, and to customize our Site
          to make your experience safer and easier. Importantly, we only collect
          personal information about you that we consider necessary for
          achieving this purpose.
          <br />
          <br />

          <div className="text-justify">
          In general, you can browse the Site without telling us who you are or
          revealing any personal information about yourself. Once you give us
          your personal information, you are not anonymous to us. To fully use
          our Site, you will need to register using our online registration
          form, where you may be required to provide us with your name, date of
          birth, contact number, email id, user id, password, residence / place
          of business information, billing information, shipping information,
          bank account details and other personal information as indicated on
          the forms throughout the Site. Where possible, we indicate which
          fields are mandatory and which fields are optional. You always have
          the option to not provide information by choosing not to use a
          particular service or feature on the Site.
          </div>
          <br />
          <br />
          <div className="text-justify">
          We may automatically track certain information about you based upon
          your behavior on our site. We use this information to do internal
          research on our users' demographics, interests, and behavior to better
          understand, protect and serve our users. This information is compiled
          and analyzed on an aggregated basis. This information may include the
          URL that you just came from (whether this URL is on our site or not),
          which URL you next go to (whether this URL is on our site or not),
          your computer browser information, and your IP address. Further, this
          information may also comprise of your friends, your interests, your
          friends likes, your and your friend?s activity on social networks.
          </div>
          <br />
          <br />
          <div className="text-justify">
          We use data collection devices such as "cookies" on certain pages of
          the Site to help analyze our web page flow, measure promotional
          effectiveness, and promote trust and safety. "Cookies" are small files
          placed on your hard drive that assist us in providing our services. We
          offer certain features that are only available through the use of a
          "cookie". We also use cookies to allow you to enter your password less
          frequently during a session. Cookies can also help us provide
          information that is targeted to your interests. Most cookies are
          "session cookies," meaning that they are automatically deleted from
          your hard drive at the end of a session. You are always free to
          decline our cookies if your browser permits, although in that case you
          may not be able to use certain features on the Site and you may be
          required to reenter your password more frequently during a session.
          </div>
          <br />
          <br />
          <div className="text-justify">
          Additionally, you may encounter "cookies" or other similar devices on
          certain pages of the Site that are placed by third parties. For
          example, if you view a web page created by a user, there may be a
          "cookie" placed within that web page. We do not control the use of
          cookies by third parties. 
          </div>
          <br />
          <br />
          <div className="text-justify">
          If you send us personal correspondence, such as emails or letters, or
          if other users or third parties send us correspondence about your
          activities or postings on the Site, we may collect such information
          into a file specific to you.
          </div>
        </p>
        <p className="text-justify">
          <strong>Blissmygift's Android App</strong>
          <br />
          
          For a better experience while using our Service, we may require you to
          provide certain personally identifiable information, including but not
          limited to [add whatever you collect here, e.g. users name | address,
          device id. The information that we request is retained on your device
          and is not collected by us in any way. It will be retained by us and
          used as described in this privacy policy. The app does use third party
          services that may collect information used to identify you.
        </p>
        <h2 className="tc-heading">Our use of your information</h2>
        <p className="text-justify">
          We use your personal information to facilitate the services you
          request. We save your personal information in the file we maintain
          about you, and other information we obtain from your current and past
          activities on the Site to: resolve disputes; troubleshoot problems;
          help promote safe trading; collect fees owed; measure consumer
          interest in the services provided by us, inform you about online and
          offline offers, products, services, and updates; customize your
          experience; detect and protect us against error, fraud and other
          criminal activity; enforce our User Agreement; and as otherwise
          described to you at the time of collection. At times, we may look
          across multiple users to identify problems or resolve disputes, and in
          particular we may examine your personal information to identify users
          using multiple User IDs or aliases. We may compare and review your
          personal information for errors, omissions and for accuracy. <br />
          <br />
          <div className="text-justify">
          If you choose to buy an item on the Site, we use your address and
          billing information to bill you and provide associated support.
          </div>
          <br />
          <br />
          <div className="text-justify">
          You agree that we may use personal information about you to improve
          our marketing and promotional efforts, to analyze site usage, improve
          the Site's content and product offerings, and customize the Site's
          content, layout, and services. These uses improve the Site and better
          tailor it to meet your needs, so as to provide you with a smooth,
          efficient, safe and customized experience while using the Site.
          </div>
           <br />
          <br />
          <div className="text-justify">
          You agree that we may use your personal information to contact you and
          deliver information to you that, in some cases, are targeted to your
          interests, such as targeted banner advertisements, administrative
          notices, product offerings, and communications relevant to your use of
          the Site. By accepting the User Agreement and Privacy Policy, you
          expressly agree to receive this information. If you do not wish to
          receive these communications, we encourage you to opt out of the
          receipt of certain communications in your profile. You may make
          changes to your profile at any time.
          </div>
        </p>
        <h2 className="tc-heading">Our Disclosure of your information</h2>
        <p className="text-justify">
          We may also use your information to deliver information to you that,
          in some cases, are targeted to your interests, such as new services
          and promotions. <br />
          <br />
          We cooperate with law enforcement and regulatory inquiries, as well as
          other third parties to enforce laws, such as: intellectual property
          rights, fraud and other rights, to help protect you and the community
          from bad actors. Therefore, in response to a verified request by law
          enforcement or other government officials relating to a criminal
          investigation or alleged illegal activity, we can (and you authorize
          us to) disclose your name, city, state, telephone number, email
          address, User ID history, fraud complaints, and bidding and listing
          history without a subpoena. Without limiting the above, in an effort
          to respect your privacy and our ability to keep the community free
          from bad actors, we will not otherwise disclose your personal
          information to law enforcement or other government officials without a
          subpoena, court order or substantially similar legal procedure, except
          when we believe in good faith that the disclosure of information is
          necessary to prevent imminent physical harm or financial loss; or
          report suspected illegal activity. Further, we can (and you authorize
          us to) disclose your name, street address, city, state, zip code,
          country, phone number, email, and company name to Intellectual
          Property rights? owners under confidentiality agreement, as we in our
          sole discretion believe necessary or appropriate in connection with an
          investigation of fraud, intellectual property infringement, piracy, or
          other unlawful activity. <br />
          <br />
          Due to the existing regulatory environment, we cannot ensure that all
          of your private communications and other personal information will
          never be disclosed in ways not otherwise described in this Privacy
          Policy. By way of example (without limiting the foregoing), we may be
          forced to disclose personal information to the government or third
          parties under certain circumstances, third parties may unlawfully
          intercept or access transmissions or private communications, or users
          may abuse or misuse your personal information that they collect from
          the Site. <br />
          <br />
          Subclause 1: Therefore, although we use industry standard practices to
          protect your privacy, we do not promise, and you should not expect,
          that your personal information or private communications will always
          remain private.
          <br />
          <br />
          Your Consent: By using the Site and/ or by providing your information,
          you consent to the collection and use of the information you disclose
          on the Site by blissmygift.com accordance with this Privacy Policy,
          including but not limited to your consent for sharing your information
          as per Clause 5 subclause 1 mentioned above. <br />
          <br />
          If we decide to change our privacy policy, we will post those changes
          on this page so that you are always aware of what information we
          collect, how we use it, and under what circumstances we disclose it.
          We might inform you by email whenever we made changes to our privacy
          policy else keep checking this page periodically for any changes made.
        </p>
        <h2 className="tc-heading">Security</h2>
        <p className="text-justify">
          Your account is password protected. We use industry standard measures
          to protect the personal information that is stored in our database. We
          limit the access to your personal information to those employees and
          contractors who need access to perform their job function, such as our
          customer service personnel. If you have any questions about the
          security on Blissmygift, please contact us. Although we take
          appropriate measures to safeguard against unauthorized disclosures of
          information, we cannot assure you that your personal information will
          never be disclosed in a manner that is inconsistent with this Privacy
          Policy.
          <br />
          <br />
          You hereby acknowledge that Blissmygift is not responsible for any
          intercepted information sent via the internet, and you hereby release
          us from any and all claims arising out of or related to the use of
          intercepted information in any unauthorized manner.
        </p>
        <h2>Log Data</h2>
        <p className="text-justify">
          We want to inform you that whenever you use our Service, in case of an
          error in the app we collect data and information (through third party
          products) on your phone called Log Data. This Log Data may include
          information such as your devices’s Internet Protocol ("IP") address,
          device name, operating system version, configuration of the app when
          utilising our Service, the time and date of your use of the Service,
          and other statistics.
        </p>
        <h2 className="tc-heading">
          Terms and modifications to this privacy policy
        </h2>
        <p className="text-justify">
          We may modify this Privacy Policy at any time, and we will post any
          new versions on this page. If we make any material changes in the way
          we use your personal information, we will notify you by sending an
          e-mail to the last e-mail address you provided to us and/or by
          prominently posting notice of the changes on our website. Any such
          changes will be effective upon the earliest of thirty (30) calendar
          days following our dispatch of an e-mail notice to you or thirty (30)
          calendar days following our posting of notice of the changes on our
          website. These changes will be effective immediately for new users of
          Blissmygift. Please note that at all times you are responsible for
          updating your personal information to provide us with your most
          current e-mail address. In the event that the last e-mail you have
          provided us is not valid, or for any reasons not capable of delivering
          to you the notice described above, our dispatch of the e-mail
          containing such notice will nonetheless constitute effective notice of
          the changes described in the notice. In any event, changes to this
          Privacy Policy may affect our use of personal information that you
          provided us prior to our notification to you of the changes. If you do
          not wish to permit changes in our use of your personal information,
          you must notify us prior to the effective date of the changes that you
          wish to deactivate your account with us. Continued use of Blissmygift
          following notice of such changes shall indicate your acknowledgement
          of such changes and agreement to be bound by the terms and conditions
          of such changes.
        </p>
        <h2 className="tc-heading">Dispute resolution</h2>
        <p className="text-justify">
          If you believe that Blissmygift has not adhered to this Privacy Policy
          you may write to Blissmygift at the following address: e-mail:{" "}
          <a href="mailto:info@blissmygift.com">info@Blissmygift.com</a> <br />
          <br />
          In your email, please describe in as much detail as possible ways in
          which you believe the Privacy Policy has not been complied with. We
          will investigate your complaint promptly.
        </p>
        <h2 className="tc-heading">
          Contacting the website and access to your personal information
        </h2>
        <p className="text-justify"> 
          Upon written request, subject to certain exceptions, Blissmygift will
          inform you of the existence, use, and disclosure of your personal
          information and will give you access to that information. Access
          requests should be sent to our Privacy Officer, using the contact
          information above. When requesting access to your personal
          information, we may request specific information from you to enable us
          to confirm your identity and right to access, as well as to search for
          and provide the personal information that we hold about you. We may
          charge you a fee to access your personal information to cover our
          costs; however, we will advise you of any fee in advance, which will
          be no more than any maximum fee prescribed by law.
        </p>
        <h2 className="tc-heading">Your Consent</h2>
        <p className="text-justify">
          By using the Website and/ or by providing your information, you
          consent to the collection and use of the information you disclose on
          the Website in accordance with this Privacy Policy, including but not
          limited to Your consent for sharing your information as per this
          privacy policy.
          <br />
          <br />
          If you have any questions about this Privacy Policy, you can contact
          us by sending an email on:{" "}
          <a href="mailto:info@blissmygift.com">info@blissmygift.com</a>
        </p>
      </div>
    </main>
    {/* end MAIN */}
  </div>
  {/* FOOTER */}
<Footer/>
 
  {/* end FOOTER */}
  {/*back-to-top  */}

  {/* <a href="https://api.whatsapp.com/send?phone=9322134554&text=Hello%21%20Blissmygift" class="whatsappicon" target="_blank">
  <img src="https://www.blissmygift.com/public/frontend/images/whatsappchat/whatsapp.png">
  </a> */}
</div>

        
        </>
    )
}

export default PrivacyPolicy;