import config from "../config";
import axios from "axios"
import * as url from "../helpers/url_helper";
//import { setAuthorizationToken } from "helpers/authorization";
//setAuthorizationToken();
export const HomeApi = {
    getCategories: async (data) => {
        var axiosConfig = {
            method: 'GET',
            url: `${config.API_URL}/${url.GET_CATEGORIES}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getBanners: async (data) => {
        var axiosConfig = {
            method: 'GET',
            url: `${config.API_URL}/${url.GET_BANNERS}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getHomeProducts: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.GET_HOME_PRODUCT}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getCategoryProducts: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.GET_CATEGORY_PRODUCT}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getProductDetails: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.GET_PRODUCT_DETAILS}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getCountryCodeData: async (data) => {
        var axiosConfig = {
            method: 'GET',
            url: `${config.API_URL}/${url.GET_COUNTRY_CODE}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getCities: async () => {
        var axiosConfig = {
            method: 'GET',
            url: `${config.API_URL}/${url.GET_CITIES}`,
            headers: {
                'Content-Type': 'application/json'
            },
           // data: data
        };

        return await axios(axiosConfig);
    },
    getCustomerFeedback: async () => {
        var axiosConfig = {
            method: 'GET',
            url: `${config.API_URL}/${url.GET_ALL_FEEDBACK}`,
            headers: {
                'Content-Type': 'application/json'
            },
           // data: data
        };

        return await axios(axiosConfig);
    },

    



    

    



    
}