import React from "react";
import { NavLink, Link, useParams } from "react-router-dom";

function LoginModal(props) {
  return (
    <>
      <div>
        <div
          className="modal fade loginModal popupLoginModal"
          id="coupon-login"
          tabIndex={0}
          role="dialog"
          >
          <div className="modal-dialog">
            <div className="modal-content" style={{ padding: 0 }}>
              <div
                className="modal-header"
                style={{
                  boxShadow: "0px 1px 2px #ddd",
                  width: "100%",
                  padding: "7px 26px 6px",
                }}
              >
                <h6
                  className="modal-title popupTitle"
                  id="myModalLabel"
                >
                  Apply Coupon
                </h6>
                <button
                  type="button"
                  className="closeEvent modal-close"
                  aria-label="Close"
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 1,
                    textTransform: "capitalize",
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    props.handleChildData({
                      isModalOpen: false,
                      isDataUpdate: false,
                    })
                  }
                >
                  <span
                    aria-hidden="true"
                    style={{ color: "#3d4152", fontSize: 34 }}
                  >
                    ×
                  </span>
                </button>
              </div>
              <div
                className="modal-body text-center w-100 mt-10per"
              >
                {/* style={{ marginBottom: 5 }} */}
                <p className="mb-5" >
                  For More Exclusive offers and Coupons <br />
                  Please Login .<br />
                </p>
              </div>
              <div className="modal-footer couponloginButtonEvent">
                <NavLink
                  to="/login?redirectTo=cart"
                  id="moveToCkBtn"
                  className="btn  btn-primary"
                  style={{ padding: "0 25px 0", textAlign: "center!important" }}
                >
                  Login
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginModal;
