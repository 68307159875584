import React, { useEffect, useState } from "react";
import InnerBackHeader from "../layouts/InnerBackHeader";
import { CartApi } from "../../apis/CartApi";
import { getUserId } from "../../Token";
import { NavLink, Link, useParams } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function NewInvoiceSummery() {
  const params = useParams();
  const order_id = params.order_id;
  const [total, setTotal] = useState();
  const [orderDate, setOrderDate] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phoneNo, setPhoneNo] = useState();
  const [email, setEmail] = useState();
  const [recipientName, setRecipientName] = useState();
  const [recipientLastName, setRecipientLastName] = useState();
  const [recipientContactNo, setRecipientContactNo] = useState();
  const [recipientAltNo, setRecipientAltNo] = useState();
  const [deliveryAddress, setDeliveryAddress] = useState();
  const [subTotal, setSubTotal] = useState();
  const [deliveryAmount, setDeliveryAmount] = useState();

  const [couponDiscount, setCouponDiscount] = useState();
  const [deliveryPincode, setDeliveryPincode] = useState();
  const [orderPlaceDate, setOrderPlaceDate] = useState();
  const [DeliveryType, setDeliveryType] = useState();
  const [DeliveryTime, setDeliveryTime] = useState();
  const [paymentMode, setPaymentMode] = useState();
  const [orderStatus, setOrderStatus] = useState();
  const [invoiceNo, setInvoiceNo] = useState();
  const [totalDetails, setTotalDetails] = useState([]);


  //Order Item
  const [productDetail, setProductDetail] = useState([]);

  useEffect(() => {
    getInvoiceInfo(order_id);
  }, []);

  const getInvoiceInfo = (order_id) => {
    const data = { order_id: order_id };
    CartApi.getOrderByid(data)
      .then((res) => {
        console.log(res.data.data);
        if (res.data.status) {
          console.log(res.data.data.total_details)
          setTotal(res.data.data.total_details.total);
          setOrderDate(res.data.data.total_details.order_placed_on);
          setFirstName(res.data.data.total_details.firstname);
          setLastName(res.data.data.total_details.lastname);
          setPhoneNo(res.data.data.total_details.mobile);
          setEmail(res.data.data.total_details.email);
          setRecipientName(res.data.data.total_details.recipient_first_name);
          setRecipientLastName(res.data.data.total_details.recipient_last_name);
          setRecipientContactNo(
            res.data.data.total_details.recipient_contact_no
          );

          setRecipientAltNo(
            res.data.data.total_details.recipient_alt_contact_no
          );

          setDeliveryAddress(res.data.data.total_details.delivery_address);

          setDeliveryPincode(res.data.data.total_details.pincode);
          setSubTotal(res.data.data.total_details.sub_total);
          setDeliveryAmount(res.data.data.total_details.delivery_charge);
          setCouponDiscount(res.data.data.total_details.discount);

          setOrderPlaceDate(res.data.data.total_details.order_placed_on);
          setDeliveryType(res.data.data.total_details.delivery_type);
          setDeliveryTime(res.data.data.total_details.delivery_time);
          setPaymentMode(res.data.data.total_details.payment_mode);

          setOrderStatus(res.data.data.total_details.current_order_status);
          setInvoiceNo(res.data.data.total_details.invoice_no);

          setProductDetail(res.data.data.order_details);
          setTotalDetails(res.data.data.total_details);
          
        } else {
          setTotalDetails([]);
          setTotal();
          setOrderDate();
          setFirstName();
          setLastName();
          setPhoneNo();
          setEmail();
          setRecipientName();
          setRecipientLastName();
          setRecipientContactNo();

          setRecipientAltNo();

          setDeliveryAddress();

          setDeliveryPincode();
          setSubTotal();
          setDeliveryAmount();
          setCouponDiscount();

          setOrderPlaceDate();
          setDeliveryType();
          setDeliveryTime();
          setPaymentMode();

          setProductDetail();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

 

  return (
    <>
      <InnerBackHeader isLogo={true} />

      <div className="ml-10 mr-10  ">
        <div className="ml-10">
          <div className="col  mt-10">
            Ordered On: <span className="text-bold">{totalDetails.order_placed_on}</span>
          </div>
          <div className="col ">
            
            Order ID: <span className="text-bold">{order_id}</span>
          </div>
          <div className="col tex-bold">
            
            [ status :
            <span className="text-bold text-danger">
              
            {totalDetails.current_order_status}  
            </span>
            ]
          </div>


          {/* <hr class="bg-dark"/> */}



{/* 
          <hr className="ml-neg-10  invoice-hr"/> */}

          <div>
            <div className=" col mb-10 mt-15 ">
              <span className=" logo-color text-bold"> Shipping Address </span>
            </div>

            <div className="col ">
            

            
              
            {totalDetails.recipient_first_name}   {totalDetails.recipient_last_name}  
            </div>
            <div className="col ">
              <span className=" col-7 invoice-add-limit fst-normal text-justify">
               
             
              {totalDetails.delivery_address}  
              </span>
            </div>
            <div className="col "> Pincode :  {totalDetails.pincode}  </div>

            <div className="col ">
              Mobile No:  {totalDetails.recipient_contact_no} 
            </div>

             { recipientAltNo && 
            <div className="col ">
              
              
              Alt Mobile No : {totalDetails.recipient_alt_contact_no}
            </div>
             }
          </div>

          <div className="col-xs- mb-10 mt-15 ">
            <span className="float-left logo-color text-bold">
              Payment Method
            </span>
          </div>
          <div className="col ">  {totalDetails.payment_mode}</div>

          <div className="col-xs- mb-10 mt-15 ">
            <span className="float-left logo-color text-bold">
              Payment status
            </span>
          </div>
          <div className="col "> {totalDetails.payment_status ? "Paid": "Not Paid"}</div>

          <div>
            <div className="col-xs- mb-10 mt-15 ">
              <span className="float-left logo-color text-bold">
                Order Summary
              </span>
            </div>
            {/* <div className="col "> MRP :  {subTotal}</div>
 <div className="col "> Delivery Amount :  {deliveryAmount}</div>
 <div className="col "> Coupon Discount :  ₹ -{couponDiscount}</div>
 <div className="col "> Total :  {subTotal}</div> */}

            <table class="table">
              <thead></thead>
              <tbody className=" ">
                <tr className="">
                  <td className="  ">Sub Total</td>
                  <td></td>
                  <td className="">₹ {totalDetails.sub_total}</td>
                </tr>
                <tr className="">
                  <td className=" ">Delivery Amount</td>
                  <td></td>
                  <td className="">₹ {totalDetails.delivery_charge}</td>
                </tr>

                {couponDiscount && (
                  <tr className="">
                    <td className=" "> Coupon Discount</td>
                    <td></td>
                    <td className="">₹ -{couponDiscount}</td>
                  </tr>
                )}
                <tr className=" text-bold ft-20">
                  <td className=" "> Grand Total</td>
                  <td></td>
                  <td className="">₹ {total} </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="bg-light-grey ml-neg-10 ">
            <div className="col ml-15 text-bold"> Delivery Mode : {DeliveryType} </div>
            <div className="col ml-15 "> [{totalDetails?.delivery_date} - {totalDetails?.delivery_time}] </div>
          </div>

          <div></div>
          <div className="col-xs- mb-10 mt-15 ">
            <span className="float-left logo-color text-bold">
            Items
            </span>
          </div>
          <div>
            {/* Item list */}

            <div className="col-xs- invoice-details mr-10">
             
              <table class="table border-bottom-imp">
            
               <tbody className=" mb-10  ">
                  {productDetail &&
                    productDetail.map((item, i) => {
                      return (
                        <tr id= {i}  className="border-bottom-imp">
                        

                          <div className="col ">
                            <div className="col-xs-3">
                            <LazyLoadImage
                              effect="blur"
                              wrapperProps={{
                                  // If you need to, you can tweak the effect transition using the wrapper style.
                                  style: {transitionDelay: "1s"},
                              }}
                              src={item.mobile_product_image}
                            />

                            {/* <img
                            className="mr-10 invoice-img"
                              src={item.mobile_product_image}
                            // alt={productDetail.product_name}
                            /> */}
                            </div>
                            <div className="col-xs-6">
                              <div className="col">    {item.product_name}</div>
                              <div className="col">   
                              {item.sub_attribute_name ? 
                              <div>
                               <span className="text-bold">
                                {item.attribute_name}:
                              </span>
                              {item.sub_attribute_name}
                              </div> :<></>
                              } 
                              </div>

                            </div>
                            <div className="col-xs-3 mr-neg-10">
                              Qty:{item.quantity}
                              
                            </div>
                            <div className="col-xs-3 mr-neg-10" style={{float:"right"}}>
                            ₹{item.total}
                            </div>
                           




                          </div>
                         

                          </tr>


                        
                          
                          




                         
                       
                      );
                     
                    })}
                     </tbody>
               
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewInvoiceSummery;
