import React, { useEffect, useState } from "react";
import { NavLink, Link, useParams } from "react-router-dom";
import InnerBackHeader from './layouts/InnerBackHeader';
import BottomNav from './layouts/BottomNav';
import Banner from './components/Banner';
import HomeProduct from './components/HomeProduct';
import HomeSectionBlock from './components/HomeSectionBlock';
import HomeSectionBlockone from './components/HomeSectionBlockone';
import Product from './components/Product';
import { HomeApi } from "../apis/HomeApi";
import InfiniteScroll from "react-infinite-scroll-component";
import config from "../config";
import Loader from "./layouts/Loader";


function CategoryProduct() {

  const params = useParams();
  const sub_category_id = params.sub_category_id;
  const [products, setProducts] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [totalProduct, setTotalProduct] = useState(0);
  const [hasMore, sethasMore] = useState(true);
  const [loader, setLoader] = useState(false);


  const [activeData, setActiveData] = useState({
    offset: 1,
    limit: 10,
    sub_category_id: sub_category_id,
  });

  useEffect(() => {
    getCategoryProducts(activeData,false);
  }, []);


  const getCategoryProducts = (data,is_push_data) => {
    setLoader(true);
    HomeApi.getCategoryProducts(data)
      .then((res) => {
        if (res.data.status) {
          //setProducts(res.data.data);
          setCategoryName(res.data.category_name);
          setTotalProduct(res.data?.productStats?.total_products);

          if(is_push_data) {
            setProducts([...products, ...res.data.data]);
          } else {
            setProducts(res.data.data);
          }

          if (res.data.data.length == 0) {
            sethasMore(false);
          }
          setLoader(false);
        } else {
          setLoader(false);
          setProducts([]);
          setCategoryName("");
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  }; 

  const fetchMoreData = () => {
    const data = {
      offset: activeData.offset + 1,
      limit: 10,
      sub_category_id: sub_category_id,
    };
    getCategoryProducts(data,true);
    setActiveData(data);
  };


  return (
    <>
    <InnerBackHeader title={categoryName} totalProduct={totalProduct} isLogo={false} link="/"/>
    {loader && <Loader />}
  <div className="block-floor-products block-floor-products-opt3 floor-products1">
   
    <div className="">
      
      <div className="block-content"> 
        {/* List Products */}
        <div className="products  products-grid">

       
        {products.length > 0 &&
        <InfiniteScroll
          dataLength={products.length}
          next={fetchMoreData}
          loader={
            <div className="col-sm-offset-5 col-sm-6 ajaxloader text-center">
              <img
               src={config.IMAGE_URL + '/common-images/loader_new.gif'}
               alt="loader" />
              <div>Breathe in... Breathe out...</div>
              <div>Bringing more gifts</div>
            </div>
          }
          hasMore={hasMore}
        >

        {products.length > 0 ? (
        <Product products={products}/>
        ) : (
          <></>
        )}

        </InfiniteScroll> }
        </div>
   
        
        {/* List Products */}
        {/* tab product */}
      </div>
    
    </div>
  </div>
  {/* block -floor -products / floor 1*/}
  {/* block -floor -products / floor 1*/}
</>


  );
}

export default CategoryProduct;
