

import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import InnerBackHeader from './layouts/InnerBackHeader';
import Product from './components/Product';
import { SearchApi } from "../apis/SearchApi";
import InfiniteScroll from "react-infinite-scroll-component";
import config from "../config";


function SearchProduct() {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const serch_keyword = urlParams.get('keyword');
  const [products, setProducts] = useState([]);
  const [categoryName, setCategoryName] = useState(serch_keyword);
  const [totalProduct, setTotalProduct] = useState(0);
  const [hasMore, sethasMore] = useState(true)

  const [activeData, setActiveData] = useState({
    offset: 1,
    limit: 10,
    serch_keyword : serch_keyword,
  });

  useEffect(() => {
    getSearchProducts(activeData,false);
  }, []);


  const getSearchProducts = (data,is_push_data) => {
    SearchApi.SearchProduct(data)
      .then((res) => {
        // You can check by console
        if (res.data.status) {
          
          //setProducts(res.data.data);
          setTotalProduct(res.data?.total_count?.total_products);

          if(is_push_data) {
            setProducts([...products, ...res.data.data]);
          } else {
            setProducts(res.data.data);
          }

          if (res.data.data.length == 0) {
            sethasMore(false);
          }
        } else {
          setProducts([]);
          setCategoryName("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }; 

  const fetchMoreData = () => {
    const data = {
      offset: activeData.offset + 1,
      limit: 10,
      serch_keyword : serch_keyword,
    };
    getSearchProducts(data,true);
    setActiveData(data);
  };


  return (
    <>
    <InnerBackHeader title={categoryName} totalProduct={totalProduct} link="/"/>
  <div className="block-floor-products block-floor-products-opt3 floor-products1">
   
    <div className="">
      
      <div className="block-content">
        {/* List Products */}
        <div className="products  products-grid">

        {products.length > 0 &&
        
        <InfiniteScroll
          dataLength={products.length}
          next={fetchMoreData}
          loader={
            <div className="col-sm-offset-5 col-sm-6 ajaxloader text-center">
              <img
               src={config.IMAGE_URL + '/common-images/loader_new.gif'}
               alt="loader" />
              <div>Breathe in... Breathe out...</div>
              <div>Bringing more gifts</div>
            </div>
          }
          hasMore={hasMore}
        >

        {products.length > 0 ? (
        <Product products={products}/>
        ) : (
          <></>
        )}

        </InfiniteScroll> }
        </div>

       
        
        {/* List Products */}
        {/* tab product */}
      </div>
    
    </div>
  </div>
  {/* block -floor -products / floor 1*/}
  {/* block -floor -products / floor 1*/}
</>


  );
}

export default SearchProduct;
