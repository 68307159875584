

const initialState = {
  inputType: "email",
};

const inputReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_INPUT_TYPE":
      return {
        ...state,
        inputType: action.payload,
      };
    default:
      return state;
  }
};

export default inputReducer;