import config from "../config";
import axios from "axios"
import * as url from "../helpers/url_helper";
//import { setAuthorizationToken } from "helpers/authorization";
//setAuthorizationToken();
export const CartApi = {
    checkPincode: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.CHECK_PINCODE}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    fetchDeliveryType: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.GET_DELIVERY_TYPE}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    fetchDeliveryTime: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.GET_DELIVERY_TIME}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    addToCart: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.ADD_TO_CART}`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getViewCart: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.FETCH_VIEW_CART}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getViewCartCount: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.FETCH_VIEW_CART_COUNT}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    checkout: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.CHECKOUT}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    paymentModeUpdate: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.PAYMENT_MODE_UPDATE}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    paymentStatusUpdate: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.PAYMENT_STATUS_UPDATE}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getOrderByid: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.GET_ORDER_BY_ID}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    addonsAddToCart: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.ADDONS_ADD_TO_CART}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    removeCartItem: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.REMOVE_CART_ITEM}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    updateCartItem: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.UPDATE_CART_ITEM}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    customerImag: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.CUSTOMER_IMG}`,
            // headers: {
            //     'Content-Type': 'application/json'
            // },
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getReciverDetailsByid: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.GET_RECEIVER_BY_ID}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },


    
    
}