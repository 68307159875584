import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";

import Home from './pages/Home';
import CategoryProduct from './pages/CategoryProduct';
import ProductDetail from './pages/ProductDetail';
import ViewCart from './pages/ViewCart';
import Checkout from './pages/Checkout';
import Payment from './pages/Payment';
import LoginFirst from './pages/auth/LoginFirst';
import LoginVerify from './pages/auth/LoginVerify';
import Registration from './pages/auth/Registration';
import ForgotPassword from './pages/auth/ForgotPassword';
import DeliveryCities from './pages/DeliveryCities';
import CustomerReviews from './pages/CustomerReviews';


import Search from './pages/Search';
import SearchProduct from './pages/SearchProduct';


import ProfileDashboard from './pages/profile/ProfileDashboard';
import ProfileCurrentOrder from './pages/profile/ProfileCurrentOrder';
import ProfilePastOrder from './pages/profile/ProfilePastOrder';
import ProfileUpdatePassword from './pages/profile/ProfileUpdatePassword';
import AllCouponCode from './AllCouponCode';
import FAQs from './documents/FAQs';
import AboutUs from './documents/AboutUs';
import PrivacyPolicy from './documents/PrivacyPolicy';
import TermsAndConditions from './documents/TermsAndConditions';
import ContactUs from './pages/auth/ContactUs';
import WriteToUs from './pages/auth/WriteToUs';
import Profile from './pages/profile/Profile';
import OrderList from './pages/profile/OrderList';
import InvoiceSummery from './pages/profile/InvoiceSummery';
import NewInvoiceSummery from './pages/profile/NewInvoiceSummery';
import OrderSuccess from './pages/components/order success & fail/OrderSuccess';
import OrderFailed from './pages/components/order success & fail/OrderFailed';
import NewProfile from './pages/profile/NewProfile';
import ConfirmationPopper from './pages/auth/ConfirmationPopper';
import ReviewForm from './pages/components/ProductComponents/ReviewForm';
import LogoutConfirmationModal from './pages/auth/LogoutConfirmationModal';
import LoginFirstDev from './pages/UnderDevPage/LoginFirstDev';
import OrderTracking from './pages/components/Modal/OrderTracking';
import BottomNav from './pages/layouts/BottomNav';



function App() {
  return (
    <>
    <Routes>
         <Route path="/" exact element={<Home />} />
         <Route path="/products/:sub_category_id" exact element={<CategoryProduct />} />
         <Route path="/product-details/:product_id" exact element={<ProductDetail />} />
         <Route path="/view-cart" exact element={<ViewCart />} />
         <Route path="/checkout" exact element={<Checkout />} />
         <Route path="/payment" exact element={<Payment />} />
         <Route path="/search" exact element={<Search />} />
         <Route path="/delivery-cities" exact element={<DeliveryCities />} />
         <Route path="/customer-reviews" exact element={<CustomerReviews />} />
         
         <Route path="/login" exact element={<LoginFirst />} />
         <Route path="/login-verify" exact element={<LoginVerify />} />
         <Route path="/registration" exact element={<Registration />} />
         <Route path="/forgot-password" exact element={<ForgotPassword />} />
          <Route path="/offers" exact element={<AllCouponCode />} /> 
    

         <Route path="/profile/myaccount" exact element={<ProfileDashboard />} />
         <Route path="/profile/current-order" exact element={<ProfileCurrentOrder />} />
         <Route path="/profile/past-order" exact element={<ProfilePastOrder />} />
         <Route path="/profile/update-password" exact element={<ProfileUpdatePassword />} />


         {/* Search Product  */}
         <Route path="/search-product" exact element={<SearchProduct/>} />

         <Route path="/profile" exact element={<Profile/>} />
         <Route path="/faq" exact element={<FAQs/>} />
         <Route path="/about-us" exact element={<AboutUs/>} />
         <Route path="/privacy-policy" exact element={<PrivacyPolicy/>} />
         <Route path="/terms-and-conditions" exact element={<TermsAndConditions />} />

         <Route path='/contact-us' exact element={<ContactUs/>}/>
         <Route path='/contact-us/write-to-us' exact element={<WriteToUs/>}/>
         <Route path='/order-list' exact element={<OrderList/>}/>
       
         <Route path='/order-details/:order_id' extract element={ <NewInvoiceSummery/>}/>
         <Route path='/order/success' extract element={ <OrderSuccess/>}/>
         <Route path='/order/failed' extract element={ <OrderFailed/>}/>

         <Route path='/write-review/:product_id' extract element={ <ReviewForm/>}/>
         <Route path='/underDev' extract element={ <LoginFirstDev/>}/>
         <Route path='/order-tracking/:product_id' extract element={ <OrderTracking/>}/>
         <Route path='/test' extract element={ <BottomNav/>}/>

    </Routes>
    </>
  );
}

export default App;
