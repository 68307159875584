import config from "../config";
import axios from "axios"
import * as url from "../helpers/url_helper";
//import { setAuthorizationToken } from "helpers/authorization";
//setAuthorizationToken();

export const ReviewApi = {

    submitReview: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.SUBMIT_REVIEW}`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: data
        };

        return await axios(axiosConfig);
    },
}