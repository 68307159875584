import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import InnerBackHeader from "./layouts/InnerBackHeader";
import { NavLink, Link, useParams, useNavigate } from "react-router-dom";
import { HomeApi } from "../apis/HomeApi";
import { CartApi } from "../apis/CartApi";
import * as Yup from "yup";
import { setCartId, getCartId, setUserId, getUserId } from "../Token";
import config from "../config";
import "react-responsive-datepicker/dist/index.css";
import { useDispatch } from "react-redux";
import { fetchCartCount } from "../actions/cartActions";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { CustomerReviewApi } from "../apis/CustomerReviewApi";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import ProductDiscription from "./components/ProductComponents/ProductDiscription";
import CustomerRating from "./components/ProductComponents/CustomerRating";
import Loader from "./layouts/Loader";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// import ShareButton from "./components/shareModal/ShareButton";


function ProductDetail() {
  const params = useParams();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false);
  const product_id = params.product_id;
  const [productDetail, setProductData] = useState([]);
  const [productGallery, setProductGallery] = useState([]);
  const [productPrices, setProductPrices] = useState([]);
  const [checkPincode, setCheckPincode] = useState(false);
  const [deliveryType, setDeliveryType] = useState([]);
  const [deliveryTime, setDeliveryTime] = useState([]);
  const [productDetails, setProductDetails] = useState(null);
  const [productDeliveryDetails, setProductDeliveryDetails] = useState(null);
  const [productCareDetails, setProductCareDetails] = useState(null);

  const [egglessPrice, setEgglessPrice] = useState(50);
  const [heartShapePrice, setHeartShapePrice] = useState(100);
  const [eggless, setEggless] = useState(false);
  const [heartshape, setHeartShape] = useState(false);

  const [weight, setWeight] = useState(null);
  const [price, setPrice] = useState(null);
  const [beforeUpdateprice, setBeforeUpdatePrice] = useState(null);
  const [product_price_id, setProductPriceId] = useState(null);
  const [deliveryCharge, setDeliveryCharge] = useState(null);

  // const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [file, setFile] = useState(null);
  const [customerRating, setCustomerRating] = useState([]);
  const [totalRating, setTotalRating] = useState({});
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const navigate = useNavigate();
  const [customerDate, setCustomerDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [pincodeVerification, setPincodeVerification] = useState();
  const hiddenFileInput = useRef(null);
  const [customerImg, setCustomerImg] = useState();
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [customValidation, setCustomValidation] = useState();
  const [loader, setLoader] = useState(false);
  const [isPhotoUpload, setIsPhotoUpload] = useState(true);
  const [pincodeMsg, setPincodeMsg] = useState("");
  const [messageLength, setMessageLength] = useState();
  const [isAddonsProduct, setIsAddonProduct] = useState(false);
  const [pincodeVerify , setPincodeVerify] = useState();
  const [productImg , setProductImg] = useState("");


  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  
  
  // Remove Customer Image
  const removeCustomerImg = (img) => {
    formik.setFieldValue("customer_img", "");
    setCustomerImg("");
    setProductImg("")
  };

  const [displayTimer, setDisplayTimer] = useState("");

  const onDateChange = (date) => {
    setCustomerDate(date);
    formik.setFieldValue("delivery_date", date);
  };

  // Product Discription


  const [activeData, setActiveData] = useState({
    product_id: product_id,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getProductDetails(activeData);
    getDeliveryType(activeData);
    getCustomerReview(activeData);
  }, []);

  // const setEndTimerFunc = (endTime) => {
  //   let x = setInterval(function () {
  //     // Getting current time in required format
  //     let now = new Date().getTime();
  //     let endDateTime = new Date(endTime);
  //     // console.log('closingDateTime',endDateTime);
  //     // Calculating the difference
  //     let t = endDateTime - now;

  //     // Getting value of days, hours, minutes, seconds
  //     let days = Math.floor(t / (1000 * 60 * 60 * 24));
  //     let hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  //     let minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
  //     let seconds = Math.floor((t % (1000 * 60)) / 1000);

  //     const endTimer =
  //       hours +
  //       ":" +
  //       minutes +
  //       ":" +
  //       seconds +
  //       " hours left for today's delivery";

  //     if (t < 0) {
  //       setDisplayTimer("");
  //     } else {
  //       setDisplayTimer(endTimer);
  //     }
  //   }, 1000);
  // };

  const setEndTimerFunc = (endTime) => {
    let x = setInterval(function () {
        // Getting current time in required format
        let now = new Date().getTime();
        let endDateTime = new Date(endTime);
        
        // Calculating the difference
        let t = endDateTime - now;

        // Getting value of days, hours, minutes, seconds
        let hours = Math.floor(t / (1000 * 60 * 60));
        let minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((t % (1000 * 60)) / 1000);

        // Adding leading zeros if needed
        let formattedHours = String(hours).padStart(2, '0');
        let formattedMinutes = String(minutes).padStart(2, '0');
        let formattedSeconds = String(seconds).padStart(2, '0');

        const endTimer =
            formattedHours +
            ":" +
            formattedMinutes +
            ":" +
            formattedSeconds +
            " hours left for today's delivery";

        if (t < 0) {
            setDisplayTimer("");
            clearInterval(x);
        } else {
            setDisplayTimer(endTimer);
        }
    }, 1000);
};

  const getCustomerReview = () => {
    const productId ={
      product_id:product_id

    }

    setLoader(true);
    CustomerReviewApi.getCustomerReview(productId)
      .then((res) => {
       
        if (res.data.status) {
          setCustomerRating(res.data?.data);
          setTotalRating(res.data?.rating_details);
        } else {
          setCustomerRating([]);
          setTotalRating({});
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  const getProductDetails = (data) => {
    setLoader(true);
    HomeApi.getProductDetails(data)
      .then((res) => {
        if (res.data.status) {
          setProductData(res.data.data);
          setIsPhotoUpload(res.data.data.is_photo_upload);
          setProductGallery(res.data.data.product_gallery);
          setProductPrices(res.data.data.product_prices);
          setPrice(res.data.data.product_prices[0]?.price);
          setBeforeUpdatePrice(res.data.data.product_prices[0]?.price);
          setWeight(res.data.data.product_prices[0]?.sub_attribute_name);
          setProductPriceId(res.data.data.product_prices[0]?.id);

          setIsAddonProduct(res.data.data.is_addons);

          setProductDetails(res.data.data.product_detail.product_description);
          setProductDeliveryDetails(
            res.data.data.product_detail.delivery_description
          );
          setProductCareDetails(res.data.data.product_detail.care_description);
          setSelectedDate(
            new Date(res.data.data.closing_date_time.datepickerStart)
          );
          // console.log(res.data.data.closing_date_time.timerEnd)
          if (!res.data.data.is_courier_delivery) {
            setEndTimerFunc(res.data.data.closing_date_time.timerEnd);
          }
          setLoader(false);
        } else {
          setProductData([]);
          setProductGallery([]);
          setProductPrices([]);
          setPrice(null);
          setBeforeUpdatePrice(null);
          setWeight(null);
          setProductPriceId(null);
          setProductDetails(null);
          setProductDeliveryDetails(null);
          setProductCareDetails(null);
          setIsAddonProduct(false);
          setLoader(false);
        }
       
      })

      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

 
  const getDeliveryType = (data) => {
    setLoader(true);
    CartApi.fetchDeliveryType(data)
      .then((res) => {
        if (res.data.status) {
          setDeliveryType(res.data.data);
        } else {
          setDeliveryType([]);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  

  const getDeliveryTimeByType = (value) => {
    setLoader(true);
    formik.setFieldValue("delivery_type", value);
    let data = { delivery_type: value, delivery_date: "" };
    CartApi.fetchDeliveryTime(data)
      .then((res) => {
        if (res.data.status) {
          setDeliveryTime(res.data.data);
        } else {
          setDeliveryTime([]);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  const handleProductUnit = (data) => {
    var egglessCurrentPrice = 0;
    var heartshapeCurrentPrice = 0;
    if (eggless) {
      egglessCurrentPrice = egglessPrice;
      setEggless(true);
    }
    if (!eggless) {
      egglessCurrentPrice = 0;
      setEggless(false);
    }
    if (heartshape) {
      heartshapeCurrentPrice = heartShapePrice;
      setHeartShape(true);
    }
    if (!heartshape) {
      heartshapeCurrentPrice = 0;
      setHeartShape(false);
    }
    var total =
      parseInt(data?.price) +
      parseInt(egglessCurrentPrice) +
      parseInt(heartshapeCurrentPrice);
      setPrice(total);
      setBeforeUpdatePrice(data?.price);
      setWeight(data?.sub_attribute_name);
      setProductPriceId(data?.id);
  };

  const handleProductCustomAttribute = (event) => {
    let isChecked = event.target.checked;
    let attrValue = event.target.value;
    var egglessCurrentPrice = 0;
    var heartshapeCurrentPrice = 0;
    if (isChecked) {
      egglessCurrentPrice = egglessPrice;
      setEggless(true);
    }
    if (!isChecked) {
      egglessCurrentPrice = 0;
      setEggless(false);
    }
    if (heartshape) {
      heartshapeCurrentPrice = heartShapePrice;
      setHeartShape(true);
    }
    if (!heartshape) {
      heartshapeCurrentPrice = 0;
      setHeartShape(false);
    }
    var total =
      parseInt(beforeUpdateprice) +
      parseInt(egglessCurrentPrice) +
      parseInt(heartshapeCurrentPrice);
    setPrice(total);
  };

  const messageOnProduct =(msg)=> {
      setMessageLength( 30 - msg.length)
  }

  const handleProductCustomHeartShape = (event) => {
    let isChecked = event.target.checked;
    let attrValue = event.target.value;
    var egglessCurrentPrice = 0;
    var heartshapeCurrentPrice = 0;
    if (eggless) {
      egglessCurrentPrice = egglessPrice;
      setEggless(true);
    }
    if (!eggless) {
      egglessCurrentPrice = 0;
      setEggless(false);
    }
    if (isChecked) {
      heartshapeCurrentPrice = heartShapePrice;
      setHeartShape(true);
    }
    if (!isChecked) {
      heartshapeCurrentPrice = 0;
      setHeartShape(false);
    }
    var total =
      parseInt(beforeUpdateprice) +
      parseInt(egglessCurrentPrice) +
      parseInt(heartshapeCurrentPrice);
      setPrice(total);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      isPhotoUpload: isPhotoUpload  ? true : false,
      customer_img: productImg,
      delivery_pincode :pincodeVerify,
      delivery_date: "",
      delivery_type: "",
      delivery_time: "",
      product_message: "",
      checkPincode: checkPincode  ? true : false,
    },
    validationSchema: Yup.object().shape(
      {
        ...customValidation,
      },
      [[]]
    ),

    onSubmit: (values) => {
      
      const date = moment(values.delivery_date);
      const data = new FormData();
      data.append("delivery_pincode", values.delivery_pincode);
      data.append("delivery_date", date.format("YYYY-MM-DD"));
      data.append("delivery_type", values.delivery_type);
      data.append("delivery_time", values.delivery_time);
      data.append("product_message", values.product_message);
      data.append("product_price_id", product_price_id);
      data.append("product_id", product_id);
      data.append("cart_id", getCartId());
      data.append("user_id", getUserId());
      data.append("city_id", 2);
      data.append("is_eggless", eggless);
      data.append("is_heartshape", heartshape);
      data.append("photo_on_cake", customerImg);

      CartApi.addToCart(data)
        .then((res) => {
          setLoader(true);
          if (res.data.status) {
            setCartId(res.data.data.cart_id);
            dispatch(fetchCartCount(res.data.data.cart_count));
            navigate("/view-cart");
            //window.location.href = "/view-cart";
          } else {
            alert(res.data.message);
            //setErrorMsg(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  });

  const handlePincodeCheck = (value) => {
      formik.setFieldValue("delivery_pincode", value);
      var len = Math.ceil(Math.log(value) / Math.LN10);
      setPincodeVerify(value)
      
      if (len == 6) {
        setLoader(true);
        let data = { pincode: value };
        CartApi.checkPincode(data)
          .then((res) => {
            // console.log(res.data.data.message)
            if (res.data.status) {
              setCheckPincode(true);
              setPincodeMsg(res.data.data.message)
            } else {
              setCheckPincode(false);
              setPincodeMsg("");
            }
            setLoader(false);
          })
          .catch((err) => {
            setLoader(false);
            console.log(err);
          });
      } else {
        setCheckPincode(false);
        setPincodeMsg("");
      }
    };

  useEffect(() => {
    setCustomValidation({
      customer_img: Yup.mixed().when('isPhotoUpload', {
        is: true,
        then: () =>  Yup.mixed()
          .required('Please upload an image')
          .test('fileType', 'Only image files are allowed (png, jpg, jpeg)', (value) => {
            if (value) {
              const fileType = value.type;
              if (
                !(
                  fileType === 'image/png' ||
                  fileType === 'image/jpeg' ||
                  fileType === 'image/jpg'
                )
              ) {
                return false;
              }
            }
            return true;
          }),
        otherwise: () => Yup.mixed().nullable(), // Define validation when isPhotoUpload is not true
      }),
      delivery_pincode: Yup.mixed().when('checkPincode', {
        is: false,
        then: () =>  Yup.mixed()
        .test('isNumber', 'Please Enter a 6-Digit Numeric Pincode', (value) =>
            typeof value === 'number' && /^\d+$/.test(value.toString())
          )
          .test('len', 'Please Enter a 6-Digit Pincode', (value) => value && value.toString().length === 6)
          .required('Please Enter Pincode to Continue'),
        otherwise: () =>  Yup.mixed().nullable(),
      }),
      
      // delivery_pincode: Yup.number()
      //   .typeError("Please Enter Number 6 Digits Pincode to Continue")
      //   .required("Please Enter Pincode to Continue"),
      delivery_date: Yup.string().required("Select Delivery Date to Continue"),
      delivery_type: Yup.string().required("Select Delivery Type to Continue"),
      delivery_time: Yup.string().required("Select Delivery Time to Continue"),
    });
  }, []);

  const customerImageUpload = (event) => {
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append("file", fileUploaded);
    formik.setFieldValue("customer_img",fileUploaded);
    setProductImg(fileUploaded);
    console.log(fileUploaded);
    const fileType = fileUploaded.type;
    if (fileType === "image/png" || fileType === "image/jpeg" || fileType === "image/jpg") 
    {
      let data = { file: fileUploaded };
      CartApi.customerImag(formData)
        .then((res) => {
          if (res.data.status) {
            setCustomerImg(res.data.data.image);
          } else {
            setCustomerImg("");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setCustomerImg("");
    }
   
  };

  const writeReview = () => {
    if (getUserId() != "") {
       let path = (`/write-review/${product_id}`);
       navigate(path);
    } else {
      // User is not logged in
      navigate(`/login?redirectTo=write-review&product_id=${product_id}`);

    }
  };

  useEffect(() => {
    if (isFormSubmited) {
      const { errors } = formik;
      const keys = Object.keys(errors);
      if (keys.length > 0) {
        const errorElement = document.getElementById(keys[0]);
        // errorElement.scrollIntoView({ behavior: 'smooth',block: 'start' });
        //errorElement.focus();
        const marginTop = 120; // Adjust the margin value as needed
  const scrollPosition = errorElement.getBoundingClientRect().top + window.scrollY - marginTop;

  window.scrollTo({
    top: scrollPosition,
    behavior: 'smooth',
  });
        setIsFormSubmited(false);
      }
    }
  }, [isFormSubmited, formik]);

  return (
    <>
      <InnerBackHeader isLogo={true} />
      {loader && <Loader />}
      {/* <ProductDetailsHeader/>  */}

      <div className="block-floor-products block-floor-products-opt3 floor-products1">
        <>
          <main className="site-main home-product-main-div">
            <div className="block-floor-products block-floor-products-opt3 floor-products1">
              <div className="col-md-12 col-main no-padding product-image-div">
                <div className="product-media media-horizontal">
                  <div className="image_preview_container images-large">
                    {/* <img
                      src={productGallery.product_image}
                      alt={productDetail.product_name}
                    /> */}
                     <LazyLoadImage
                        alt={productDetail.product_name}
                        effect="blur"
                        wrapperProps={{
                          // If you need to, you can tweak the effect transition using the wrapper style.
                          style: {transitionDelay: "1s"},
                        }}
                        src={productGallery.product_image}
                      />
                    {/* </picture> */}
                  </div>
                </div>
              </div>
              <div className="product-details-main-div">
                {/* container */}
                {/* title */}
                {/* Main Content */}
                <div className="col-main">
                  <div className="row ">
                    <div className="col-xs-12 col-md-7 col-lg-7 no-padding">
                      <div className="product-info-main product-details-page-name">
                        <div className="page-product-title">
                          {productDetail.product_name} 
                        
                          <span id="showweight"> {weight}</span>
                        </div>

                        <div className="price price-icon">
                          ₹ <span className="price-number"> {price}</span>
                        </div>

                        {totalRating.total_rating ?
                        <div className="mt-10">
                          <span className="comment-2">
                            <span className="comment-2">
                              {totalRating?.rating}
                            </span>
                            <strong className="left-rating-star">★</strong>
                          </span>
                          &nbsp;
                          <a
                          className="font-light-blue"
                            href="#customer_review "
                          >
                            <span className="m-plp_product-reviews__3d14Y product-reviews">
                              <span itemProp="reviewCount">
                                {totalRating?.total_rating}
                              </span>{" "}
                              Reviews
                            </span>
                          </a>
                        </div>
                        :<></>}

                        {productPrices.length > 1 && (
                          <div className="row">
                            <br />
                            <p className="page-product-title">
                              Pick An Upgrade
                            </p>
                            {productPrices.map((item, i) => {
                              return (
                                <div
                                  className="col-sm-3 col-xs-3 small-product-upgrade"
                                  key={i}
                                  onClick={() => handleProductUnit(item)}
                                >
                                  <div className="caption">
                                    {/* <img
                                      className={
                                        product_price_id == item.id
                                          ? "product_unit_active"
                                          : ""
                                      }
                                      src={productGallery.mobile_product_image}
                                    /> */}
                                    <LazyLoadImage
                                        effect="blur"
                                        wrapperProps={{
                                          // If you need to, you can tweak the effect transition using the wrapper style.
                                          style: {transitionDelay: "1s"},
                                        }}
                                        className={
                                          product_price_id == item.id
                                            ? "product_unit_active"
                                            : ""
                                        }
                                        src={productGallery.mobile_product_image}
                                    />
                                    <p className="text-center">
                                      {item.sub_attribute_name}
                                      <br />
                                      <b>₹ {item.price} </b>
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}

                          
                        {!isAddonsProduct ? (

                        <form
                          className="form-horizontal"
                          
                          onSubmit={(e) => {
                            e.preventDefault();
                            formik.handleSubmit();
                            setIsFormSubmited(true);
                            return false;
                          }}
                        >
                          {/* className="form-group" */}
                          <div>  
                            <div className="row">

                            {(productDetail.is_eggless  > 0 || productDetail.is_heartshape > 0) && (
                              <div className="col-xs-12  no-padding">
                                {productDetail.is_eggless > 0 && (
                                  <div className="col">
                                    <label>
                                      <input
                                        id="eggless"
                                        defaultValue="eggless"
                                        name="is_eggless"
                                        className="with-gap prod-att-checkbox prod-eg-check"
                                        type="checkbox"
                                        onClick={(e) =>
                                          handleProductCustomAttribute(e)
                                        }
                                      />
                                      <span className="eggless">Eggless</span>
                                    </label>
                                  </div>
                                )}

                                {productDetail.is_heartshape > 0 && (
                                  <div className="col">
                                    <label>
                                      <input
                                        id="heartshape"
                                        defaultValue="heartshape"
                                        name="is_heartshape"
                                        className="with-gap prod-att-checkbox prod-hs-check"
                                        type="checkbox"
                                        onClick={(e) =>
                                          handleProductCustomHeartShape(e)
                                        }
                                      />
                                      <span className="heartshape">
                                        Heart Shape
                                      </span>
                                    </label>
                                  </div>
                                )}
                              </div>
                            )}

                              {productDetail.is_photo_upload == 1 && (
                                <>
                                  <div className="col-xs-12 no-padding" id="customer_img"  >
                                    <div className="input_container mb-10">
                                      <button
                                        className="btn-upload mt-15"
                                        onClick={handleClick}
                                      >
                                        <img
                                          className="uploadIMgTxt uploadImgIcon"
                                          src={
                                            config.IMAGE_URL +
                                            "/common-images/icons8-upload.gif"
                                          }
                                          id="uploadedImageIcon"
                                        />{" "}
                                        Upload a file
                                      </button>
                                      <input
                                        type="file"
                                       
                                        name="customer_img"
                                        className="display_none"
                                        onBlur={formik.handleBlur}
                                        onChange={customerImageUpload}
                                        ref={hiddenFileInput}
                                      />
                                    </div>

                                    {formik.touched.customer_img &&
                                    formik.errors.customer_img ? (
                                      <p className="text-danger text-small text-left">
                                        {formik.errors.customer_img}
                                      </p>
                                    ) : null}
                                  </div>

                                  {customerImg && (
                                    <div className="col-xs-12 no-padding">
                                      <img
                                        src={customerImg}
                                        className="ml-10 userUplodImg"
                                      />
                                      <div>
                                        <button
                                          onClick={removeCustomerImg}
                                          className="customerImg"
                                        >
                                          <i
                                            className="fa fa-trash"
                                            aria-hidden="true"
                                          />{" "}
                                          Remove{" "}
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}

                              <br />

                              <div className="col-xs-12 no-padding">
                                {/* custom-pincode   */}
                                <div className="input-field ">
                                  <input
                                    type="text"
                                    placeholder="Enter Delivery Pincode"
                                    // type="text"
                                    className="form-control custom-input-style text-padding"
                                    id="delivery_pincode"
                                    name="delivery_pincode"
                                    maxLength={6}
                                    // min={parameterForm.durationType === 'minutes' ? 5 : undefined}
                                    // onChange={handlePincodeChange}

                                    // onChange={(e) => setPincodeVerification(e.target.value)}

                                    // onChange={(e) =>
                                    //   handlePincodeCheck(e.target.value)
                                    // }
                                    onChange = {(e) => {
                                      formik.handleChange(e);
                                      handlePincodeCheck(e.target.value);
                                    }}

                                    value={formik.values.delivery_pincode}
                                    //onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    // value={pincodeVerification}
                                  />
                                  {/* <label htmlFor="delivery_pincode ">
                                    Enter Delivery Pincode
                                  </label> */}


                                  

                                  {checkPincode ? (



                                    <span
                                      className="custom_validation_message text-success"
                                    >
                                       <div
                                        dangerouslySetInnerHTML={{ __html: pincodeMsg }}
                                      />
                                    </span>
                                  ) : (
                                    <>

<span
                                      className="custom_validation_message text-danger"
                                     
                                    >
                                       <div
                                        dangerouslySetInnerHTML={{ __html: pincodeMsg }}
                                      />
                                    </span>
                                    </>
                                  )}

                                  {formik.touched.delivery_pincode &&
                                  formik.errors.delivery_pincode ? (
                                    <p className="text-danger text-small text-left">
                                      {formik.errors.delivery_pincode}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="col-xs-12  no-padding">
                                <div className="input-field ">
                                  {/*  New Date Picker */}

                                  <div className="datepickerdiv">
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <KeyboardDatePicker
                                         fullWidth
                                        format="yyyy-M-dd"
                                        margin="normal"
                                        id="delivery_date"
                                        placeholder="Select Delivery Date"
                                        name="delivery_date"
                                        value={customerDate}
                                        open={isPickerOpen}
                                        autoOk
                                        onClick={() => setIsPickerOpen(true)}
                                        onClose={() => setIsPickerOpen(false)}
                                        onChange={onDateChange}
                                        // onChange={(e)=>{
                                        //   handleDeliveryDate(e.target.value)
                                        // }}
                                        // minDate={new Date().setDate()}
                                        minDate={moment(selectedDate).add(
                                          0,
                                          "days"
                                        )}
                                        // maxDate={moment(selectedDate).subtract(1, 'days')}
                                        KeyboardButtonProps={{
                                          "aria-label": "change date",
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>

                                    {formik.touched.delivery_date &&
                                    formik.errors.delivery_date ? (
                                      <p className="text-danger text-small text-left">
                                        {formik.errors.delivery_date}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            
                              {deliveryType ? (
                                <div className="input-field col-xs-12 no-padding">
                                  <select
                                    className="browser-default"
                                    id="delivery_type"
                                    name="delivery_type"
                                    onChange={(e) =>
                                      getDeliveryTimeByType(e.target.value)
                                    }
                                    //onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.delivery_type}
                                  >
                                    <option
                                      value=""
                                      className="product-details-lable"
                                    >
                                      Choose Delivery Type
                                    </option>
                                    {deliveryType?.map((item, i) => (
                                      <option value={item.id} key={i}>
                                        {item.name} (₹ {item.delivery_charge})
                                      </option>
                                    ))}
                                  </select>

                                  {formik.touched.delivery_type &&
                                  formik.errors.delivery_type ? (
                                    <p className="text-danger text-small text-left">
                                      {formik.errors.delivery_type}
                                    </p>
                                  ) : null}
                                </div>
                              ) : (
                                <></>
                              )}

                              <div className="input-field col-xs-12 no-padding">
                                <select
                                  className="browser-default"
                                  id="delivery_time"
                                  name="delivery_time"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.delivery_time}
                                >
                                  <option value="">Choose Delivery Time</option>
                                  {deliveryTime?.map((item, i) => (
                                    <option value={item.id} key={i}>
                                      {item.start_time} - {item.end_time}
                                    </option>
                                  ))}
                                </select>
                                {formik.touched.delivery_time &&
                                formik.errors.delivery_time ? (
                                  <p className="text-danger text-small text-left">
                                    {formik.errors.delivery_time}
                                  </p>
                                ) : null}
                              </div>
                            </div>

                            {productDetail.is_cake_include ||
                            productDetail.is_product_message ? (
                              <div className="row">
                                <div className="col-xs-12 no-padding">
                                  <div className="input-field">
                                    <input
                                      type="text"
                                      className="form-control custom-input-style text-padding"
                                      name="product_message"
                                      id="product_message"
                                      onChange={formik.handleChange}
                                      maxLength={30}
                                      onKeyUp={(e) => messageOnProduct(e.target.value)}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.product_message}
                                      placeholder={productDetail.is_cake_include ? "Message On Cake" : "Message On Product"}
                                    />

                                             
                                   

                                    {/* {productDetail.is_cake_include ? (
                                      <label htmlFor="product_message">
                                        Message On Cake
                                      </label>
                                    ) : (
                                      <label htmlFor="product_message">
                                        Message On Product
                                      </label>
                                    )} */}
                                  </div>

                                  {messageLength > 0 &&  (
                                      <span className="float-right">
                                      { messageLength} character(s) remaining
                                      </span>
                                   )}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}

                            <input
                              type="hidden"
                              name="buttontype"
                              id="buttontype"
                              defaultValue="addcart"
                            />
                           
                          </div>

                          {/* Timer */}

                          <div className="button-position">
                            {displayTimer && (
                              <div className="col-xs-12 endDayTimer">
                                {displayTimer}
                              </div>
                            )}
                            <div className="row">
                              <div className="col-xs-12 col-xs-12 no-padding">
                                <button
                                  className="add-cart-button"
                                  type="submit"
                                >
                                  ADD TO CART
                                </button>
                              </div>
                              {/* <div className="col-xs-12 col-xs-6 no-padding">
                                <button
                                  type="submit"
                                  className="buy-now-button"
                                >
                                  BUY NOW
                                </button>
                              </div> */}
                            </div>
                          </div>
                        </form>

                        ) : (
                            <><div className="col-main">
                                <div className="row">
                                  <div className="col-sm-6 col-md-7 col-lg-7 no-padding">
                                    <h4 className="text-danger text-center">
                                      This is Addons product. You can add this product after adding any one
                                      main product.{" "}
                                    </h4>
                                  </div>
                                  {/* Main detail */}
                                </div>
                              </div>
                            </>
                        ) }



                      </div>
                      {/* detail- product */}
                    </div>
                    {/* Main detail */}
                  </div>
                </div>

                {(productDeliveryDetails != null || productDeliveryDetails != null || productCareDetails != null) && (      
                <ProductDiscription
                  productDetails={productDeliveryDetails}
                  productDeliveryDetails={productDeliveryDetails}
                  productCareDetails={productCareDetails}
                />
                )}

                {customerRating.length > 0 && (
                  <CustomerRating customerRating={customerRating} productId={product_id} />
                )}



      <div
        className="row"
        style={{
          borderRadius: 7,
          margin: "12px 0px",
          padding: "18px 0px",
          backgroundColor: "#F3F3F3",
          textAlign: "center",
          marginBottom:"65px"
        }}
      >
        <div
          className="col s12 m12  center-align "
          style={{
            padding: 0,
            color: "#4D4D4D",
            fontWeight: 600,
            fontSize: 18,
          }}
        >
          Feeling on top of the world with latest order?
        </div>
        <div
          className="col s12 m12  center-align "
          style={{
            padding: "15px 0 5px",
            color: "#636466",
            fontSize: "1.34rem",
          }}
        >
          
          <div
              // className="view-more-bttn"
              style={{ padding: "7px 10px 7px!important" }}
            >
              <div className="text-center">
                {/* <p className="text-center">Login to see the items you added previously</p> */}
                <button
                  onClick={writeReview}
                  
                  className="write-review_btn btn"

                  // className="btn btn-success emptycartbutton"
                >
                  WRITE A REVIEW
                </button>
              </div>
            </div>
           
        </div>
      </div>
              

                {/* Main detail */}
              </div>
              {/* block -floor -products / floor 1*/}
            </div>

           
          </main>
          {/* end MAIN */}
        </>
      </div>
      {/* block -floor -products / floor 1*/}
      {/* block -floor -products / floor 1*/}
    </>
  );
}

export default ProductDetail;
