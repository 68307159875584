import config from "../config";
import axios from "axios"
import * as url from "../helpers/url_helper";
//import { setAuthorizationToken } from "helpers/authorization";
//setAuthorizationToken();
export const ProfileApi = {
    currentOrderList: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.CURRENT_ORDER_LIST}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    pastOrderList: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.PAST_ORDER_LIST}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    orderDetails: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.ORDER_DETAILS}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    updatePassword: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.UPDATE_PASSWORD}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    }
}