import React, { useEffect, useState } from "react";
import InnerBackHeader from "./pages/layouts/InnerBackHeader";
import { CouponsApi } from "./apis/CouponCodeApi";
import config from "./config";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// import { CouponsApi } from "../apis/CouponCodeApi";


function AllCouponCode() {
    const [offersList, setOffersList] = useState();
    
  useEffect(() => {
    getCouponCode();
  }, []);


  const getCouponCode = (data) => {
    CouponsApi.getProductOffer(data)
      .then((res) => {
        console.log(res.data.data)
        if (res.data.status) {
          setOffersList(res.data.data);
        } else {
          setOffersList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
    


    return(
        <>
          <InnerBackHeader isLogo={true} />
          <div id="wrapper">
  {/* MAIN */}
  <main className="block-floor-products block-floor-products-opt3 floor-products1 home-product-main-div">
    <div className="columns ">
      <div>
        <LazyLoadImage
         alt="offers"
          effect="blur"
          wrapperProps={{
              // If you need to, you can tweak the effect transition using the wrapper style.
              style: {transitionDelay: "1s"},
          }}
          src={config.IMAGE_URL +"/offers/offer-page-banner-mobile.jpg"}
        />

      </div>
      {/* Block  Breadcrumb*/}
      <div className="">
        {/* Main Content */}
        <div className="col-main">
          <div className="">
            <div
              className="row offer"
              style={{ paddingLeft: 5, marginTop: 17, marginBottom: 10 }}
            >



                {offersList?.map((item, i) => (
                      <div
                      className="col-xs-6  section white tiles center-align offer-div"
                      style={{
                        width: "46%",
                        padding: "0%",
                        marginBottom: 15,
                        border: "1px solid #CCC",
                        marginRight: 14
                      }}
                    >
                      <p
                        style={{
                          fontSize: 22,
                          margin: "8% 0% 3%",
                          fontWeight: "bold"
                        }}
                      >
                         {item.title}
                      </p>
                      <div
                        className="desktopWinCoupon"
                        style={{
                          background: "#6B6B6B 0% 0% no-repeat padding-box",
                          borderRadius: "4px 4px 0px 0px",
                          color: "#FFFFFF",
                          marginLeft: 9,
                          width: "88%",
                          padding: "8px 0px 8px 0px",
                          fontSize: 20
                        }}
                      >
                        COUPON CODE
                      </div>
                      <div
                        className="win-coupon desktopWinCoupon"
                        style={{
                          fontSize: 35,
                          border: "1px dashed #5C5C5C",
                          borderTopStyle: "none",
                          color: "#5D1C7B",
                          fontWeight: 700,
                          borderRadius: "3px 3px 0px 0px",
                          padding: 13,
                          width: "88%",
                          marginLeft: 9
                        }}
                      >
                       {item.coupon_code}
                      </div>
                      <div
                        className=" section white"
                        style={{ fontSize: 16, margin: "0%" }}
                      >
                       {item.description}
                      </div>
                    </div>
                   








                ))}




            
            </div>
          </div>
          
          {/* Main Content */}
        </div>
      </div>
    </div>
  </main>
  {/* end MAIN */}
</div>


        
        
        </>
    )
}

export default AllCouponCode ;