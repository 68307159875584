import React, { useEffect, useState } from "react";
import { CouponsApi } from "../../../apis/CouponCodeApi";
import { getCartId } from "../../../Token";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import config from "../../../config";

function CouponCodeModal(props) {
  const [offersList, setOffersList] = useState();
  const [couponCode, setCouponCode] = useState();
  const [couponCodeMessage, setCouponCodeMessage] = useState("");
  // const [isModalOpen, setIsModalOpen] = useState(props.isModalOpen)

  let navigate = useNavigate();
  const [activeData, setActiveData] = useState({
    cart_id: getCartId(),
  });

  useEffect(() => {
    getCouponCode();
  }, []);

  const applySingleCoupon = (couponCode) => {
    clickSearchCoupon(couponCode);
  };

  const getCouponCode = (data) => {
    CouponsApi.getProductOffer(data)
      .then((res) => {
        if (res.data.status) {
          setOffersList(res.data.data);
        } else {
          setOffersList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clickSearchCoupon = (couponCode) => {
    let data = { coupon_code: couponCode, cart_id: getCartId() };
    var len = Math.ceil(Math.log(couponCode) / Math.LN10);

    if (!couponCode || !couponCode.trim()) {
      setCouponCodeMessage("Please Enter Coupon Code");
    } else {
      CouponsApi.applyCouponCode(data)
        .then((res) => {
          if (res.data.status) {
            setCouponCodeMessage(res.data.message);
            props.handleChildData({ isModalOpen: false, isDataUpdate: true });
          } else {
            setCouponCodeMessage(res.data.message);

            props.handleChildData({ isModalOpen: true, isDataUpdate: false });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <div
        className=" CouponCodeModal adobeDiscountModel coupon-modal modal fade open"
        id="coupon-field"
        tabIndex={0}
        role="dialog"
        style={{
          minHeight: "100%",
          zIndex: 1003,
          opacity: 1,
          top: "10%",
          transform: "scaleX(1) scaleY(1)",
          display: "block",
        }}
      >
        <div className="modal-dialog">
          <div
            className=" modalContain modal-content"
            // style={{ padding: 0, float: "left", width: "100%" }}
          >
            <div
              className="modal-header "
              style={{
                position: "sticky",
                left: 0,
                right: 0,
                top: 0,
                backgroundColor: "#fafafa",
                zIndex: 9,
                float: "left",
                width: "100%",
                padding: "9px 9px 0",
              }}
            >
              <button
                type="button"
                className="  coupon-modal-applybtn modal-close"
                aria-label="Close"
                // style={{
                //   position: "absolute",
                //   left: "-2px",
                //   top: 0,
                //   border: "none",
                //   background: "none",
                //   cursor: "pointer",
                // }}
              >
                <span
                  aria-hidden="true"
                  style={{ fontSize: 30 }}
                  // data-dismiss="modal"
                  onClick={() =>
                    props.handleChildData({
                      isModalOpen: false,
                      isDataUpdate: false,
                    })
                  }
                >
                  <img
                    className="closeEvent"
                    src={config.IMAGE_URL + "/icons/2143237.png "}
                    style={{ width: 24, fontSize: 30 }}
                  />{" "}
                </span>
              </button>
              <div 
              className="discount-coupons-title"
                // style={{
                //   textAlign: "center",
                //   left: 0,
                //   right: 0,
                //   margin: "0 auto",
                //   color: "#3e4152",
                //   fontWeight: 600,
                // }}
              >
                {" "}
                Discount Coupons
              </div>
              <div
                className="row"
                style={{
                  border: "none!important",
                  width: "100%",
                  margin: "24px auto 7px",
                  paddingBottom: 0,
                  float: "left",
                  height: 46,
                  backgroundColor: "#fafafa!important",
                }}
              >
                <div
                  className="col s9"
                  style={{
                    width: "75%",
                    border: "1px solid #E6E6E6",
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  <input
                    placeholder="Enter coupon code"
                    type="text"
                    id="coupon_code"
                    name="coupon_code"
                    defaultValue=""
                    style={{
                      // padding: "0 10px 0!important",
                      backgroundColor: "#fafafa!important",
                      height: "44px!important",
                      marginBottom: 0,
                      border: "none",
                      paddingLeft: "10px",
                    }}
                    onChange={(e) => {
                      setCouponCode(e.target.value);
                    }}
                  />
                </div>
                <div className="col s3" style={{ width: "25%", padding: 0 }}>
                  <button
                    id="applyCoupon"
                    className="bag-discount add-coupon-btn-pop modal-close"
                    style={{
                      fontSize: 17,
                      textTransform: "uppercase",
                      width: "100%",
                      height: 46,
                      border: "none",
                      cursor: "pointer",
                      textAlign: "center",
                      color: "#fff",
                      background: "#284390",
                      float: "right",
                      // lineHeight: 31,
                      padding: "8px 16px",
                    }}
                    variant="secondary"
                    // data-dismiss= { isModalClose ? "modal" : "" }
                    onClick={(e) => clickSearchCoupon(couponCode)}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>

            <div className="ml-15">
              <span className="text-danger ">{couponCodeMessage}</span>
            </div>

            <div
              className="modal-body"
              style={{
                padding: 0,
                width: "100%",
                float: "left",
                overflowY: "auto",
                height: "auto",
                position: "relative",
              }}
            >
              <div
                className="existcoupon"
                style={{ padding: 0, float: "left", width: "100%" }}
              >
                <ul
                  style={{
                    width: "100%",
                    float: "left",
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  {offersList?.map((item, i) => (
                    <li
                      style={{
                        marginBottom: 0,
                        display: "block",
                        padding: "0 13px 0px",
                        borderBottom: "11px solid #e9ecee",
                        borderRight: "1px solid #e9ecee",
                        borderLeft: "1px solid #e9ecee",
                        float: "left",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          margin: "8px 0 0",
                          fontWeight: 600,
                          color: "#3e4152",
                        }}
                      >
                        {/* {couponCodeMessage} */}
                        Save <span className="moneySymbol">₹</span>&nbsp;
                        <span className="moneyCal" data-inr={50}>
                          {item.maximum_discount}
                        </span>
                      </p>
                      <p
                        style={{
                          fontSize: 13,
                          margin: 0,
                          color: "rgb(130, 130, 130)",
                        }}
                      >
                        Flat <span className="moneySymbol">₹</span>&nbsp;{" "}
                        <span className="moneyCal" data-inr={50}>
                          {item.title}
                        </span>{" "}
                        off on minimum purchase of{" "}
                        <span className="moneySymbol">₹</span>&nbsp;{" "}
                        <span className="moneyCal" data-inr="1,000">
                          {item.min_amount}
                        </span>
                      </p>
                      <div className="divider-line"></div>
                      <div className="applycoupondiv">
                        <div style={{ flex: 1 }}>
                          <div
                            style={{
                              backgroundColor: "rgb(37 111 239 / 10%)",
                              color: "rgb(28, 28, 28)",
                              textTransform: "uppercase",
                              display: "inline-block",
                              padding: "2px 10px",
                              border: "1px dashed rgb(37, 111, 239)",
                              fontSize: 14,
                            }}
                          >
                            {item.coupon_code}
                          </div>
                        </div>
                        <label>
                          {parseInt(props.total) >=
                          parseInt(item.min_amount) ? (
                            <div
                              className="waves-effect waves-light pull-right applyCouponSingle"
                              style={{ color: "#284390", fontWeight: "bold" }}
                              onClick={(e) =>
                                applySingleCoupon(item.coupon_code)
                              }
                              data-dismiss="modal"
                            >
                              Apply
                            </div>
                          ) : (
                            <div className="pull-right applybtndisable">
                              Apply
                            </div>
                          )}
                        </label>
                      </div>
                      <div className="divider-line-second"></div>

                      {parseInt(props.total) >= parseInt(item.min_amount) ? (
                        <div
                          style={{
                            color: "green",
                            fontSize: 13,
                            paddingBottom: 10,
                          }}
                        >
                          You will Save <span className="moneySymbol">₹</span>
                          &nbsp;{" "}
                          <span className="moneyCal">
                            {item.maximum_discount}
                          </span>{" "}
                          with this code
                        </div>
                      ) : (
                        <div className="additemnote">
                          Add items worth <span className="moneySymbol">₹</span>
                          &nbsp;
                          <span className="moneyCal">
                            {parseInt(item.min_amount) - parseInt(props.total)}
                          </span>{" "}
                          more to apply this offer
                        </div>
                      )}
                    </li>
                  ))}

                 
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CouponCodeModal;
