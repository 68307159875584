import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import config from "../../config";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Product({ products }) {
  return (


    <>
      {products.map((item, i) => {
        return (
          <div
            className="col-sm-2 col-xs-6 product-item product-item-main-div"
            itemProp="itemListElement"
            itemScope=""
            itemType="http://schema.org/ListItem"
            key={i}
          >
            <meta itemProp="position" content={1} />
            <NavLink className="product-item-img" to={`/product-details/${item.id}`}>
              <div className="product-item  product-item-opt-1">
                <div className="product-item-info">
                  <div className="product-item-photo">
                    <picture>
                      <source
                        srcSet= {item.product_images_webp}
                        type="image/webp"
                      />

                      <LazyLoadImage
                           alt={item.product_name}
                          effect="blur"
                          wrapperProps={{
                              // If you need to, you can tweak the effect transition using the wrapper style.
                              style: {transitionDelay: "1s"},
                          }}
                          src= {item.product_images}
                        />
                      {/* <img
                        alt={item.product_name}
                        src= {item.product_images}
                      /> */}
                    </picture>
                  </div>
                  <div className="product-item-detail">
                    <strong className="product-item-name">
                     {item.product_name}
                    </strong>
                    <div className="clearfix">
                      <div className="product-item-price">
                        <span className="price">₹ {item.product_price}</span>
                      </div>
                      <div className="earlydelivery">
                      <div dangerouslySetInnerHTML={{__html: item.earliest_day_message}}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
        );
      })}
    </>
  );
}

export default Product;
