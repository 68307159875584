import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

const OfferPopup = ({ closedOfferPopup, offerPopupInfo, currentDate }) => {
  const [showPopup, setShowPopup] = useState(true);
  const navigate = useNavigate();

  const handleClosePopup = () => {
    setShowPopup(false);
    localStorage.setItem("popupOpenDate", currentDate);
    closedOfferPopup(false);
  };

  const handleGiftNow = () => {
    navigate("/products/115");
    localStorage.setItem("popupOpenDate", currentDate);
  };

  return (
    <>
      <div
        className="modal-backdrop"
        onClick={handleClosePopup}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent black
          zIndex: 1050, // ensure it's above other content
        }}
      ></div>

      <div
        style={{
          display: "block",
          marginTop: "-200px",
          width: "88%",
          zIndex: 1051, // ensure it's above the backdrop
        }}
        className="modal"
        id="exampleModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div
            className="modal-content"
            style={{
              padding: "3px",
              marginTop: "14px",
              marginBottom: "34px",
            }}
          >
            <div className="offer-popup">
              <div className="offer-popup-content">
                <button
                  style={{ zIndex: 1050 }}
                  type="button"
                  className="close-button font-30"
                  onClick={handleClosePopup}
                >
                  &times;
                </button>
                <div className="offer-text">
                  <div className="col-xs-12 offerTagline">
                    {" "}
                    Psst...don't miss
                  </div>
                  <div className="col-xs-12 offerTagline">
                    {" "}
                    our bestsellers!
                  </div>
                  <div className="col-xs-12 offerPersent ">
                    {" "}
                    {offerPopupInfo.title}*
                  </div>
                  <div className="col-xs-12 ft-15">
                    ({offerPopupInfo.description})
                  </div>
                  <div className="col-xs-12 popupCoupon">
                    {" "}
                    <span style={{ border: "groove",padding:"3px" }} className="ft-20">
                      Use Code: {offerPopupInfo.coupon_code}
                    </span>{" "}
                  </div>

                  <span
                    onClick={handleGiftNow}
                    className="btn  col-xs-12 no-padding gift-now-btn"
                  >
                    GIFT NOW
                  </span>

                  {/* <p>T&C Apply</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferPopup;
