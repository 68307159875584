import React from "react";
import InnerBackHeader from "../layouts/InnerBackHeader";
import Footer from "../layouts/Footer";
import { useFormik } from "formik";
import * as Yup from "yup";
function WriteToUs(){
 



    const formik = useFormik({
        initialValues: {
                          name:"",
                          email:"",
                          contact:"",
                          message:"",
                        },
        validationSchema: Yup.object().shape({

          name : Yup.string().required("Please enter recipient name"),
          email:Yup.string().email().required("Please enter email"),
          contact: Yup.number()
          .typeError("Enter a valid phone number")
          .positive("Phone number can't start with a minus.")
          .integer("A phone number can't include a decimal point")
          .min(7)
          .required("Please enter mobile"),
          message:Yup.string().required("Please enter message"),
        }),

        

      onSubmit: (values) => {
        console.log(values)
       

      }
    })
return(
<>
<InnerBackHeader isLogo={true}/>
<div className="adbWriteToUs container pl-15 mb-15 " >
   <div className="section" style={{ paddingBottom: 0 }}>
   <div
   >
   <form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
       return false;
      }}
      >    
      <div className="" >
         <div className="col s12 center-align">
            <h1 className="center-align ">Write To Us</h1>
         </div>
      </div>
      <div className="row">
         <div className="col-xs-12 no-padding">
            <div className="input-field">
               <input
                  type="text"
                  className="form-control custom-input-style text-padding"
                  name="name"
                  id="name"
                  onChange={formik.handleChange}
                  // maxLength={30}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  placeholder=" Name"
                  />
                  { formik.errors.name ? (
                  <p className="text-danger text-small text-left">
                  {formik.errors.name}
                  </p>
                  ) : null}
            </div>
         </div>
      </div>
      <div className="row">
         <div className="col-xs-12 no-padding">
            <div className="input-field">
               <input
                  type="text"
                  className="form-control custom-input-style text-padding"
                  name="email"
                  id="email"
                  onChange={formik.handleChange}
                  // maxLength={30}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  placeholder=" Email Address"
                  />
                  { formik.errors.email ? (
                  <p className="text-danger text-small text-left">
                  {formik.errors.email}
                  </p>
                  ) : null}
            </div>
         </div>
      </div>
      <div className="row">
         <div className="col-xs-12 no-padding">
            <div className="input-field">
               <input
                  type="text"
                  className="form-control custom-input-style text-padding"
                  name="contact"
                  id="contact"
                  onChange={formik.handleChange}
                  // maxLength={30}
                  onBlur={formik.handleBlur}
                  value={formik.values.contact}
                  placeholder=" Contact No."
                  />
                   { formik.errors.contact ? (
                  <p className="text-danger text-small text-left">
                  {formik.errors.contact}
                  </p>
                  ) : null}
            </div>
         </div>
      </div>
      <div className="row">
         <div className="col-xs-12 no-padding">
            <div className="input-field">
               <input
                  type="text"
                  className="form-control custom-input-style text-padding"
                  name="message"
                  id="message"
                  onChange={formik.handleChange}
                  // maxLength={30}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                  placeholder=" Message"
                  />
                   { formik.errors.message ? (
                  <p className="text-danger text-small text-left">
                  {formik.errors.message}
                  </p>
                  ) : null}
            </div>
         </div>
      </div>
      <div className="col s12 ">
         <button
         className="btn waves-effect waves-light submit-but"
         type="submit"
         style={{ width: "100%", margin: "20px 0" }}
         >
         <span style={{ fontSize: 18 }}>Send</span>
         </button>
      </div>
   </form>
</div>
</div>
</div>
<div id="contact_form_map" className="col-xs-12 col-sm-6">
   <h3 className="page-subheading text-center">Information</h3>
   <ul className="store_info row">
      <li className="text-center">
         <i className="fa fa-phone" />
         <span>+91 9322134554</span>
      </li>
      <li className="text-center">
         <i className="fa fa-envelope" />
         <span>online@blissmygift.com</span>
      </li>
      {/* 
      <li>Registered Office Address : <span>43, Pragati Nagar, Jaitala Road, Nagpur, Maharashtra - 440036</span></li>
      */}
   </ul>
</div>



</>
)
};
export default WriteToUs;