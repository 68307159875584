import React, { useEffect, useState,useCallback } from "react";
import { useFormik } from "formik";

import InnerBackHeader from './layouts/InnerBackHeader';
import BottomNav from './layouts/BottomNav';
import Banner from './components/Banner';
import HomeProduct from './components/HomeProduct';
import HomeSectionBlock from './components/HomeSectionBlock';
import HomeSectionBlockone from './components/HomeSectionBlockone';
import Product from './components/Product';
import { NavLink, Link, useParams } from "react-router-dom";
import { HomeApi } from "../apis/HomeApi";
import { CartApi } from "../apis/CartApi";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { setCartId,getCartId,setUserId,getUserId,getOrderId,setOrderId,getUserInfo } from "../Token";
import useRazorpay from "react-razorpay";
import { useDispatch } from "react-redux";
import { fetchCartCount } from "../actions/cartActions";


function Payment() {
  const userInfo = getUserInfo();
  const Razorpay = useRazorpay();
  const dispatch = useDispatch();

  const [activeData, setactiveData] = useState({"order_id":getOrderId()});

  const [orderData, setOrderDetails] = useState([]);
  
  useEffect(() => {
    getOrderByid(activeData);
    }, []);

  const getOrderByid = (activeData) => {
    CartApi.getReciverDetailsByid(activeData)
      .then((res) => {
        if (res.data.status) {
          setOrderDetails(res.data.data);
        } else {
          setOrderDetails([]);
        }
      })
      .catch((err) => {
        console.log(err);
    });
  };


  const handlePayment = useCallback((totalAmount) => {
   // const order = await createOrder(params);
   const total = totalAmount * 100;
   const RazorpayOptions = {
     key: "rzp_live_MwIUfN7ZBtpl61",
     amount: total, // No need to parse as it's already a number
     currency: "INR",
     name: "BLISSMYGIFT",
     description: "Payment",
     image: "https://www.blissmygift.com/assets/images/icon-logo/64x64.png",
     // order_id: getOrderId(), // Uncomment if you have an order ID
     handler: (response) => {
       console.log('Payment response:', response); // Added console log
       // Check for razorpay_payment_id, razorpay_order_id, and razorpay_signature
       if (response.razorpay_payment_id) {
         const data = new FormData();
         data.append("order_id", getOrderId());
         data.append("cart_id", getCartId());
         data.append("user_id", getUserId());
         CartApi.paymentStatusUpdate(data)
           .then((response) => {
             if (response.data.status) {
               dispatch(fetchCartCount(0));
               localStorage.removeItem("cart_id");
               localStorage.removeItem("order_id");
               navigate('/order/success');
             } else {
               alert('Something went wrong, please try again!');
             }
           })
           .catch((error) => {
             console.log("Payment update error:", error);
           });
       } else {
         console.log('Payment failed:', response);
         alert('Payment error');
       }
     },
     prefill: {
       name: userInfo.firstname,
       email: userInfo.email,
       contact: userInfo.mobile,
     },
     theme: {
       color: "#1763df"
     }
   };
 

    const rzpay = new Razorpay(RazorpayOptions);
      rzpay.open();
    }, [Razorpay]);

    
    let navigate = useNavigate(); 
    useEffect(() => {
      }, []);

    const handleOnChange = (e) => {
      console.log('selected option', e.target.value);
      formik.setFieldValue(
        "payment_method",
        e.target.value
      )
     // this.setState({ selectedOption: e.target.value});
    }
    

    const formik = useFormik({
        initialValues: {
          payment_method: "razorpay",
        },
        validationSchema: Yup.object().shape(
          {
           payment_method: Yup.string().required("Please enter recipient name"),
          },
          []
        ),
        onSubmit: (values) => {
          const data = new FormData();
          data.append("order_id", getOrderId());
          data.append("cart_id", getCartId());
          data.append("user_id", getUserId());
          data.append("payment_method", values.payment_method);
          CartApi.paymentModeUpdate(data)
            .then((res) => {
              if (res.data.status) {
                    if(values.payment_method == 'razorpay') {
                     handlePayment(orderData.total);
                    } 

                    if(values.payment_method == 'cod') {
                      dispatch(fetchCartCount(0));
                      localStorage.removeItem("cart_id");
                      localStorage.removeItem("order_id");
                      navigate('/order/success');
                     } 
                    // setOrderId(res.data.data.order_id);
                    // // let path = `/payment`; 
                    // // navigate(path);
              } else {
                alert(res.data.message);
                //setErrorMsg(res.data.message);
              }
            })
            .catch((error) => {
              console.log("error", error);
            });
        },
      });

  return (
    <>
    <InnerBackHeader/>
  <div className="block-floor-products block-floor-products-opt3 floor-products1">
   
  <>
  {/* end HEADER */}
  <div id="wrapper">
    {/* MAIN */}
    <main className="site-main home-product-main-div viewcartpadding">
      <div className="columns">
        {/* <form method="post" id="placeOrder" action="http://localhost/projects/amol/blissmygift/laravel8/www.blissmygift.com/customer/placeOrder" > */}
        <div className="checkout-page">
        <form
          className="login-login-form"
          onSubmit={formik.handleSubmit}
          >
            <div className="box-border">
              <h4 className="checkout-sep">Payment Mode</h4>
              <ul>
              {/* <button onClick={handlePayment}>Click</button> */}
                {/* <li>
                          <label for="radio_button_5"><input checked="checked"  name="payment_method" value="payumoney" type="radio"> Online Payment (Payumoney)</label>
                      </li> */}
                <li>
                  <label>
                    <input
                      name="payment_method"
                      //defaultValue="razorpay"
                      type="radio"
                      selected={formik.values.payment_method == "razorpay"}
                      //defaultChecked="razorpay"
                      onChange={(e) => handleOnChange(e)}
                      //onChange={formik.payment_method}
                      onBlur={formik.payment_method}
                      value="razorpay"
                    />
                    <span>Online Payment (Razorpay)</span>
                  </label>
                </li>
                {/* <li>
                  <label>
                    <input
                      name="payment_method"
                      defaultValue="paypal"
                      type="radio"
                      onChange={formik.payment_method}
                      onBlur={formik.payment_method}
                      value={formik.values.payment_method}
                    />
                    <span>
                      Online Payment (Paypal) <br />
                      <span style={{ color: "red" }}>
                        Only for International Banking / Card
                      </span>
                    </span>
                  </label>
                </li> */}
                <li>
                  <label>
                    <input
                      name="payment_method"
                      value="cod"
                      type="radio"
                      selected={formik.values.payment_method == "cod"}
                      onChange={(e) => handleOnChange(e)}
                      //onChange={formik.payment_method}
                      onBlur={formik.payment_method}
                      //value={formik.values.payment_method}
                    />
                    <span>Cash On Delivery</span>
                  </label>
                </li>
              </ul>
              {formik.touched.payment_method &&
                formik.errors.payment_method ? (
                <p className="text-danger text-small text-left">
                {formik.errors.payment_method}
                </p>
                ) : null}
              <div className="button-position" id="visiblePage">
                <div className="row">
                  <div className="col-sm-6 col-xs-12 no-padding">
                    <button
                      className="btn-process-continue"
                      type="submit"
                      id="place_final_order"
                    >
                      Place Order
                    </button>
                  </div>
                </div>
              </div>
              {/* </form> */}
            </div>
          </form>
        </div>
      </div>
    </main>
    {/* end MAIN */}
  </div>
</>



  </div>
  {/* block -floor -products / floor 1*/}
  {/* block -floor -products / floor 1*/}
</>


  );
}

export default Payment;
