import React, { useEffect, useState } from "react";
import { NavLink, Link } from 'react-router-dom';
import moment from "moment";
import Header from './layouts/Header';
import BottomNav from './layouts/BottomNav';
import Banner from './components/Banner';
import HomeProduct from './components/HomeProduct';
import HomeSectionBlock from './components/HomeSectionBlock';
import HomeSectionBlockone from './components/HomeSectionBlockone';
import config from "../config";
import { HomeApi } from "../apis/HomeApi";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "./layouts/Loader";
import OfferPopup from "./components/Modal/OfferPopup";
import GiftCategory from "./components/GiftCategory";
import { date } from "yup";


function Home() { 

  const [banners, setBanners] = useState([]);
  const [page, setPage] = useState({ page: 1 });
  const [homeproduct, setHomeProductData] = useState([]);
  const [homeproducttitle, setHomeProductTitle] = useState([]);
  const [homeproductsubCatId, setHomeProductsubCatId] = useState([]);
  const [loader, setLoader] = useState(false);
    const currentDate = moment().format("YYYY-MM-DD");
  const popupOpenDate = localStorage.getItem('popupOpenDate');
  const [popupOpen, setPopupOpen] = useState(true)
  const [popupInfo, setPopupInfo] = useState();
  const [giftCategory, setGiftCategory] = useState();
  const [offerPopupInfo, setofferPopupInfo] = useState({})
  // const [currentDate, setCurrentDate] = useState()

  
  


  

  


  const [activeData, setActiveData] = useState({
    page: 1
  });
  const [hasMore, sethasMore] = useState(true)



  useEffect(() => {
    getBanners();
    getHomeProducts(page, false);

    // if(currentDate.toLocaleDateString()=== popupOpenDate){
    //   setPopupOpen(false)
    // }
  }, []);



  const getHomeProducts = (page, is_push_data) => {
    HomeApi.getHomeProducts(page)
      .then((res) => {
        if (res.data.status) {
          //console.log(res.data.data.homecakes);

          if (is_push_data) {
            // setHomeProductsubCatId([...homeproductsubCatId, ...res.data.data?.sub_category_id]);
            // setHomeProductTitle([...homeproducttitle, ...res.data.data?.title]);
            setHomeProductData([...homeproduct, ...res.data.data]);
          } else {
            // setHomeProductTitle(res.data.data?.title);
            // setHomeProductsubCatId(res.data.data?.sub_category_id);
            setHomeProductData(res.data.data);
          }

          if (res.data.data?.length == 0) {
            sethasMore(false);
          }
        } else {
          //setHomeProductData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const fetchMoreData = () => {
    const data = {
      page: activeData.page + 1
    };
    getHomeProducts(data, true);
    setActiveData(data);
  };


  const getBanners = () => {
    setLoader(true);
    HomeApi.getBanners()
      .then((res) => {
        if (res.data.status) {
          setPopupOpen(res.data.offer.is_poup_open)
          setofferPopupInfo(res.data.offer.special_offer)
          setBanners(res.data.data);
          setGiftCategory(res.data.giftCategory);
          // setCurrentDate(res.data.data.offer.current_date)
          
        } else {
          setBanners([]);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  const closedOfferPopup =()=>{
    setPopupOpen(false)
    localStorage.setItem('popupOpenDate',currentDate ); 
  }


  return (
    <>
    
    {popupOpen && offerPopupInfo && currentDate != popupOpenDate ? <OfferPopup closedOfferPopup={closedOfferPopup}   offerPopupInfo={offerPopupInfo} currentDate={currentDate}  /> : <> </>} 
      <div className="wrapper">
        <Header />
        <div id="wrapper">
          <main className="site-main">
            {/* <HomeSectionBlockone /> */}
            {/* MAIN */}
           
            <HomeSectionBlock />
            <Banner banners={banners} />
            {loader && <Loader />}

            {/* <div className="block-floor-products block-floor-products-opt3 floor-products1 home-product-main-div">
             
              <NavLink to="/products/26">
                <img
                  src={config.IMAGE_URL + '/home-image/pinata.jpg'}
                  alt="Pinata Cake"
                />
              </NavLink>
            </div> */}

            {homeproduct?.length > 0 &&
              <InfiniteScroll
                dataLength={homeproduct?.length}
                next={fetchMoreData}
                loader={
                  <div className="col-sm-offset-5 col-sm-6 ajaxloader text-center">
                    <img
                      src={config.IMAGE_URL + '/common-images/loader_new.gif'}
                      alt="loader" />
                    <div>Breathe in... Breathe out...</div>
                    <div>Bringing more gifts</div>
                  </div>
                }
                hasMore={hasMore}
              >

                {homeproduct?.length > 0 ? (
                    <>
                    <HomeProduct products={homeproduct}/>
                    <GiftCategory  giftCategory={giftCategory}/>
                    </>
                ) : (
                  <></>
                )}

              </InfiniteScroll>}


            {/* List Products */}
            {/* tab product */}

            <div className="block-floor-products block-floor-products-opt3 floor-products1 home-product-main-div">
              <div className="block-content block-content-product">
                {/* List Products */}
                <div className="row">
                  <div className="col-sm-4  no-padding">
                    <NavLink to="/products/26">
                      <img
                        src={config.IMAGE_URL + '/home-image/mid-night-delivery1.png'}
                        alt="Midnight Cake"
                      />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>









          </main>
        </div>

      </div>
     


    </>

  );
}

export default Home;
