

import React from "react";
import InnerBackHeader from "../pages/layouts/InnerBackHeader";
import Footer from "../pages/layouts/Footer";


function AboutUs(){

    return(
        <>
         <InnerBackHeader isLogo={true} />
        <div className="wrapper">
 
  <div id="wrapper">
    {/* MAIN */}
    <main className="site-main home-product-main-div viewcartpadding">
      {/* Block  Breadcrumb*/}
      <div className="">
       
        {/* Main Content */}
        <h2 className="page-heading">
          <span className="page-heading-title2">About Us</span>
        </h2>
        <div className="content-text clearfix">
          <h1>Blissmygift </h1>
          <div className="divider" />
          <h3 className="tc-heading">
            We want to set a benchmark in online gifting
          </h3>
          <p className="text-justify">
            Taking birth from the idea to bridge the gap which is developing
            among an individual relations due to new life style, work pressure
            and busy schedules. We provide an option for "How to Celebrate a
            Relation". With{" "}
            <a href="https://www.blissmygift.com/" style={{ color: "#d64e89" }}>
              Blissmygift
            </a>
            , we endeavor to create an <strong>online gifting</strong> portal
            addressing the above need with passion of excelling in the field.
          </p>
          <p className="text-justify">
            In today's world everyone is so busy that going out to{" "}
            <strong>buy a gift</strong> is a pain. But you cannot miss to gift
            because a gift is a perfect way to tell someone how much you love
            them, care for them, want to make them happy, want them to have all
            that they want, and so many countless feelings that A GIFT can
            convey. Then what to do?
          </p>
          <p className="text-justify">
            Solution is to <strong>buy gifts online</strong>. By that you need
            not to be present in the city where you want gifts to be delivered
            and you can place order sitting anywhere, be it a bus, car, home,
            office, party or a beach. All you need to have is an internet
            connection.
          </p>
          <h2 className="tc-heading">What we do?</h2>
          <p className="text-justify">
            We provide you a trustworthy and convenient platform to choose best
            gift for your family, friends etc. for every occasion like
            birthdays, anniversaries, marriage, love, farewell and many more. We
            offer wide range of products in various categories like cakes,
            egg-less cakes, drawing cakes, 3D cakes, photo cakes, collectibles,
            chocolates, bouquet, flowers bunch, soft toys, greeting cards,
            candles, photo frames, handicrafts etc. We also customize gifts
            according to customer wish.
          </p>
          <p className="text-justify">
            We are all about how you celebrate your relation in the best
            possible manner which fulfill you with joy happiness and
            satisfaction.
          </p>
          <h2 className="tc-heading">How we can help?</h2>
          <div style={{ marginLeft: 10 }}>
            <h3 className="tc-heading">Delivery date and time</h3>
            <p className="text-justify">
              You can choose date and time of the delivery of your gifts. We do
              offer same day delivery but its alway better to place your order
              atleast 2 days in advance. That way we could serve you better and
              you have more options to customize your order as per your needs.
              Many times due to large number of orders you might see few time
              slots disabled. To avoid such scenarios it's advised to place
              order 2-3 days in advance.
            </p>
            <h3 className="tc-heading">Talk to us</h3>
            <p className="text-justify">
              You can find the perfect gift yourself or talk to our gift
              connoisseurs who can help you. Gift Connoisseurs is our team who
              has very good taste in gifting. They can help you find the perfect
              gift for every person and every occasion.
            </p>
            <h3 className="tc-heading">What makes us special</h3>
            <p className="text-justify">
              Our specialization resides in cakes and flowers delivery across
              India. As cakes and flowers are synonym for any kind of
              celebration, our team put special efforts to make sure the timely,
              fresh and safe delivery of these products in entire Bangalore and
              Hyderabad. On top of that, we made it sure that customer always
              gets great quality cakes and flowers at a reasonable prices.
              Besides that, services like midnight delivery, liberty to choose
              delivery date and time, and highly supportive customer support
              staff makes us stand apart.
            </p>
          </div>
          <div className="divider" />
          <p style={{ paddingBottom: 40 }}>
            So many relations, so many reasons to love and celebrate.
            <br />
            Surprise them, gift them and celebrate the uniqueness of very
            relation with Blissmygift. So, go ahead and{" "}
            <strong>order cake online</strong>.
          </p>
        </div>
      </div>
      {/* Main Content */}
    </main>
    {/* end MAIN */}
  </div>
  {/* FOOTER */}
 <Footer/>
  {/* end FOOTER */}
  {/*back-to-top  */}
  {/* <a href="#" className="back-to-top">
    <i aria-hidden="true" className="fa fa-angle-up" />
  </a> */}

</div>

        
        </>
    )
}

export default AboutUs;
