import config from "../config";
import axios from "axios"
import * as url from "../helpers/url_helper";
//import { setAuthorizationToken } from "helpers/authorization";
//setAuthorizationToken();
export const AuthApi = {
    loginFirst: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.CHECK_LOGIN}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    loginVerify: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.CHECK_VERIFY}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    Registration: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.REGISTRATION}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    ForgotPassword: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.FORGOT_PASSWORD}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    }
}