import React from "react";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="footer-opt-3 mb-10">
        <div className="footer-top">
          {/* <div className="container"></div> */}
        </div>
        {/* <div className="footer-content"> </div> */}
        <div className="">
          <div className="  text-center">
            <div className="copyright text-center">
              © 2023 Blissmygift.com. All Rights Reserved
            </div>
          </div>

          <div className="text-center">
            <NavLink to={"/contact-us"}> Contact US </NavLink>
            <span style={{ padding: 10}}>      |     </span>

            <NavLink to={"/terms-and-conditions"}> Terms & Condintions  </NavLink>
          </div>

          <div className="text-center" >    <NavLink   to={"/"} >Wishes</NavLink> </div>

        
        </div>
      </footer>
    </>
  );
}
export default Footer;
