import React, { useEffect, useState } from "react";
import { NavLink, Link } from 'react-router-dom';
import config from "../../config";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function HomeSectionBlock() {


  const products = [
    { id: 23, src: '/home-image/cakes_new1.jpg', alt: 'Anniversary', label: 'Cakes' },
    { id: 78, src: '/home-image/flowers-new.jpg', alt: 'Chocolates', label: 'Flowers' },
    { id: 115, src: '/home-image/combo-new.jpg', alt: 'Flowers', label: 'Combo' },
    { id: 128, src: '/home-image/birthday-new.jpg', alt: 'Combo', label: 'Birthday' },
    { id: 151, src: '/home-image/anniversary-new.jpg', alt: 'Combo', label: 'Anniversary' },
    { id: 217, src: '/home-image/Chocolates-new.jpg', alt: 'Combo', label: 'Chocolates' },
    { id: 273, src: '/home-image/Indoor-Plants.jpg', alt: 'Combo', label: 'Plants' },
    { id: 426, src: '/home-image/mug.jpg', alt: 'Combo', label: 'Mug' },
  ];

  return ( 
    <>

        <div className="block-floor-products block-floor-products-opt3 floor-products1 home-product-main-div">
      <div className="">
        <div className="row">
          {products.map((product) => (
            <div key={product.id} className="col-sm-3 col-xs-3 home-new-thumb">
              <NavLink to={`/products/${product.id}`}>
                <LazyLoadImage
                  alt={product.alt}
                  effect="blur"
                  wrapperProps={{ style: { transitionDelay: '1s' } }}
                  src={`${config.IMAGE_URL}${product.src}`}
                />
                <div className="text-center">{product.label}</div>
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    </div>
    </>

  );
}

export default HomeSectionBlock;
