import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, NavLink, Link } from "react-router-dom";
import InnerBackHeader from "./../layouts/InnerBackHeader";
import BottomNav from "./../layouts/BottomNav";
import { HomeApi } from "../../apis/HomeApi";
import * as Yup from "yup";
import { AuthApi } from "../../apis/AuthApi";
import { getTempUserData, setTempUserData } from "../../Token";
import config from "../../config";
import Facilities from "../layouts/Facilities";
import Loader from "../layouts/Loader";
import { useParams } from "react-router-dom";

function LoginFirstDev() {
  const [activeData, setactiveData] = useState({});
  const [countryCode, setCountryCode] = useState([]);
  const [inputtype, setinputtype] = useState("email");
  const urlParam = new URLSearchParams(window.location.search);
  const redirectTo = urlParam.get("redirectTo");
  const [loginWithEmail, setLoginWithEmail] = useState(true);
  const [loader, setLoader] = useState(false);
  const params = useParams();
  const productID = urlParam.get("product_id");
  const [inputValue, setInputValue] = useState('');
  const [loginMethod, setLoginMethod] = useState('email');


  let navigate = useNavigate();
  useEffect(() => {
    getCountryCode(activeData);
  }, []);

  const getCountryCode = (activeData) => {
    setLoader(true);
    HomeApi.getCountryCodeData(activeData)
      .then((res) => {
        if (res.data.status) {
          setCountryCode(res.data.data);
        } else {
          setCountryCode([]);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  const formik = useFormik({
    initialValues: {
      inputtype: inputtype,
      email: "",
      country_code: "",
      mobile: "",
    },

    validationSchema: Yup.object().shape(
      {
        email: Yup.mixed().when("inputtype", {
          is: "email",
          then: () => Yup.string().email().required("Please enter email"),
          otherwise: () => Yup.mixed().nullable(),
        }),
        mobile: Yup.mixed().when("inputtype", {
          is: "phone",
          then: () =>
            Yup.number()
              .typeError("Enter a valid phone number")
              .positive("Phone number can't start with a minus.")
              .integer("A phone number can't include a decimal point")
              .min(7)
              .required("Please enter mobile"),
          otherwise: () => Yup.mixed().nullable(),
        }),
      },
      []
    ),
    validateOnChange: false, // Set to false to disable validation on change
    validateOnBlur: false, // Set to false to disable validation on blur
    onSubmit: (values) => {
      console.log(values)
      const data = new FormData();
      data.append("inputtype", inputtype);
      data.append("email", values.email);
      data.append("country_code", values.country_code);
      data.append("mobile", values.mobile);
      
      AuthApi.loginFirst(data)
        .then((res) => {
          if (res.data.status) {
            // console.log(res.data.data.redirect);
            // setCartId(res.data.data.cart_id);
            if (res.data.data.redirect == "login") {
              setTempUserData(JSON.stringify(res.data.data));
              var path = `/login-verify`;
              if (redirectTo != null) {
                path = `/login-verify?redirectTo=` + redirectTo;
              }
             
                if (redirectTo ==="write-review") {
                
                  path = `/login-verify?redirectTo=${redirectTo}&product_id=${productID}`;
                } 

               
              navigate(path);
            } else {
              setTempUserData(JSON.stringify(res.data.data));
              var path = `/registration`;
              if (redirectTo != null) {
                path = `/registration?redirectTo=` + redirectTo;
              }
              navigate(path);
            }
          } else {
            alert(res.data.message);
            //setErrorMsg(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  });

  const activeTab = (tabname) => {
    if (tabname === "email") {
      setLoginWithEmail(true);
    } else {
      formik.resetForm();
      setLoginWithEmail(false);
    }
    setinputtype(tabname);
    formik.setValues({
      ...formik.values,
      inputtype: tabname,
      email: "",
      country_code: "",
      mobile: "",
    });
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue("email", value)
    setInputValue(value);
    // Check if the input contains only digits
    if (/^\d+$/.test(value)) {
      // If yes, switch to phone login
      setLoginWithEmail(false);
    } else {
      // If no, switch to email login
      setLoginWithEmail(true);
    }
  };

  const handlePhoneInputChange = (event) => {
    const value = event.target.value;
    // Assuming the country code is added in front of the input
    formik.setFieldValue("mobile" ,value);
    if (/^\d+$/.test(value)) {
      // If yes, switch to phone login
      setLoginWithEmail(  );
    } else {
      // If no, switch to email login
      setLoginWithEmail(true);
    }
  };

  return (
    <>
      {/* title="Login" */}
      <InnerBackHeader isLogo={true} />
      {loader && <Loader />}

      <>
       
        <main className="site-main">
          <div className="columns ">
            <div className="row ">
              <div className="col-sm-12 mt-30">
                <div className="box-authentication">
                  <div className="login-container">
                    <div className="row">
                      <div className="width col l12 m12 s12 no-padding">
                        <div className="login-box">
                          <h4 className="center-align login-title text-bold">
                            Login
                          </h4>

                          <form
                            className="login-login-form"
                            onSubmit={formik.handleSubmit}
                          >

                          <fieldset className="login-input-container left-align signup_wrapper">
                            <ul className="tabs">
                              <li
                                className="tab col m6"
                                id="deactivateEmailTab"
                                // onClick={() => activeTab("email")}
                              >
                                <a
                                  // href="#emailTab"
                                  className={` ${
                                    loginWithEmail ? "login-hover-effect" : " "
                                  }`}
                                >
                                  Email Id
                                </a>
                              </li>
                              <li
                                className="tab col m6"
                                id="deactivatePhoneTab"
                                // onClick={() => activeTab("phone")}
                              >
                                <a
                                  // href="#phoneNumberTab"
                                  className={` ${
                                    loginWithEmail ? "" : "login-hover-effect"
                                  }`}
                                >
                                  Phone Number
                                </a>
                              </li>
                              {/* <li
                               className={` ${
                                loginWithEmail ? "indicator tab-left-active" : "indicator tab-right-active"
                              }`}
                                
                              /> */}
                              <li

                              
                              className="indicator"
                              style={
                                loginWithEmail
                              ? { left: 0, right: 187 }
                              : 
                              { left: 138, right: 1 }
                             
                              }
                              />
                            </ul>
                            <br />
                            <div
                              id="emailTab"
                              className={` row ck-login-email-wrapper ${
                                loginWithEmail ? "active" : "display_none"
                              } `}
                              // className="row ck-login-email-wrapper active"
                            >
                              <div className="input-field  s12 inputField">
                                <input
                                  id="email"
                                  type="text"
                                  placeholder="Enter Your Email"
                                  name="email"
                                //  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.email}
                                  onChange={handleInputChange}
                                />
                                {formik.touched.email && formik.errors.email ? (
                                  <p className="text-danger text-small text-left">
                                    {formik.errors.email}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            <div
                              id="phoneNumberTab"
                              className={` row ck-login-email-wrapper ${
                                loginWithEmail ? "display_none" : "active"
                              } `}
                              // className={` row ck-login-email-wrapper display_none`}
                            >
                              <div className="inputField">
                                <div className="input-field col s3 inputvalidate">
                                  <select
                                    className="browser-default"
                                    id="country_code"
                                    name="country_code"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.country_code}
                                  >
                                    {countryCode?.map((item, i) => (
                                      <option value={item.country_code}>
                                        +{item.country_code}
                                      </option>
                                    ))}
                                  </select>
                                  {formik.touched.country_code &&
                                  formik.errors.country_code ? (
                                    <p className="text-danger text-small text-left">
                                      {formik.errors.country_code}
                                    </p>
                                  ) : null}
                                </div>

                                <div className="input-field mobileNumberw col s9 mobileField">
                                  <input
                                    id="contact_no"
                                    name="mobile"
                                    type="tel"
                                    maxLength={15}
                                    placeholder="Enter Your Phone Number"
                                    // onChange={formik.handleChange}
                                    onChange={handlePhoneInputChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.mobile}
                                  />
                                  {formik.touched.mobile &&
                                  formik.errors.mobile ? (
                                    <p className="text-danger text-small text-left">
                                      {formik.errors.mobile}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="row fl-remove">
                              <div className=" s12">
                                <button
                                  id="login-continue"
                                  type="submit"
                                  className="  font-18 submitBtn waves-effect  get-password-btn waves-light btn"
                                >
                                  Continue
                                </button>
                              </div>
                              {/* <p className="signupAlign forgot-btn">
                                {" "}
                                <NavLink
                                  className="forpass login-create-account-link modal-trigger forgotPwdM"
                                  to="/forgot-password"
                                  style={{ color: "#1794F4", fontWeight: 500 }}
                                >
                                  Forgot Password
                                </NavLink>
                              </p> */}
                            </div>
                            
                           <Facilities/>
                           </fieldset>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>

      {/* end MAIN */}

      {/* block -floor -products / floor 1*/}
      {/* block -floor -products / floor 1*/}
    </>
  );
}

export default LoginFirstDev;
