module.exports = {

    /*dev*/
    API_URL: "https://www.blissmygift.com/api",
    BASE_URL: "https://www.blissmygift.com",
    IMAGE_URL:"https://www.blissmygift.com/assets/images",


    // API_URL: "http://localhost/amol/project/blissmygift/blissmygift.com/api",
    // BASE_URL: "http://localhost/amol/project/blissmygift/blissmygift.com/",
    // IMAGE_URL:"http://localhost/amol/project/blissmygift/blissmygift.com/assets/images",

    
    
    LIMIT:10,
    CLIENT_ID: "4Sp7q01ds42i4oHgjwgmv2nZ"
}