import config from "../config";
import axios from "axios"
import * as url from "../helpers/url_helper";

export const CouponsApi = {
    getProductOffer: async (data) => {
        var axiosConfig = {
            method: 'GET',
            url: `${config.API_URL}/${url.PRODUCT_OFFER}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    applyCouponCode: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.APPLY_COUPON_CODE}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    removeCouponCode: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.REMOVE_COUPON_CODE}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },


    
    
}