import React, { useEffect, useState } from "react";
import InnerBackHeader from "../layouts/InnerBackHeader";
import BottomNav from "../layouts/BottomNav";
import { NavLink, useFetcher, useNavigate } from "react-router-dom";
import { getUserId , getUserInfo, } from "../../Token";
import LogoutConfirmationModal from "../auth/LogoutConfirmationModal";
function Profile() {
  const [userLogin, setUserLogin] = useState(false)
  const userInfoString = getUserInfo()
  const [userFirstName, setUserFirstName] = useState("")
  const [userLastName, setUserLastName] = useState("")
  const [userEmailName, setUserEmailName] = useState("")
  const [ logoutConformation,  setLogoutConformation] =useState()

  const navigate = useNavigate();


  useEffect(()=>{
    checkLogin();
  
  },[])



  const handleUserLogout =() =>{
    setLogoutConformation(true)
    
    
    // localStorage.clear();
    // localStorage.removeItem("user_id");
    // localStorage.removeItem("user_info");
    // localStorage.removeItem("user_temp_info");
    // window.location.href = '/';
    // setUserLogin(false);

  }

 const  checkLogin = () => {
  if(getUserId() != ""){
    const userInfo = JSON.parse(userInfoString);
    setUserLogin(true);
    setUserFirstName(userInfo.firstname);
    setUserLastName(userInfo.lastname);
    setUserEmailName(userInfo.email);
  }else{
    setUserLogin(false)
    setUserFirstName('');
    setUserLastName('');
    setUserEmailName('');
  };

  


 }

 const findMyOrder =()=>{

  if (getUserId() != "") {
    navigate("/order-list");
 } else {
   
   navigate(`/login?redirectTo=order-list`);

 }
  
 }

 const handleLogoutModal =(confirmed)=> {
  if(confirmed){
    localStorage.clear();
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_info");
    localStorage.removeItem("user_temp_info");
    window.location.href = '/';
    setUserLogin(false);

  }else{
    setLogoutConformation(false)
  }

 }
 
 const handleUserLogin =()=> {
  navigate("/login")
 }


  return (
    <>
      <InnerBackHeader isLogo={true} />
      <>
     

      
        <main className="">
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n            .gray-background {\n                background: #666666;\n            }\n            @media only screen and (max-width: 340px) {\n                .profile-big-icon {\n                    margin-top: -45px !important;\n                }\n            }\n/*             @media only screen and (min-width: 320px) and (max-width: 450px) { \n                .font-size {\n                    font-size:14px !important;\n                }*/\n        ",
            }}
          />
          <div
            className="adbMyProfile  profile-content"
            style={{ width: "100% !important", paddingBottom: 45 }}
          >
            <div className="login-section">
              <div className="gray-background" style={{ height: 100 }}></div>
              <div className="login-button-section">
                <div
                  className="row white"
                  style={{
                    marginBottom: 0,
                    position: "relative",
                    paddingBottom: 30,
                  }}
                >
                  <div className="col s5">
                    <div
                      className="white profile-big-icon ft-65 "
                      style={{
                        marginTop: "-67px",
                        // padding: "30px 40px",
                        padding: "20px",
                        textAlign: "center",
                        boxShadow: "0px 3px 6px #00000029",
                      }}
                    >
                      <i class="fa fa-user-o" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div
                    className="col s7"
                    style={{
                      bottom: 30,
                      position: "absolute",
                      marginLeft: "41.66%",
                    }}
                  >
                    {userLogin ?(<>  <div className="loggedUser" >
                      <div
                        className="font-size truncate"
                        style={{
                          position: "relative",
                          top: "-5px",
                          textAlign: "left",
                          fontWeight: 600,
                          fontSize: 18,
                          textTransform: "capitalize",
                        }}
                      >
                        <span style={{ fontWeight: 400 }}>Hello,</span>{" "}
                        <span id="userName"> <span> {userFirstName}  {userLastName}  </span></span>
                      </div>
                      <div
                        className="font-size truncate"
                        id="userEmailId"
                        style={{
                          position: "relative",
                          top: "-7px",
                          textAlign: "left",
                          fontSize: 15,
                          color: "#666666",
                        }}
                      />

                      <span> { userEmailName}</span>
                    </div> </>):(   <div className="isAnonymous"  onClick={handleUserLogin}>
                      <div
                        className="adbProfileLoginSignUP"
                        style={{
                          width: "100%",
                          textAlign: "center",
                          padding: "3%",
                          backgroundColor: "#2b4591",
                          boxShadow: "0px 3px 6px #00000029",
                        }}
                      >
                        <div
                          style={{
                            color: "#FFFFFF",
                            fontSize: 12,
                            fontWeight: 400,
                          }}
                          // to={"/login"}
                        >
                          LOG IN/SIGN UP
                        </div>
                      </div>
                    </div>   )}
                   

                   
                  </div>
                </div>
              </div>
            </div>

           

            {/* Orders */}
            <div
              className="row white adbProfileOrderStatus"
              style={{
                marginTop: 10,
                marginBottom: 0,
                padding: "15px 0",
                borderBottom: "1px solid #e1dfdf",
              }}
              onClick={findMyOrder}
            >
              {/* <NavLink to={"/order-list"}> */}
                <div
              
                  className="col s2 center-align ft-25"
                  style={{ paddingRight: 0 }}
                >
                  <i class="fa fa-shopping-bag" style={{ font: 50 }}></i>
                </div>
                <div className="col s10">
                  <div
                    style={{
                      color: "#666666",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    Orders
                  </div>
                  <div style={{ fontSize: 12, color: "#666666" }}>
                    Check your order status
                  </div>
                </div>
              {/* </NavLink> */}
            </div>

             {/* My Acount */}
             {/* <div
              className="row white adbProfileOrderStatus"
              style={{
                marginTop: 10,
                marginBottom: 0,
                padding: "15px 0",
                borderBottom: "1px solid #e1dfdf",
              }}
            >
              <NavLink to={"/profile/myaccount"}>
                <div
                  className="col s2 center-align ft-25"
                  style={{ paddingRight: 0 }}
                >
                  <i class="fa fa-user" aria-hidden="true"></i>
                </div>
                <div className="col s10">
                  <div
                    style={{
                      color: "#666666",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    Update Profile
                  </div>
                  <div style={{ fontSize: 12, color: "#666666" }}>
                  Update profile name, password
                  </div>
                </div>
              </NavLink>
            </div> */}

            {/* Coupons */}
            <div
              className="row white adbProfileOrderStatus"
              style={{
                marginTop: 10,
                marginBottom: 0,
                padding: "15px 0",
                borderBottom: "1px solid #e1dfdf",
              }}
            >
              <NavLink to={"/offers"}>
                <div
                  className="col s2 center-align ft-25"
                  style={{ paddingRight: 0 }}
                >
               <i class="fa fa-ticket" aria-hidden="true" > </i>
                </div>
                <div className="col s10">
                  <div
                    style={{
                      color: "#666666",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                   Coupon
                  </div>
                  <div style={{ fontSize: 12, color: "#666666" }}>
                    Check all the coupon
                  </div>
                </div>
              </NavLink>
            </div>

            {/* Help Center */}
            <div
              className="adbProfileHelp row white"
              style={{ marginBottom: 0, padding: "15px 0" }}
            >
              <NavLink  to={"/contact-us"} >
                <div
                  className="col s2 center-align ft-25"
                  style={{ paddingRight: 0 }}
                >
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                </div>
                <div className="col s10">
                  <div
                    style={{
                      color: "#666666",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    Help Center
                  </div>
                  <div style={{ fontSize: 12, color: "#666666" }}>
                    Help regarding your recent purchases
                  </div>
                </div>
              </NavLink>
            </div>

            {/* Recover Password */}
            {userLogin ?

            <div
              className="row white adbProfileOrderStatus"
              style={{
                marginTop: 10,
                marginBottom: 0,
                padding: "15px 0",
                borderBottom: "1px solid #e1dfdf",
              }}
            >
              <NavLink to={"/forgot-password"}>
                <div
                  className="col s2 center-align ft-25"
                  style={{ paddingRight: 0 }}
                >
              <i class="fa fa-unlock-alt" aria-hidden="true"></i>
                </div>
                <div className="col s10">
                  <div
                    style={{
                      color: "#666666",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                   Recover Password
                  </div>
                  <div style={{ fontSize: 12, color: "#666666" }}>
                    Forgotten Passwords? Rediscover Access with Ease
                  {/* Seamless Password Recovery: Your Key to Reaccess in Seconds. */}
                  </div>
                </div>
              </NavLink>
            </div>
            : <> </>}
          
            <div
              className="row white mb-22"
              style={{ marginTop: 10, padding: "15px 0" }}
            >
              <div className="col s2"></div>
              <div className="col s10">
                <div className="row" style={{ marginBottom: 0 }}>
                  <NavLink className="adbProfileFAQ" to={"/faq"}>
                    <div
                      className="col s12"
                      style={{ padding: "5px 5px 5px 12px" }}
                    >
                      <div style={{ color: "#666666" }}>FAQs</div>
                    </div>
                  </NavLink>
                  <NavLink className="adbProfileAboutUs" to={"/about-us"}>
                    <div
                      className="col s12"
                      style={{ padding: "5px 5px 5px 12px" }}
                    >
                      <div style={{ color: "#666666" }}>About Us</div>
                    </div>
                  </NavLink>
                  <NavLink
                    className="adbProfilePrivacyPolicy"
                    to={"/privacy-policy"}
                  >
                    <div
                      className="col s12"
                      style={{ padding: "5px 5px 5px 12px" }}
                    >
                      <div style={{ color: "#666666" }}>Privacy Policy</div>
                    </div>
                  </NavLink>
                  <NavLink
                    className="adbProfileCondition"
                    to={"/terms-and-conditions"}
                  >
                    <div
                      className="col s12"
                      style={{ padding: "5px 5px 5px 12px" }}
                    >
                      <div style={{ color: "#666666" }}>
                        Terms &amp; Conditions
                      </div>
                    </div>
                  </NavLink>

                  {userLogin ? ( <div
                    className="adbProfileLogOut loggedUser"
                    // style={{ display: "none" }}
                  >
                    <div
                      className="pr-0"
                      id="logoutFromProfile"
                      // href="javascript:;"
                      // data-uri="/customer/logout"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      <div
                        className="col s12"
                        style={{ padding: "5px 5px 5px 12px" , color: "#666666" }}
                      >
                       <NavLink to="#"  id="logout"  onClick={() => handleUserLogout()}>
                      Logout
                    </NavLink>
                    

                    
                        {/* <div style={{ color: "#666666" }}>Logout</div> */}
                      </div>
                    </div>
                  </div>   ) : (<> </>)}


                  
                  
                  {logoutConformation && <LogoutConfirmationModal handleLogoutModal={handleLogoutModal}/>}

                  
                </div>
              </div>
            </div>
          </div>


          {/* Logout conformation modal */}


          
  {/* Button trigger modal */}
  <div
  className="modal fade"
  id="logout"
  tabIndex={-1}
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">
          Modal title
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">...</div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          Close
        </button>
        <button type="button" className="btn btn-primary">
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>

 


        
        </main>
      </>
      <BottomNav />
    </>
  );
}
export default Profile;
