import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InnerBackHeader from "./layouts/InnerBackHeader";
import { NavLink, Link, useParams } from "react-router-dom";
import { CartApi } from "../apis/CartApi";
import { setCartId, getCartId, setUserId, getUserId } from "../Token";
import config from "../config";
import { useDispatch } from "react-redux";
import { fetchCartCount } from "../actions/cartActions";
import Loader from "./layouts/Loader";
import LoginModal from "./components/Modal/LoginModal";
import CouponCodeModal from "./components/Modal/CouponCodeModal";
import { CouponsApi } from "../apis/CouponCodeApi";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
function ViewCart(props) {
  // const state = useSelector((state) => state);
  const dispatch = useDispatch();
  //
  // console.log('state',state);

  const [activeData, setActiveData] = useState({
    cart_id: getCartId(),
  });
  const [cartDetails, setCartDetails] = useState([]);
  const [cart, setCart] = useState([]);
  const [addonsProduct, setAddonsProduct] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isCartEmpty, setIsCartEmpty] = useState(true);
  const [cartDiscount, setCartDiscount] = useState();
  const [couponName, setCouponName] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [removeCouponCodeMsg, setRemoveCouponCodeMsg] = useEffect();

  let navigate = useNavigate();
  useEffect(() => {
    getViewCart(activeData);
  }, []);

  const getViewCart = (activeData) => {
    setLoader(true);
    CartApi.getViewCart(activeData)
      .then((res) => {
        // console.log(res.data.data.cart);
        if (res.data.status) {
          if (res.data.data.cartDetails.length > 0) {
            setIsCartEmpty(true);
          } else {
            setIsCartEmpty(false);
          }
          setAddonsProduct(res.data.data.addonsProducts);
          setCartDetails(res.data.data.cartDetails);
          setCart(res.data.data.cart);
          dispatch(fetchCartCount(res.data.data.cart_count));
          setLoader(false);
        } else {
          setIsCartEmpty(false);
          setAddonsProduct([]);
          setCartDetails([]);
          setCart([]);
          dispatch(fetchCartCount(0));
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addAddProduct = (product_price_id) => {
    var data = {
      city_id: cart.city_id,
      cart_id: getCartId(),
      product_price_id: product_price_id,
    };
    CartApi.addonsAddToCart(data)
      .then((res) => {
        if (res.data.status) {
          navigate("/view-cart");
          getViewCart(activeData);
        } else {
          navigate("/view-cart");
          getViewCart(activeData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeCartProduct = (orders_products_id) => {
    var data = {
      city_id: cart.city_id,
      cart_id: getCartId(),
      orders_products_id: orders_products_id,
    };
    console.log(data)
    CartApi.removeCartItem(data)
      .then((res) => {
        if (res.data.status) {
          navigate("/view-cart");
          getViewCart(activeData);
          //window.location.href = "/view-cart";
        } else {
          navigate("/view-cart");
          getViewCart(activeData);
          //window.location.href = "/view-cart";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removePromoCoupon = () => {
    let data = { cart_id: getCartId() };
    CouponsApi.removeCouponCode(data)
      .then((res) => {
        if (res.data.status) {
          getViewCart(activeData);
        } else {
          // setCouponCodeMessage(res.data.message);
          navigate("/view-cart");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCartProduct = (orders_products_id, type, qty) => {
    var quantity = qty;
    if (type == "plus") {
      quantity = parseInt(qty) + parseInt(1);
    } else {
      quantity = parseInt(qty) - parseInt(1);
    }

    if (quantity > 0) {
      var data = {
        qty: quantity,
        cart_id: getCartId(),
        orders_products_id: orders_products_id,
      };
      CartApi.updateCartItem(data)
        .then((res) => {
          getViewCart(activeData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const continueToCheckout = (data) => {
    if (getUserId() != "") {
      let path = `/checkout`;
      navigate(path);
    } else {
      let path = `/login?redirectTo=checkout`;
      navigate(path);
    }
  };

  const addMoreProduct = (data) => {
    let path = `/`;
    navigate(path);
  };
  const handleChildData = (data) => {
    if (data.isDataUpdate) {
      getViewCart(activeData);
    }
    if (data.isModalOpen) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
    // // Handle data received from the child component
    // console.log('Data from Child:', data);
  };

  return (
    <>
      <InnerBackHeader title="My Cart" isLogo={true} />
      {loader && <Loader />}
      <div className="block-floor-products block-floor-products-opt3 floor-products1">
        <>
          <main className="site-main">
            {isCartEmpty ? (
              <div className="columns container  home-product-main-div viewcartpadding">
                <h2 className="page-heading">
                  <span className="page-heading-title2">Shopping Cart</span>
                </h2>
                <div className="page-content page-order ">
                  <div className="order-detail-content">
                    <div className="">
                      {cartDetails.map((item, i) => {
                        return (
                          <>
                            <div
                              className="row cart_summary cart-product-box"
                            >
                              <div className="col-xs-4 no-padding">
                                <NavLink
                                  to={`/product-details/${item.product_id}`}
                                >
                                  {/* <img
                                    alt={item.product_name}
                                    src={item.mobile_product_image}
                                  /> */}
                                    <LazyLoadImage
                                      alt={item.product_name}
                                      effect="blur"
                                      wrapperProps={{
                                          // If you need to, you can tweak the effect transition using the wrapper style.
                                          style: {transitionDelay: "1s"},
                                      }}
                                      src={item.mobile_product_image}
                                    />
                                </NavLink>
                              </div>
                              <div className="col-xs-8">
                                <a href="#">{item.product_name}</a>
                                <div className="cart_ref">
                                  {item.attribute_name} :{" "}
                                  {item.sub_attribute_name}
                                </div>
                                {item.eggless_price > 0 && (
                                  <>
                                    <div className="cart_ref">
                                      Eggless : Yes
                                    </div>
                                  </>
                                )}
                                {item.heart_shape_price > 0 && (
                                  <>
                                    <div className="cart_ref">
                                      Heart Shape : Yes
                                    </div>
                                  </>
                                )}

                                <div id="subtotal_price_11">₹ {item.total}</div>
                                <div className="col-xs-3 no-padding">
                                  <button
                                    data-field="qty0"
                                    data-type="minus"
                                    className="btn-number btn-sm btn-success qtybutton "
                                    onClick={(e) =>
                                      updateCartProduct(
                                        item.id,
                                        "minus",
                                        item.quantity
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-minus"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                                <div className="col-xs-4 no-padding">
                                  <input
                                    type="text"
                                    minLength={1}
                                    maxLength={12}
                                    name="qty0"
                                    id="qty0"
                                    value={item.quantity}
                                    readOnly=""
                                    className="form-control input-sm item-qty-box"
                                  />
                                </div>
                                <div className="col-xs-3 no-padding">
                                  {" "}
                                  <button
                                    data-field="qty0"
                                    data-type="plus"
                                    data-cart-details={11}
                                    className="btn-number  btn-sm btn-success qtybutton ml-15"
                                    onClick={(e) =>
                                      updateCartProduct(
                                        item.id,
                                        "plus",
                                        item.quantity
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-plus"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                              <div
                                className="col s12 p-20 "
                              >
                                <div
                                  className="initialRemove  modal-trigger cart-remove-btn"
                                  data-target="remove-pdd-21687"
                                >
                                  <button
                                    className=" customerImg "
                                    onClick={(e) => removeCartProduct(item.id)}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    />{" "}
                                    Remove{" "}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <br />
                          </>
                        );
                      })}

                      <div>
                        {/* apply CouponCode Display */}

                        {cart.discount > 0 ? (
                          <div
                            className="apply_coupon_display  apply-coupon-display"
                          >
                            <div  className="flex-1">
                              <div
                                className="coupon_name"
                              >
                              <b>  {cart.coupon_code} </b>  
                              </div>
                              <div className="coupon_subtitle">
                                Offered applied on the bill
                              </div>
                            </div>
                            <div
                              onClick={(e) => removePromoCoupon(e.target.value)}
                              className="removeCouponEventCart  customerImg coupon_remove"
                            >
                              <i className="fa fa-trash-o" /> Remove
                            </div>
                          </div>
                        ) : (
                          <>
                            <div
                              // data-target="#coupon-field"
                              // data-toggle="modal"
                              className="openDiscountModal modal-trigger apply_coupon_dashbox"
                              onClick={() => setIsModalOpen(true)}
                            >
                              <span>
                                <img
                                  src={
                                    config.IMAGE_URL +
                                    "/offers/gift-voucher.jpg"
                                  }
                                  
                                  className="apply-coupon-img"
                                />
                                <span
                                 className="apply-coupon-btn "
                                 
                                >
                                  &nbsp;Apply Coupons
                                </span>
                              </span>
                            </div>
                          </>
                        )}
                      </div>

                      {/* popup modal for login */}
                      {/* isModalClose={true}  */}
                      {isModalOpen ? (
                        getUserId() !== "" && getUserId() !== "undefined" ? (
                          <CouponCodeModal
                            total={cart.total}
                            handleChildData={handleChildData}
                          />
                        ) : (
                          <LoginModal handleChildData={handleChildData} />
                        )
                      ) : null}

                      <table className="priceDetailsTable">
                        <tbody>
                          <tr>
                            <td className="priceDetali">
                              Price Details ({cartDetails.length})
                            </td>
                          </tr>
                          <tr>
                            <td>Sub Total </td>
                            <td className="text-right">
                              <strong id="classSubTotal">
                                ₹ {cart.sub_total}
                              </strong>
                            </td>
                          </tr>
                          <tr>
                            <td>Delivery Amount </td>
                            <td className="text-right">
                              {" "}
                              <strong>₹ {cart.delivery_charge}</strong>
                            </td>
                          </tr>

                          {cart.discount > 0 && (
                            <tr>
                              <td>Coupon Discount </td>{" "}
                              <td className="text-right">
                                <strong
                                  id="classTotal"
                                  className="text-success"
                                >
                                  {" "}
                                  - ₹ {cart.discount}
                                </strong>
                              </td>
                            </tr>
                          )}

                          <tr>
                            <td>Total </td>{" "}
                            <td className="text-right">
                              <strong id="classTotal">₹ {cart.total}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div>
                      <NavLink
                        to="/"
                        className="continueShoppingBtn btn-large waves-effect waves-light continueBtnHome"
                      >
                        Continue Shopping
                      </NavLink>
                    </div>

                    <div className="button-position">
                      <div className="row">
                        <div className="col-sm-12 col-xs-12  no-padding">
                          <button
                            className="btn-addmore-product "
                            onClick={(e) => continueToCheckout()}
                          >
                            {/* Add More Products */}
                            {/* Continue To Checkout  */}
                            <b className="">CONTINUE TO CHECKOUT</b>
                          </button>
                        </div>
                      
                      </div>
                    </div>
                  </div>
                </div>
                <br /> <br /> <br />
                <h2 className="page-heading">
                  <span className="page-heading-title2">
                    Make it special with Addons
                  </span>
                </h2>
                <div className="row mb-40">
                  {addonsProduct.map((item, i) => {
                    return (
                      <>
                        <div
                          className="col-sm-4 col-xs-4 addons"
                        >
                          <img
                            alt={item.product_name}
                            src={item.product_gallery.mobile_product_image}
                            className="w-100"
                          />
                          <div className="caption">
                            <p className="text-center">
                              <span> {item.product_name.substring(0, 12)}</span>
                              <br />
                              <b>₹ {item.product_price.price} </b>
                            </p>
                          </div>
                          <button
                            type="button"
                            className="btn btn-small addtocartbutton"
                            onClick={(e) =>
                              addAddProduct(item.product_price.id)
                            }
                          >
                            Add
                          </button>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="columns container  home-product-main-div viewcartpadding">
                <div className="text-center">
                  <img
                    alt="Empty Cart"
                    src={config.IMAGE_URL + "/common-images/empty-cart.png"}
                  />
                </div>
                <h4 className="text-center">
                  <span
                    className="page-heading-title2 logo-color"
                  >
                    Your Cart is Empty.
                  </span>
                </h4>
                <p className="text-center">
                  Add something to make me happy :).
                </p>
                <div className="text-center">
                  {/* <p className="text-center">Login to see the items you added previously</p> */}
                  <NavLink to="/" className="btn btn-success emptycartbutton">
                    SHOP NOW
                  </NavLink>

                </div>
                <br /> <br /> <br />
              </div>
            )}
          </main>
          {/* end MAIN */}
        </>
      </div>
      {/* block -floor -products / floor 1*/}
      {/* block -floor -products / floor 1*/}
    </>
  );
}

export default ViewCart;
