import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate,NavLink, Link } from 'react-router-dom';
import InnerBackHeader from './../layouts/InnerBackHeader';
import BottomNav from './../layouts/BottomNav';
import { HomeApi } from "../../apis/HomeApi";
import * as Yup from "yup";
import { AuthApi } from "../../apis/AuthApi";
import { getTempUserData,setTempUserData,setUserInfo,setUserId,setCartId, getCartId } from "../../Token";
import config from "../../config";
import Facilities from "../layouts/Facilities";
import Loader from "../layouts/Loader";



function ForgotPassword() {

    const [activeData, setactiveData] = useState({});
    const [countryCode, setCountryCode] = useState([]);
    const [message, setMessage] = useState('');
    const [loader, setLoader] = useState(false);
    
    let navigate = useNavigate(); 
    // let userTempData = JSON.parse(getTempUserData());
    
   



    const formik = useFormik({
        initialValues: {
          email: ""
    
        },
        validationSchema: Yup.object().shape(
          {
            email: Yup.string().required("Please enter email"),
          },
          []
        ),
        onSubmit: (values) => {
            setLoader(true);
          const data = new FormData();
          data.append("email", values.email);
          AuthApi.ForgotPassword(data)
            .then((res) => {
              if (res.data.status) {
                    setMessage(res.data.message);
                    // let path = `/`+res.data.data.redirect; 
                    // navigate(path);
              } else {
                alert(res.data.message);
                //setErrorMsg(res.data.message);
              }
              setLoader(false);
            })
            .catch((error) => {
                setLoader(false);
              console.log("error", error);
            });
        },
      });

    return (
        <>
            <InnerBackHeader isLogo={true} />
            

            <>
            {loader && <Loader />}
                <main className="site-main">
                    <div className="columns ">
                            <div className="row ">
                                <div className="col-sm-12 mt-30">
                                    <div className="box-authentication">
                                        <div className="login-container">
                                            <div className="row">
                                                <div className="width col l12 m12 s12 no-padding">
                                                    <div className="login-box">
                                                        <h4
                                                            className="center-align login-title text-bold"
                                                        >
                                                          Recover Password

                                                        </h4>
                                                        <div className="text-center mt-15" >
                                                        Choose your email below, we will send an password on email.
                                                        </div>
                                                        {message != '' ? (
                                                            // classname="jss39"
                                                            // style={{ textAlign: "center", color: "#008539" }}
                                                        <div className="text-center text-success" >
                                                        {message} <br/><br/>
                                                        <NavLink style={{ textAlign: "center", color: "blue" }}   to="/login">CLICK FOR LOGIN</NavLink>
                                                        </div>
                                                        ) : <></>}
                                                        
                                                        <form
                                                         id="registrationForm"
                                                        onSubmit={formik.handleSubmit}
                                                        >
                                                       
                                                            <fieldset className="login-input-container left-align signup_wrapper">
                                                                
                                                                <br />
                                                               
                                                                <div
                                                                    className="row ck-login-email-wrapper active"
                                                                >
                                                                    <div className="input-field  s12 inputField">
                                                                        <input
                                                                            id="email"
                                                                            type="text"
                                                                            placeholder="Enter Your Email"
                                                                            name="email"
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            value={formik.values.email}
                                                                        />
                                                                         {formik.touched.email &&
                                                                    formik.errors.email ? (
                                                                    <p className="text-danger text-small text-left">
                                                                    {formik.errors.email}
                                                                    </p>
                                                                    ) : null}
                                                                    </div>
                                                                   
                                                                </div>
                                                              

                                                                <div className="row fl-remove">
                                                                    <div className=" s12">
                                                                        <button
                                                                            id="login-continue"
                                                                            type="submit"
                                                                            className="submitBtn get-password-btn waves-effect waves-light btn"
                                                                           
                                                                        >
                                                                            GET PASSWORD
                                                                        </button>
                                                                    </div>
                                                                   
                                                                </div>
                                                               
                                                               <Facilities/>
                                                            </fieldset>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </main>
            </>


            {/* end MAIN */}

            {/* block -floor -products / floor 1*/}
            {/* block -floor -products / floor 1*/}
        </>


    );
}

export default ForgotPassword;
