
import React, { useEffect, useState } from "react";
import { NavLink, Link } from 'react-router-dom';
import config from "../../config";
import { useSelector } from "react-redux";
import { CartApi } from "../../apis/CartApi";
import { getCartId } from "../../Token";
import {  useDispatch } from "react-redux";
import { fetchCartCount } from "../../actions/cartActions";

function ViewCartCount(props) {
  // console.log(props.isRoundIcon);
//  const state = useSelector(
//     (state) => state.cart.fetchcartCountCartCount
//     );
  const dispatch = useDispatch();
  const state = useSelector((state) => state.cart.cart);
    const [activeData, setActiveData] = useState({
        cart_id: getCartId(),
   });
  const [cartCount, setCartCount] = useState([]);

  useEffect(() => {
    if(state === undefined || state === null) {
    getViewCart(activeData);
    } else {
    setCartCount(state);  
    }
  }, []);

  const getViewCart = (activeData) => {
    CartApi.getViewCartCount(activeData)
    .then((res) => {
    if (res.data.status) {
        dispatch(fetchCartCount(res.data.data.cart_count));
       // setCartCount(res.data.data.cart_count);
    } else {
        dispatch(fetchCartCount(0));
       // setCartCount(0);
    }
    })
    .catch((err) => {
    console.log(err);
    });
};

 // console.log('state',state); 
  return ( 
    <>
                 <div className="cartdiv">
                    <NavLink to="/view-cart">
                      
                       {props.isRoundIcon ? (
                        <img
                        src={config.IMAGE_URL + '/common-images/cart-round.png'}
                        alt="Cart"
                        width={32}
                        />
                        ) : 
                        <img
                        src={config.IMAGE_URL + '/common-images/cart-icon-app.png'}
                        alt="Cart"
                        width={30}
                        />
                        }
                        
                      <span className="view-cart-count">{state}</span>
                    </NavLink>
                  </div>
    </>

  );
}

export default ViewCartCount;
