import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate,NavLink, Link } from 'react-router-dom';

function ProfileSideBar() {

  const navigate = useNavigate();


  const handleLogout = () => {
    localStorage.clear();
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_info");
    localStorage.removeItem("user_temp_info");
    window.location.href = '/';
    //navigate("/");
  }


    return (
            <>
            <div className="profile-usermenu">
                <ul className="nav">
                  <li>
                    <NavLink to="/profile/myaccount">
                      Dashboard
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/profile/current-order">
                      Current Order
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/profile/past-order">
                      Past Order
                    </NavLink>
                  </li>
                  
                  <li>
                    <NavLink to="/profile/update-password">
                      Update Password
                    </NavLink>
                  </li>
                  
                  <li>
                    <NavLink to="#"  onClick={() => handleLogout()}>
                      Logout
                    </NavLink>
                  </li>
                </ul>
              </div>
              </>

    );
}

export default ProfileSideBar;
