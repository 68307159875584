import React, { useEffect, useState } from "react";
import { NavLink, Link } from 'react-router-dom';
import config from "../../config";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function HomeProduct({ products }) {
  return (
    <>
                    {products?.map((itemp, i) => {
                      return (
                        <div className="block-floor-products block-floor-products-opt3 floor-products1 home-product-main-div" key={i}>
                          <div className="">
                            <div className="block-content block-content-product">
                              <div className="row pb-10">
                                <div className="col-sm-6 col-xs-6 viewallleft">
                                  <h3 className="title home-page-product-row-titile">
                                    {itemp.title}
                                  </h3>
                                </div>
                                <div className="col-sm-6 col-xs-6 viewallright">
                                  <div className="view-all-btn">
                                    <NavLink to={`/products/${itemp.sub_category_id}`}>
                                      VIEW ALL{" "}
                                    </NavLink>
                                  </div>
                                </div>
                              </div>
                              <div className="products  products-grid">
                                <div className="product-items row">

                                  {itemp?.product.map((item, i) => {
                                    return (
                                      <NavLink to={`/product-details/${item.id}`} key={i} className="product-item-img">
                                        <div className="col-sm-2 col-xs-6 product-item product-item-main-div">
                                          <div className="product-item  product-item-opt-1 ">
                                            <div className="product-item-info">
                                              <div className="product-item-photo">
                                                <picture>
                                                  <source
                                                    srcSet={item.product_gallery.mobile_webp_product_image}
                                                    type="image/webp"
                                                  />
                                                  {/* <img
                                                    alt={item.product_name}
                                                    src={item.product_gallery.mobile_product_image}
                                                  /> */}
                                                   <LazyLoadImage
                                                      alt={item.product_name}
                                                      effect="blur"
                                                      wrapperProps={{
                                                          // If you need to, you can tweak the effect transition using the wrapper style.
                                                          style: {transitionDelay: "1s"},
                                                      }}
                                                      src={item.product_gallery.mobile_product_image}
                                                    />
                                                </picture>
                                              </div>
                                              <div className="product-item-detail">
                                                <strong className="product-item-name">
                                                  {item.product_name}
                                                </strong>
                                                <div className="clearfix">
                                                  <div className="product-item-price">
                                                    <span className="price">₹ {item.product_price.price}</span>
                                                  </div>

                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </NavLink>
                                    );
                                  })}

                                </div>


                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </>

  );
}

export default HomeProduct;
