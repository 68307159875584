

import React from "react";
import config from "../../config";


function Facilities(){


    return(
        <>
            
            <div
            className=" "
            style={{ marginTop: 25}}
            >
                      <img
                     src={
                        config.IMAGE_URL +
                        "/common-images/down-banner.jpg"
                      }   
                      
                      alt="Down Banner"
                      style={{ width: "100%", height: "100%" }}
                      />
            </div>

        
        
        </>
    )
}
export default Facilities;
