import React, { useEffect, useState } from "react";
import { NavLink, Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Banner({banners}) {
  return ( 
    <>
    <div id="myCarousel" className="carousel slide" data-ride="carousel">
          {/* Indicators */}
        
          {/* Wrapper for slides */}
          <div className="carousel-inner">
           
            {banners.map((item, i) => {
              return (

                  <div key={i} className= {`item ${i == 0 ? "active" : ""}`}>
                    <NavLink to={`/products/${item.app_category_id}`}>
                      <picture>
                        <source
                          srcSet={item.mobile_webp_banner_image}
                          type="image/webp"
                        />
                         <LazyLoadImage
                            alt="Cake"
                            effect="blur"
                            wrapperProps={{
                            style: {transitionDelay: "1s"},
                            }}
                            src={item.mobile_banner_image} />
                      

{/*                         
                        <img
                          srcSet={item.mobile_banner_image}
                          alt="Cake"
                        /> */}
                      </picture>
                    </NavLink>
                  </div>
                );
          })}
       
          </div>
          {/* Left and right controls */}
          <a
            className="left carousel-control"
            href="#myCarousel"
            data-slide="prev"
          >
            <span className="glyphicon glyphicon-chevron-left" />
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="right carousel-control"
            href="#myCarousel"
            data-slide="next"
          >
            <span className="glyphicon glyphicon-chevron-right" />
            <span className="sr-only">Next</span>
          </a>
        </div>
    </>

  );
}

export default Banner;
