import React from "react"
import config from "../../config";


function Loader(){


    return(
        <div
        className="loader app-loader"
        
      >
        <img
          id="loadingimg"
          src={config.IMAGE_URL + '/common-images/loader_new.gif'}
        
        />
      </div>
      



    )
}

export default Loader;
















