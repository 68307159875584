import React, { useEffect, useState } from "react";
import InnerBackHeader from "../../layouts/InnerBackHeader";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { HomeApi } from "../../../apis/HomeApi";
import ReactStars from "react-rating-stars-component";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ReviewApi } from "../../../apis/ReviewApi";
import { getUserId } from "../../../Token";

function ReviewForm() {
  const params = useParams();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const product_id = params.product_id;
  const [activeData, setActiveData] = useState({
    product_id: product_id,
  });
  const [loader, setLoader] = useState(false);
  const [productGallery, setProductGallery] = useState([]);
  const [productInfo, setProductInfo] = useState([]);
  const [productDetails, setProductDetails] = useState(null);
  const [productDetail, setProductData] = useState([]);
  const [reviewMessage, setreviewMessage] = useState("");
  // const [images, setImages] = useState([]);
  const [images, setImages] = useState();
  const [ratingStar, setRatingStar] = useState();
  const user_id = getUserId()

  useEffect(() => {
    getProductDetails(activeData);
    // console.log(product_id);
  }, []);

  const getProductDetails = (data) => {
    setLoader(true);
    HomeApi.getProductDetails(data)
      .then((res) => {
        if (res.data.status) {
          setProductData(res.data.data);
          setProductGallery(res.data.data.product_gallery);
          setProductDetails(res.data.data.product_detail.product_description);
        } else {
          console.log("Error fetching product details");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const ratingChanged = (newRating) => {
    setRatingStar(newRating);
  };
  const handleImageUpload = (event) => {
    const files = event.target.files[0];
    // const newImages = [...images];
    setImages(files);
  
    // if (newImages.length + files.length <= 5) {
    //   for (let i = 0; i < files.length; i++) {
    //     const fileUploaded = files[i];
    //     newImages.push(fileUploaded);
    //     const formData = new FormData();
    //     formData.append("file", fileUploaded);
    //     // Add formData to your existing logic or use it for API calls
    //   }
  
    //   // setImages(newImages);
      
    // } else {
    //   alert("You can only upload up to 5 images.");
    // }
  };
  

  // const handleImageUpload = (event) => {
  //   const files = event.target.files;
  //   const newImages = [...images];

  //   if (newImages.length + files.length <= 5) {
  //     for (let i = 0; i < files.length; i++) {
  //       newImages.push(files[i]);
  //     }

  //     setImages(newImages);
  //   } else {
  //     alert("You can only upload up to 5 images.");
  //   }
  // };

  const handleImageRemove = (index) => {
    // const newImages = [...images];
    // newImages.splice(index, 1);
    // setImages(newImages);
   
    setImages("");
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    review: Yup.string().required("Review is required"),
    // stra: Yup.number().required("Please provide a rating")
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      review: "",
      star: ratingStar ? ratingStar : 5
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const formData = {
        user_id:user_id,
        product_id:product_id,  
        name: values.name,
        feedback: values.review,
        star: values.star,
        file: images,
      };
      console.log("formData:", formData);

      ReviewApi.submitReview(formData)
        .then((res) => {
          setLoader(true);
          if (res.data.status) {
            console.log(res)
            setreviewMessage(res.data.message)
            alert(res.data.message);
            window.location.reload()
           
            // dispatch(fetchCartCount(res.data.data.cart_count));
            // navigate("/view-cart");
            //window.location.href = "/view-cart";
          } else {
            // alert(res.data.message);
            setreviewMessage(res.data.message)
            //setErrorMsg(res.data.message);
          }
        })
        .catch((error) => {
          alert("No")
          console.log("error", error);
        });
    },
    
  });

  return (
    <>
      <InnerBackHeader isLogo={true} />

      <div className="container-fluid mt-20 mb-22">
        <div className="row">
          <div className="col-xs-4 no-padding"> 
            <img
              src={productGallery.product_image}
              alt={productDetail.product_name}
              style={{ width: " 100px" }}
            />
          </div>
          
          <div className="col-xs-8 no-padding mt-30">
            <span className="font-weight-bold ml  ft-18">
              {" "}
              {productDetail.product_name}{" "}
            </span>

          </div>
         
        </div>
        <div style={{borderBottom:"1px solid", marginTop:"10px"}}></div>
        <div className="col-12 ft-18 ft-bolader mt-30 text-center">
          {" "}
          Rate this product
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <ReactStars
            count={5}
            onChange={ratingChanged}
            size={50}
            activeColor="#ffd700"
          />
          {formik.touched.star && formik.errors.star ? (
                  <p className="text-danger text-small text-left">
                    {formik.errors.star}
                  </p>
          ) : null}
        </div>

        <div className="row">
          <div className="col-md-6 mx-auto">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group" style={{ marginBottom: "1rem" }}>
                <label htmlFor="name" className="reviewer_name_lable">
                  Name:
                </label>
                <input
                style={{width:"93%"}}
                  type="text"
                  className="form-control reviewer_name_input"
                  id="name"
                  placeholder="Enter your name"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <p className="text-danger text-small text-left">
                    {formik.errors.name}
                  </p>
                ) : null}
              </div>
              <div className="form-group" style={{ marginBottom: "1rem" }}>
                <label htmlFor="review" className="reviewer_name_lable">
                  Review:
                </label>
                <textarea
                  className="form-control reviewer_name_input"
                  id="review"
                  rows="3"
                  placeholder="Enter your review"
                  name="review"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.review}
                ></textarea>
                {formik.touched.review && formik.errors.review ? (
                  <p className="text-danger text-small text-left">
                    {formik.errors.review}
                  </p>
                ) : null}
              </div>
              <div className="form-group" style={{ marginBottom: "1rem" }}>
                <label htmlFor="image" className="reviewer_name_lable">
                  Upload Image:
                </label>
                <small
                  id="imageHelp"
                  className="form-text text-muted"
                  style={{
                    fontSize: "0.875rem",
                    lineHeight: "1.5",
                    color: "#6c757d",
                  }}
                >
                  <span style={{ color: "#dc3545", paddingRight: "3px" }}>
                    *
                  </span>
                  Allowed only files with extension (jpg, png). Maximum number
                  of allowed files is 5 with max size 20 MB for each.
                </small>
                <div>
                  {/* {images.map((image, index) => ( */}
                  {images && 
                    <div
                      // key={index}
                      style={{
                        position: "relative",
                        display: "inline-block",
                      }}
                    >
                      
                      <img
                        // src={URL.createObjectURL(image)}
                        src={URL.createObjectURL(images)}
                        // alt={`Image ${index}`}
                        className="review_image"
                      />
                   


                      <button
                        // onClick={() => handleImageRemove(index)}
                        onClick={() => handleImageRemove(1)}
                        style={{
                          position: "absolute",
                          top: "0",
                          right: "0",
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                       <i class="fa fa-times" aria-hidden="true"></i>
                      </button>
                    </div>
                      }
                  {/* ))} */}
                  {/* {images.length === 0 || images[images.length - 1] !== null ? ( */}
                  {/* {images.length === 0  ? ( */}
                    <label
                      htmlFor="file-upload"
                      className="ft-30"
                      style={{
                        cursor: "pointer",
                        fontSize: "65px",
                        marginLeft: "10px",
                      }}
                    >
                      <input
                        id="file-upload"
                        type="file"
                        multiple
                        onBlur={formik.handleBlur}
                        onChange={handleImageUpload}
                        style={{ display: "none" }}
                      />
                      <i
                        className="fa fa-plus-square-o"
                        aria-hidden="true"
                      ></i>
                    </label>
                  {/* ) : null} */}
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                style={{ width: "100%" }}
              >
                Submit Review
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default ReviewForm;
