import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { NavLink, useNavigate } from "react-router-dom";
import InnerBackHeader from './../layouts/InnerBackHeader';
import BottomNav from './../layouts/BottomNav';
import { HomeApi } from "../../apis/HomeApi";
import * as Yup from "yup";
import { AuthApi } from "../../apis/AuthApi";
import { getTempUserData,setTempUserData,setUserInfo,setUserId,getCartId } from "../../Token";
import config from "../../config";
import Facilities from "../layouts/Facilities";
import Loader from "../layouts/Loader";
import { useSelector, useDispatch } from 'react-redux';


function LoginVerify() {
    const inputType = useSelector(state => state.inputType);

    const [activeData, setactiveData] = useState({});
    const [countryCode, setCountryCode] = useState([]);
    // const [inputtype, setinputtype] = useState('email');
    const [inputtype, setinputtype] = useState(inputType.inputType); 
    const urlParam = new URLSearchParams(window.location.search); 
    const redirectTo = urlParam.get('redirectTo');
    const [errorMsg, setErrorMsg] = useState("")
    const [loader, setLoader] = useState(false);
    const productID = urlParam.get("product_id");
   
    
    
    let navigate = useNavigate(); 
    let userTempData = JSON.parse(getTempUserData());
    useEffect(() => {
        getCountryCode(activeData);
      }, []);

    const getCountryCode = (activeData) => {
        setLoader(true);
        HomeApi.getCountryCodeData(activeData)
          .then((res) => {
            if (res.data.status) {
                setCountryCode(res.data.data);
            } else {
                setCountryCode([]);
            }
             setLoader(false);
          })
          .catch((err) => {
            setLoader(false);
            console.log(err);
          });
    };

    const activeTab = (tabname) => {
        setinputtype(tabname);
    };


    const formik = useFormik({
        initialValues: {
          inputtype: userTempData.inputtype,
          email: userTempData.email,
          country_code: userTempData.country_code,
          mobile: userTempData.mobile,
          password: ""
    
        },
        validationSchema: Yup.object().shape(
          {
            password: Yup.string().required("Please enter password"),
          },
          []
        ),
        onSubmit: (values) => {
            setLoader(true);
          const data = new FormData();
          data.append("inputtype", inputtype);
          data.append("email", values.email);
          data.append("country_code", values.country_code);
          data.append("mobile", values.mobile);
          data.append("password", values.password);
          data.append("cart_id", getCartId());
          AuthApi.loginVerify(data)
            .then((res) => {
              if (res.data.status) {
                    //setTempUserData("");
                    setUserInfo(JSON.stringify(res.data.data.user));
                    setUserId(res.data.data.user.id);
                    //let path = `/`+res.data.data.redirect;
                    var path = '/'; 
                    if (redirectTo === 'cart') {
                        path = '/view-cart';
                    } else if (redirectTo === 'checkout') {
                        path = '/checkout';
                    } else if (redirectTo === 'write-review') {
                        path = `/write-review/${productID}`;
                    } else if (redirectTo === 'order-list') {
                        path = '/order-list';
                    }
                    navigate(path);
              } else {
                // alert(res.data.message);
                setErrorMsg(res.data.message);
              }
              setLoader(false);

            })
            .catch((error) => {
                setLoader(false);
              console.log("error", error);
            });
        },
      });

    return (
        <>
            <InnerBackHeader isLogo={true}/>
            {loader && <Loader />}

            <>
                <main className="site-main">
                    <div className="columns ">
                            <div className="row ">
                                <div className="col-sm-12 mt-30">
                                    <div className="box-authentication">
                                        <div className="login-container">
                                            <div className="row  l12">
                                                <div className="width col l12 m12 s12 no-padding">
                                                    <div className="login-box">
                                                        <h4
                                                            className="center-align login-title text-bold"
                                                        >
                                                            Login
                                                        </h4>
                                                        
                                                        <form
                                                         className="login-login-form"
                                                        onSubmit={formik.handleSubmit}
                                                        >
                                                       
                                                            <fieldset className="login-input-container left-align signup_wrapper">
                                                                
                                                                <br />
                                                                { userTempData.inputtype == 'email' ? 
                                                                (
                                                                <div
                                                                    id="emailTab"
                                                                    className="row ck-login-email-wrapper active"
                                                                >
                                                                    <div className="input-field  s12 inputField">
                                                                        <input
                                                                       className="custom-placeholder"
                                                                            id="email"
                                                                            type="text"
                                                                            placeholder="Enter Your Email"
                                                                            name="email"
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            value={formik.values.email}
                                                                        />
                                                                         {formik.touched.email &&
                                                                    formik.errors.email ? (
                                                                    <p className="text-danger text-small text-left">
                                                                    {formik.errors.email}
                                                                    </p>
                                                                    ) : null}
                                                                    </div>
                                                                   
                                                                </div>
                                                                ) : (
                                                                <div
                                                                    id="phoneNumberTab"
                                                                    className=" row ck-login-email-wrapper"
                                                                >
                                                                    <div className="inputField">
                                                                        <div className="input-field col s3 inputvalidate p-0-imp">
                                                                            <select
                                                                                className="browser-default"
                                                                                id="country_code"
                                                                                name="country_code" 
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                value={formik.values.country_code}
                                                                            > 
                                                                            {countryCode?.map((item, i) => (  
                                                                            <option value={item.country_code} >+{item.country_code}</option>
                                                                            ))}
                                                                            </select>
                                                                            {formik.touched.country_code &&
                                                                            formik.errors.country_code ? (
                                                                            <p className="text-danger text-small text-left">
                                                                            {formik.errors.country_code}
                                                                            </p>
                                                                            ) : null}
                                                                        </div>
                                                                        
                                                                        <div className="input-field  col s9 inputvalidate">
                                                                            <input
                                                                                id="contact_no"
                                                                                name="mobile"
                                                                                type="tel"
                                                                                maxLength={15}
                                                                                placeholder="Enter Your Phone Number"
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                value={formik.values.mobile}
                                                                            />
                                                                            {formik.touched.mobile &&
                                                                            formik.errors.mobile ? (
                                                                            <p className="text-danger text-small text-left">
                                                                            {formik.errors.mobile}
                                                                            </p>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                ) }


                                                                <div
                                                                    id="emailTab"
                                                                    className="row ck-login-email-wrapper active"
                                                                >
                                                                    <div className="input-field  s12 inputField">
                                                                        <input
                                                                            id="password"
                                                                            type="password"
                                                                            placeholder="Enter Your Password"
                                                                            name="password"
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            value={formik.values.password}
                                                                        />
                                                                        {formik.touched.password && formik.errors.password ? (
                                                                    <p className="text-danger text-small text-left">
                                                                    {formik.errors.password}
                                                                    </p>
                                                                    ) : errorMsg ? (
                                                                    <p className="text-danger text-small text-left">
                                                                    {errorMsg}
                                                                    </p>
                                                                    ) : null}
                                                                    </div>
                                                                    {/* {formik.touched.password &&
                                                                    formik.errors.password ? (
                                                                    <p className="text-danger text-small text-left">
                                                                    {formik.errors.password}
                                                                    </p>
                                                                    ) : null}
                                                                     */}
                                                                    

                                                                </div>
                                                                

                                                                <div className="row fl-remove">
                                                                    <div className=" s12">
                                                                        <button
                                                                            id="login-continue"
                                                                            type="submit"
                                                                            className="submitBtn waves-effect waves-light btn get-password-btn"
                                                                           
                                                                        >
                                                                            Login
                                                                        </button>
                                                                    </div>
                                                                    <p
                                                                        className="signupAlign forgot-btn"
                                                                       
                                                                    >
                                                                        {" "}
                                                                        <NavLink
                                                                            className="forpass login-create-account-link modal-trigger forgotPwdM"
                                                                            to="/forgot-password"
                                                                            style={{ color: "#1794F4", fontWeight: 500 }}
                                                                        >
                                                                            Forgot Password
                                                                        </NavLink>
                                                                    </p>
                                                                </div>
                                                              
                                                               <Facilities/>
                                                            </fieldset>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </main>
            </>


            {/* end MAIN */}

            {/* block -floor -products / floor 1*/}
            {/* block -floor -products / floor 1*/}
        </>


    );
}

export default LoginVerify;
