import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import config from "../../config";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function GiftCategory({ giftCategory }) {
  return (
    <>

  <div className="block-floor-products block-floor-products-opt3 floor-products1 home-product-main-div" >
    <div className="">
       <div className="block-content block-content-product">
      <div className="row pb-10">
        <div className="col-sm-6 col-xs-6 viewallleft">
          <h3 className="title home-page-product-row-titile">Gift Category</h3>
        </div>
      
      </div>

      {giftCategory?.map((item, i) => {
        return (
          <div className="col-sm-3 col-xs-4 home-new-thumb" key={i}>
            <NavLink to={`/products/${item.id}`}>
              {/* <img src={item.image} alt="Flowers" /> */}
              <LazyLoadImage
               alt="Flowers"
                effect="blur"
                wrapperProps={{
                    // If you need to, you can tweak the effect transition using the wrapper style.
                    style: {transitionDelay: "1s"},
                }}
                src={item.image}
              />
              <div className="text-center">{item.name}</div>
            </NavLink>
          </div>
        );
      })}

</div>
</div>
</div>
    </>
  );
}

export default GiftCategory;
