import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

import InnerBackHeader from './layouts/InnerBackHeader';
import BottomNav from './layouts/BottomNav';
import Banner from './components/Banner';
import HomeProduct from './components/HomeProduct';
import HomeSectionBlock from './components/HomeSectionBlock';
import HomeSectionBlockone from './components/HomeSectionBlockone';
import Product from './components/Product';
import { NavLink, Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { CartApi } from "../apis/CartApi";
import * as Yup from "yup";
import { setCartId,getCartId,setUserId,getUserId,getOrderId,setOrderId } from "../Token";

function Checkout() {

 
    const [orderId, setOrderID] = useState({"order_id":getOrderId()});
    const [resiverInfo, setResiverInfo] = useState();
    const [activeData, setactiveData] = useState({});
    const [countryCode, setCountryCode] = useState([]);
    const [inputtype, setinputtype] = useState('email');
    
    let navigate = useNavigate(); 
    useEffect(() => {
      
      getCheckoutInfo(orderId)
      }, []);



      const getCheckoutInfo = (orderId) => {
       
        // setLoader(true);
    
        CartApi.getReciverDetailsByid(orderId)
          .then((res) => {
            if (res.data.status) {
              formik.setFieldValue("recipient_first_name",res.data.data.recipient_first_name ? res.data.data.recipient_first_name : "",
              );
              formik.setFieldValue("recipient_last_name",res.data.data.recipient_last_name ? res.data.data.recipient_last_name : "",
              );
              formik.setFieldValue("recipient_contact_no",res.data.data.recipient_contact_no ? res.data.data.recipient_contact_no : "",
              );
              formik.setFieldValue("recipient_alt_contact_no",res.data.data.recipient_alt_contact_no ? res.data.data.recipient_alt_contact_no : "",
              );
              formik.setFieldValue("delivery_address",res.data.data.delivery_address ? res.data.data.delivery_address : "",
              );
              formik.setFieldValue("special_instruction",res.data.data.special_instruction ? res.data.data.special_instruction : "",
              );
              setResiverInfo(res.data.data)
              
            } else {
              formik.resetForm({
                values: {
                recipient_first_name: "",
                recipient_last_name: "",
                recipient_contact_no : "",
                recipient_alt_contact_no: "",
                delivery_address: "",
                special_instruction: ""
    
                }})
              
            }
            // setLoader(false);
          })
          .catch((err) => {
            // setLoader(false);
            console.log(err);
          });
      };
   

    const formik = useFormik({
        initialValues: {
          recipient_first_name: "",
          recipient_last_name: "",
          recipient_contact_no : "",
          recipient_alt_contact_no: "",
          delivery_address: "",
          special_instruction: ""
    
        },
        validationSchema: Yup.object().shape(
          {
            recipient_first_name: Yup.string().required("Please enter recipient name"),
            recipient_contact_no: Yup.string().required("Please enter recipient contact no"),
            delivery_address: Yup.string().required("Please enter delivery address")
          },
          []
        ),
        onSubmit: (values) => {
          const data = new FormData();
          data.append("recipient_first_name", values.recipient_first_name);
          data.append("recipient_last_name", values.recipient_last_name);
          data.append("recipient_contact_no", values.recipient_contact_no);
          data.append("recipient_alt_contact_no", values.recipient_alt_contact_no);
          data.append("delivery_address", values.delivery_address);
          data.append("special_instruction", values.special_instruction);
          data.append("cart_id", getCartId());
          data.append("user_id", getUserId());
          data.append("order_id", getOrderId());
 
          CartApi.checkout(data)
            .then((res) => {
              if (res.data.status) {
                    setOrderId(res.data.data.order_id);
                    let path = `/payment`; 
                    navigate(path);
              } else {
                alert(res.data.message);
                //setErrorMsg(res.data.message);
              }
            })
            .catch((error) => {
              console.log("error", error);
            });
        },
      });



  return (
    <>
    <InnerBackHeader/>
  <div className="block-floor-products block-floor-products-opt3 floor-products1">
   
  <>
  <div id="wrapper">
  {/* MAIN */}
  <main className="site-main home-product-main-div viewcartpadding">
    <div className="columns ">
      {/* container */}
      {/* Block  Breadcrumb*/}
      <h2 className="page-heading">
        <span className="page-heading-title2">Shipping Information</span>
      </h2>
      <form
      className="login-login-form"
      onSubmit={formik.handleSubmit}
      >
        <div className="page-content checkout-page">
          <div className="box-border">
            <ul>
              <li className="row">
                {/* <div className="input-field ">
                  <label htmlFor="recipient_first_name" className="required">
                    First Name
                  </label>
                  <input
                    className="input form-control"
                    name="recipient_first_name"
                    defaultValue=""
                    id="recipient_first_name"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.recipient_first_name}
                  />
                  {formik.touched.recipient_first_name &&
                  formik.errors.recipient_first_name ? (
                  <p className="text-danger text-small text-left">
                  {formik.errors.recipient_first_name}
                  </p>
                  ) : null}
                </div> */}
                <div className="input-field">
                <label
                  htmlFor="recipient_first_name"
                  className={formik.values.recipient_first_name ? "required active" : "required"}
                >
                  First Name
                </label>
                <input
                  className="input form-control "
                  // style={{
                  //   textAlign: 'right',
                  //   marginRight: '10px',
                  //   display: 'inline-block',
                  // }}
                  name="recipient_first_name"
                  defaultValue=""
                  id="recipient_first_name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.recipient_first_name}
                />
                {formik.touched.recipient_first_name && formik.errors.recipient_first_name ? (
                  <p className="text-danger text-small text-left">
                    {formik.errors.recipient_first_name}
                  </p>
                ) : null}
              </div>
                <div className="input-field">
                  <label htmlFor="recipient_last_name"  className={formik.values.recipient_last_name ? "required active" : "required"}>
                    Last Name
                  </label>
                  <input
                    className="input form-control"
                    name="recipient_last_name"
                    defaultValue=""
                    id="recipient_last_name"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.recipient_last_name}
                  />
                </div>
              </li>
              <li className="row">
                <div className="input-field">
                  <label htmlFor="recipient_contact_no"  className={formik.values.recipient_contact_no ? "required active" : "required"}>Contact No.</label>
                  <input
                    className="input form-control"
                    name="recipient_contact_no"
                    defaultValue=""
                    id="recipient_contact_no"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.recipient_contact_no}
                  />
                  {formik.touched.recipient_contact_no &&
                  formik.errors.recipient_contact_no ? (
                  <p className="text-danger text-small text-left">
                  {formik.errors.recipient_contact_no}
                  </p>
                  ) : null}
                </div>
                <div className="input-field">
                  <label
                    htmlFor="recipient_alt_contact_no"
                    className={formik.values.recipient_alt_contact_no ? "required active" : "required"}
                  >
                    Alternate Contact No.
                  </label>
                  <input
                    className="input form-control"
                    name="recipient_alt_contact_no"
                    id="recipient_alt_contact_no"
                    defaultValue=""
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.recipient_alt_contact_no}
                  />
                  {formik.touched.recipient_alt_contact_no &&
                  formik.errors.recipient_alt_contact_no ? (
                  <p className="text-danger text-small text-left">
                  {formik.errors.recipient_alt_contact_no}
                  </p>
                  ) : null}
                </div>
              </li>
              <li className="row">
                <div className="input-field">
                  <label htmlFor="delivery_address"  className={formik.values.delivery_address ? "required active" : "required"}>
                    Address
                  </label>
                  <textarea
                    className="materialize-textarea"
                    name="delivery_address"
                    id="delivery_address"
                    defaultValue={""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.delivery_address}
                  />
                  {formik.touched.delivery_address &&
                  formik.errors.delivery_address ? (
                  <p className="text-danger text-small text-left">
                  {formik.errors.delivery_address}
                  </p>
                  ) : null}
                </div>
              </li>
              <li className="row">
                <div className="input-field">
                  <label htmlFor="special_instruction" className={formik.values.special_instruction ? "required active" : "required"}>
                    Special Instruction
                  </label>
                  <textarea
                    className="materialize-textarea"
                    name="special_instruction"
                    id="special_instruction"
                    defaultValue={""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.special_instruction}
                   />
                   
                </div>
              </li>
            </ul>
          </div>
          <div className="button-position">
            <div className="row">
              <div className="col-sm-6 col-xs-12 no-padding">
                <button type="submit" className="btn-process-continue" id="place_order">
                  Proceed to Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </main>
  {/* end MAIN */}
</div>

</>


  </div>
  {/* block -floor -products / floor 1*/}
  {/* block -floor -products / floor 1*/}
</>


  );
}

export default Checkout;
