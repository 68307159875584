import config from "../config";
import axios from "axios"
import * as url from "../helpers/url_helper";
//import { setAuthorizationToken } from "helpers/authorization";
//setAuthorizationToken();
export const SearchApi = {
    fetchSearchPopularData: async () => {
        var axiosConfig = {
            method: 'GET',
            url: `${config.API_URL}/${url.GET_SEARCH_POPULAR}`,
            headers: {
                'Content-Type': 'application/json'
            },
           // data: data
        };

        return await axios(axiosConfig);
    },

    SearchData: async(data) => {
      
      var axiosConfig ={
        method: "POST",
        url:` ${config.API_URL}/${ url.SEARCH_LIST}`,
        headers:{
          'Contain-Type' : 'application/json'
        },
        data:data
      }
      return await axios(axiosConfig);

    },

    SearchProduct: async(data) => {
      var axiosConfig ={
        method: "POST",
        url:` ${config.API_URL}/${ url.SEARCH_PRODUCT}`,
        headers:{
          'Contain-Type' : 'application/json'
        },
        data:data
      }
      return await axios(axiosConfig);
    },
    


    


}



