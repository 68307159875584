import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import InnerBackHeader from "./layouts/InnerBackHeader";
import BottomNav from "./layouts/BottomNav";
import Banner from "./components/Banner";
import HomeProduct from "./components/HomeProduct";
import HomeSectionBlock from "./components/HomeSectionBlock";
import HomeSectionBlockone from "./components/HomeSectionBlockone";
import Product from "./components/Product";
import { NavLink, Link, useParams } from "react-router-dom";
import { HomeApi } from "../apis/HomeApi";
import { CartApi } from "../apis/CartApi";
import * as Yup from "yup";
import { setCartId, getCartId, setUserId, getUserId } from "../Token";
import config from "../config";
import { useDispatch } from "react-redux";
import { fetchCartCount } from "../actions/cartActions";
import Loader from "./layouts/Loader";

function CustomerReviews() {
  // const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  //
  // console.log('state',state);

  const [ratings, setRatings] = useState([]);
  const [totalRatings, setTotalRating] = useState();

  let navigate = useNavigate();
  useEffect(() => {
    getCustomerFeedback();
  }, []);

  const getCustomerFeedback = () => {
    setLoader(true);
    HomeApi.getCustomerFeedback()
      .then((res) => {
        // console.log(res.data.data.length);
        if (res.data.status) {
          setTotalRating(res.data.data.length);
          setRatings(res.data.data);
        } else {
          setRatings([]);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const clickHelpful =(data) =>{
    console.log(data)
  }

  return (


    <>
   {loader && <Loader />}
      <InnerBackHeader isLogo={true} />
      <div className="block-floor-products block-floor-products-opt3 floor-products1">
        <main className="site-main container-fluid">
          <div className="col-main">
            <h2 className="page-heading">
              <span className="page-heading-title2">
                Customer Recent Reviews
              </span>
            </h2>

            <div>
              <span className="text-success text-bold font-30">4.9/5</span>

              <span className="page-heading-title2 ml-10 text-bold">
                {" "}
                All time ({totalRatings} reviews)
              </span>
            </div>


            {ratings.map((rating, i) => {
              return (
                <>
                  <div className="col s12">
                    <div
                      style={{
                        borderTop: "1px solid rgb(230,230,230)",
                        padding: "15px 0",
                      }}
                    >
                      <div className="mt-10">
                        <span className="comment-2">
                          <span className="comment-2">{rating.star}</span>
                          <strong className="left-rating-star">★</strong>
                        </span>
                      </div>
                      <div
                        id="imgPreview"
                        className="modal"
                        style={{
                          maxHeight: "90%",
                          maxWidth: "90%",
                          borderRadius: "3%",
                          textAlign: "center",
                        }}
                        tabIndex={0}
                      >
                        <div
                          className="row modal-content"
                          style={{ padding: 0, margin: 0 }}
                        >
                          <div
                            className="modal-close"
                            style={{
                              paddingTop: 4,
                              paddingRight: 8,
                              float: "right",
                            }}
                          >
                           
                          </div>
                          <div
                            className="col s12"
                            style={{ marginBottom: "5%" }}
                          >
                            <img
                              src=""
                              id="modal-img"
                              className="img-fluid"
                              style={{
                                maxWidth: "100%",
                                maxHeight: 400,
                                borderRadius: "3%",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div style={{ fontSize: 13, paddingTop: 5 }}>
                        {rating.feedback}
                      </div>
                      <div
                        style={{ paddingTop: 10, fontSize: 12, color: "#888" }}
                      >
                        <div
                          className="truncate"
                          style={{
                            display: "inline-block",
                            width: 120,
                            overflow: "inherit",
                          }}
                        >
                          {rating.customer_name}
                        </div>
                        <div
                          style={{ paddingLeft: 132, display: "inline-block" }}
                        >
                          {rating.review_date}
                        </div>
                      </div>
{/*      
     <button   className="btn btn-default btn-sm" style={{
      borderRadius: 9,
      textTransform: "capitalize",
      backgroundColor: "#eee",
      color: "black",
      fontSize: 11,
      width: 71,
      // lineHeight: 24,
      height: 23,
      marginTop: 6,
      padding: 0,
      lineHeight: 0,
     }}
     onClick={(e)=> clickHelpful(e.target.value )}
     key={i}
     id={i}
     
     > 
      Helpful
     </button> */}
     
      {/* <div
        className="btn btn-default btn-sm"
        style={{
          borderRadius: 9,
          textTransform: "capitalize",
          backgroundColor: "#eee",
          color: "black",
          fontSize: 11,
          width: 71,
          lineHeight: 24,
          height: 23,
          marginTop: 6
        }}
      >
      
        Helpful
      </div> */}
    
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </main>
        {/* end MAIN */}
      </div>
      {/* block -floor -products / floor 1*/}
      {/* block -floor -products / floor 1*/}
    </>
  );
}

export default CustomerReviews;
