import React, { useEffect, useState } from "react";
import config from "../../config";
import { HomeApi } from "../../apis/HomeApi";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { setCartId,getCartId,setUserId,getUserId } from "../../Token";
// import BottomNav from '../layouts/BottomNav';
import ViewCartCount from '../components/ViewCartCount';


function BottomNav() {

  const [categories, setCategories] = useState([]);
  const [opencategory, setOpenCategory] = useState([]);
  const [isMenuOpen, setMenuOpen] = useState(false);
 
  const [headerFixed, setHeaderFixed] = useState("");


  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const placeholderText = "Search cakes, flowers, gifts, etc.";


 
  useEffect(() => {
    getCategories();
    onScrollLoad();


    const intervalId = setInterval(() => {
      setPlaceholderIndex((prevIndex) => {
        const nextIndex = prevIndex < placeholderText.length * 2 - 1 ? prevIndex + 1 : 0;

        // Toggle between forward and backward typing
        return nextIndex;
      });
    }, 100); // Adjust the interval based on your preference

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount

  }, []);

 const typedPlaceholder = placeholderText
    .substring(0, Math.min(placeholderIndex, placeholderText.length));


  const onScrollLoad = (e) => {
    window.addEventListener('scroll', isSticky);
    return () => {
        window.removeEventListener('scroll', isSticky);
    };
  }

  const isSticky = (e) => {
    //const header = document.querySelector('.header-section');
    const scrollTop = window.scrollY;
    scrollTop >= 100 ? setHeaderFixed('header-fixed') : setHeaderFixed('');
};

  const getCategories = () => {
    HomeApi.getCategories()
      .then((res) => {
        if (res.data.status) {
          //console.log(res.data.data);
          setCategories(res.data.data);
        } else {
          setCategories([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function menuOpen(category_id) {

      if(category_id == opencategory) {
        setOpenCategory(null);
      } else {
        setOpenCategory(category_id);
      }
    

      
      //alert(category_id);
  };



  function menuOpenClose(val) {
      setMenuOpen(val);
  };

 

  

  return (
    <>
      {/* HEADER */}
      <header className="site-header header-opt-3 cate-show">
        {/* header-content */}
       
        {/* header-content */}
        <div className=" header-nav mid-header header-banner-bottom">
          <div className="container">
            <div className="box-header-nav">
              {/* menu */}
              <div className={`block-nav-menu ${isMenuOpen ? "has-open" : ""}`} >
                <div className="clearfix">
                  <span data-action="close-nav" onClick={() => menuOpenClose(false)} className="close-nav">
                    <span>close</span>
                  </span>
                </div>
                <ul className="ui-menu">
                  {/* <li>
               <a href="https://www.blissmygift.com/occasions/teachers-day" style="color: red; font-weight: bold;"> TEACHER'S DAY</a>
               </li> */}

                  {categories.map((category, i) => {
                    return (

                      <li className= {`parent parent-megamenu ${category.id == opencategory ? "open-submenu" : ""}`} key={category.id}>
                        <a
                          href="javascript:void(0);"
                          className="category-menu-title cat_1"
                          onClick={() => menuOpen(category.id)}
                        >
                          {category.name}
                          <span className="nav-down-arrow" >
                            <i className="fa fa-arrow-down" aria-hidden="true" />
                          </span>
                          <span className="toggle-submenu" />
                        </a>
                        <div className="megamenu drop-menu">
                          <ul>

                            {category.category_inner_title.map((subtTitle, i) => {
                              return (
                                <li className="col-md-3" key={subtTitle.id}>
                                  <div className="title">
                                    <a href="#">
                                      <span>{subtTitle.name}</span>
                                    </a>
                                  </div>
                                  <ul className="list-submenu">

                                    {subtTitle.sub_category.map((subCategory, i) => {
                                      return (

                                        <li key={subCategory.id}>
                                          <NavLink to={`products/${subCategory.id}`}>
                                            {subCategory.name}
                                          </NavLink>
                                        </li>

                                      );
                                    })}

                                  </ul>
                                </li>
                              );
                            })}

                          </ul>
                        </div>
                      </li>

                    );
                  })}

                </ul>
              </div>
              {/* menu */}
            </div>
          </div>
        </div>
      </header>
      

      <div className="navbar mobile-navbar-bottom" id="bottom-navbar">
        <NavLink
          className="mobile-navbar-bottom-link home-navbar-bottom-link"
          id="home-navbar-bottom-link"
          name="home-navbar-bottom-link"
          to="/"
        >
          <div>
            <img
              className="responsive-img home-outline"
              src={config.IMAGE_URL + '/icons/home.png'}
              width={20}
            />
          </div>
          <span className="home-mobile-nav-font">Home</span>
        </NavLink>
        <a
          className="mobile-navbar-bottom-link category-navbar-bottom-link"
          id="shop-navbar-bottom-link"
          name="shop-navbar-bottom-link"
          href="#"
          data-action="toggle-nav"
          onClick={() => menuOpenClose(true)}
        >
          <div>
            <img
              className="lazyload responsive-img shop-outline"
              src={config.IMAGE_URL + '/icons/category.png'}
              width={20}
            />
          </div>
          <span className="home-mobile-nav-font">Categories</span>
        </a>
        
        <NavLink
          className="mobile-navbar-bottom-link city-navbar-bottom-link"
          id="city-navbar-bottom-link"
          name="city-navbar-bottom-link"
          to="/delivery-cities"
        >
          <div>
            <img
              className="lazyload responsive-img  city-outline"
              src={config.IMAGE_URL + '/icons/city.png'}
              width={15}
            />
          </div>
          <span className="home-mobile-nav-font">City</span>
        </NavLink>
        <NavLink
          className="mobile-navbar-bottom-link account-navbar-bottom-link"
          id="account-navbar-bottom-link"
          name="account-navbar-bottom-link"
          to={"/profile"}
          // to={getUserId() != '' && getUserId() != 'undefined' ?  '/profile/myaccount'  : "/login"}
        >
          <div>
            <img
              className="lazyload responsive-img  account-outline"
              src={config.IMAGE_URL + '/icons/profile.png'}
              width={15}
            />
          </div>
          <span className="home-mobile-nav-font">Profile</span>
        </NavLink>
      </div>
      {/* end HEADER */}
    </>

  );
}

export default BottomNav;
