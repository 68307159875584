import React, { useEffect, useState } from "react";
import InnerBackHeader from "../../layouts/InnerBackHeader";
import { useParams } from "react-router-dom";
import { OrderTrackingApi } from "../../../apis/OrderTrackingApi";
// import "./stepper.css"; // Import your CSS file
const OrderTracking = () => {
  const product_id = useParams().product_id;
  const steps = [
    "Order Placed",
    "Order Confirmed",
    "Order Dispatched",
    "Order Delivered",
  ];
  const [currentStep, setCurrentStep] = useState(1);
  const [complete, setComplete] = useState(false);
  const [orderHistory, setOrderHistory] = useState();
  const [orderPlaced, setOrderPlaced] = useState();
  const [orderConfirmed, setOrderConfirmed] = useState();
  const [orderDispatched, setOrderDispatched] = useState();
  const [orderDelivered, setOrderDelivered] = useState();
  useEffect(() => {
    getOrderTrackingHistory();
  }, []);
  const getOrderTrackingHistory = () => {
    const data = {
      order_id: parseInt(product_id),
    };
    OrderTrackingApi.getOrderTrackingHistory(data)
      .then((res) => {
        if (res.data.status) {
          setOrderHistory(res.data.data);
          setOrderPlaced(res.data.data.order_placed);
          setOrderConfirmed(res.data.data.order_confirmed);
          setOrderDispatched(res.data.data.order_dispatched);
          setOrderDelivered(res.data.data.order_delivered);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <InnerBackHeader isLogo={true} />
      <div className="container mt-5">
        {orderHistory && (
          <div className="row justify-content-center">
            <div className="d-flex flex-column align-items-center">
              <div className="container mt-30 no-padding">
                <div className="col-xs-12 text-bold">
                  
                  ORDER ID : {product_id}
                </div>
                <div className="col-xs-12 text-bold mt-15">
                  
                  ORDER STATUS : {orderHistory &&
                    orderHistory.order_current_status.current_order_status}
                </div>
                
                {orderHistory && (
                  <div className="row justify-content-center ">
                    <div
                      className="d-flex flex-column align-items-center "
                      style={{ marginTop: " 86px" }}
                    >
                      {/* step-item  */}
                      {/* <div className={`row ${orderHistory.is_step_pass ? 'active' : 'complete'}`}> */}
                      <div
                        className={`row ${
                          orderHistory.order_placed.is_step_pass ? "complete" : "active"
                        }`}
                      >
                        {/* is_step_pass */}
                        <div className="col-xs-2">
                          <div className="step">
                            <i className="fa fa-user" aria-hidden="true"></i>
                          </div>
                          <div
                            className={`vertical-line ${
                              orderHistory.order_placed.is_step_pass ? "progress-line" : ""
                            }`}
                          ></div>
                        </div>
                        <div className="col-xs-10">
                          <div>
                            {orderHistory.order_placed.name}
                          </div>
                          <div >
                            {orderHistory.order_placed.date}
                          </div>
                        </div>
                      </div>
                      <div
                        className={`row ${
                          orderHistory.order_confirmed.is_step_pass ? "complete" : "active"
                        }`}
                      >
                        <div className="col-xs-2">
                          <div className="step">
                            <i class="fa fa-check" aria-hidden="true"></i>
                          </div>
                          <div
                            className={`vertical-line ${
                              orderHistory.order_confirmed.is_step_pass ? "progress-line" : ""
                            }`}
                          ></div>
                        </div>
                        <div className="col-xs-10">
                          <div>
                            {orderHistory.order_confirmed.name}
                          </div>
                          <div >
                            {orderHistory.order_confirmed.date}
                          </div>
                        </div>
                       
                      </div>
                      <div
                        className={`row ${
                          orderHistory.order_dispatched.is_step_pass ? "complete" : "active"
                        }`}
                      >
                        <div className="col-xs-2">
                          <div className="step">
                            <i class="fa fa-truck" aria-hidden="true"></i>
                          </div>
                          <div
                            className={`vertical-line ${
                              orderHistory.order_dispatched.is_step_pass ? "progress-line" : ""
                            }`}
                          ></div>
                        </div>
                        <div className="col-xs-10">
                          <div>
                            {orderHistory.order_dispatched.name}
                          </div>
                          <div >
                            {orderHistory.order_dispatched.date}
                          </div>
                        </div>
                        
                      </div>
                      <div
                        className={`row ${
                          orderHistory.order_delivered.is_step_pass ? "complete" : "active"
                        }`}
                      >
                        <div className="col-xs-2">
                          <div className="step">
                            <i class="fa fa-gift" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div className="col-xs-10">
                          <div>
                            {orderHistory.order_delivered.name}
                          </div>
                          <div >
                            {orderHistory.order_delivered.date}
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
     

    </>
  );
};
export default OrderTracking;
