import config from "../config";
import axios from "axios"
import * as url from "../helpers/url_helper";
//import { setAuthorizationToken } from "helpers/authorization";
//setAuthorizationToken();
export const OrderTrackingApi = {
    getOrderTrackingHistory: async (data) => {
        var axiosConfig = {
            method: 'POST',
            url: `${config.API_URL}/${url.GET_ORDER_TRACKING_HISTORY}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    
}