
  export const setCartId = (cart_id) => {
    localStorage.setItem("cart_id", cart_id);
  };

  export const setOrderId = (order_id) => {
    localStorage.setItem("order_id", order_id);
  };

  export const setUserId = (user_id) => {
    localStorage.setItem("user_id", user_id);
  };

  export const setUserInfo = (user) => {
    localStorage.setItem("user_info", user);
  };

  export const getUserInfo = () => {
    let user_info = localStorage.getItem("user_info");
    return user_info ? user_info : '';
  };

  export const setTempUserData = (user_temp_info) => {
    localStorage.setItem("user_temp_info", user_temp_info);
  };

  export const getTempUserData = () => {
    let user_temp_info = localStorage.getItem("user_temp_info");
    return user_temp_info ? user_temp_info : '';
  };


  export const getCartId = () => {
    let cart_id = localStorage.getItem("cart_id");
    return cart_id ? cart_id : '';
  };

  export const getOrderId = () => {
    let order_id = localStorage.getItem("order_id");
    return order_id ? order_id : '';
  };

  export const getUserId = () => {
    let user_id = localStorage.getItem("user_id");
    return user_id ? user_id : '';
  };

  