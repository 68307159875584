import React from "react";
import InnerBackHeader from "../pages/layouts/InnerBackHeader";
import Footer from "../pages/layouts/Footer";


function TermsAndConditions(){


    return(

        <>
         <InnerBackHeader isLogo={true} />
         <div className="wrapper">
 
  <div id="wrapper">
    {/* MAIN */}
    <main className="site-main  home-product-main-div viewcartpadding">
      {/* Block  Breadcrumb*/}
      <div className="row">
        {/* Main Content */}
        <h2 className="page-heading">
          <span className="page-heading-title2">Terms &amp; Condition</span>
        </h2>
        <p className="text-justify">
          <span >
            We thank you from the bottom of our heart for placing an order with
            us and making us a part of your celebrations. We strive hard to make
            your celebrations memorable and wonderful.
          </span>
        </p>
        <p  className="text-justify">
          <span >
            We at Blissmygift are a group of gifting and celebration
            enthusiasts. We love our work and try to serve people in their
            celebrations and have fun doing so. &nbsp;We try our level best that
            whatever we display on our website is delivered with the same
            quality as promised. In serving you we also care to follow certain
            guidelines, rules and processes. These terms and conditions are not
            there to intimidate the user but are there so as to minimise the
            areas of conflict. We have listed these so that the user is aware of
            the processes and rules that can affect him/her, his/her order and
            the recipient.
          </span>
        </p>
        <p  className="text-justify">
          <span >
            Please read the following terms and conditions very carefully as
            your use of service is subject to your acceptance of and compliance
            with the following terms and conditions ("Terms").
          </span>
        </p>
        <p  className="text-justify">
          <span >
            By subscribing to or using any of our services you agree that you
            have read, understood and are bound by the Terms, regardless of how
            you subscribe to or use the services. If you do not want to be bound
            by the Terms, you must not subscribe to or use our services.
          </span>
        </p>
        <p  className="text-justify">
          <span >
            Your use of the Website, services and tools are governed by the
            following terms and conditions ("Terms of Use") as applicable to the
            Website, including the applicable policies which are incorporated
            herein by way of reference. If You transact on the Website, You
            shall be subject to the policies that are applicable to the Website
            for such transaction. By mere use of the Website, You shall be
            contracting with Blissmygift Private Limited and these terms and
            conditions including the policies constitute Your binding
            obligations, with Blissmygift.
          </span>
        </p>
        <p  className="text-justify">
          <span >
            By impliedly or expressly accepting these Terms of Use, You also
            accept and agree to be bound by the Blissmygift Privacy Policy.
          </span>
        </p>
        <p   className="text-justify">
          <span >
            For the purpose of these Terms of Use, wherever the context so
            requires "You" or "User" shall mean any natural or legal person who
            has agreed to become a member of the Website by providing
            Registration Data while registering on the Website as Registered
            User using the computer systems. The terms "we", "us", "our" shall
            mean Blissmygift Private Limited.
          </span>
        </p>
        <p  className="text-justify">
          <span >
            When You use any of the services provided by us through the Website,
            including but not limited to, (e.g. Customer Reviews), You will be
            subjected to the rules, guidelines, policies, terms, and conditions
            applicable to such service, and they shall be deemed to be
            incorporated into this Terms of Use and shall be considered as part
            and parcel of this Terms of Use. We reserve the right, at our sole
            discretion, to change, modify, add or remove portions of these Terms
            of Use, at any time without any prior written notice to you. It is
            your responsibility to review these Terms of Use periodically for
            updates / changes. Your continued use of the Website following the
            posting of changes will mean that you accept and agree to the
            revisions. As long as You comply with these Terms of Use, We grant
            You a personal, non-exclusive, non-transferable, limited privilege
            to enter and use the Website.
          </span>
        </p>
        <p className="text-justify" >
          <span >
            ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR
            AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE,
            SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING.
          </span>
        </p>
        <h2>
          <strong>Introduction:</strong>
        </h2>
        <ol  className="text-justify">
          <li>
            <span  className="text-justify"  >
              a) www.blissmygift.com website ("Website") is an Internet based
              content and e-commerce portal owned and operated by Blissmygift
              Private Limited, a company incorporated under the laws of India.
            </span>
          </li>
          <li>
            <span  className="text-justify" >
              b) Use of the Website is offered to you conditioned on acceptance
              without modification of all the terms, conditions and notices
              contained in these Terms, as may be posted on the Website from
              time to time. blissmygift.com at its sole discretion reserves the
              right not to accept a User from registering on the Website without
              assigning any reason thereof.
            </span>
          </li>
        </ol>
        <h3>
          <strong>Headings:</strong>
        </h3>
        <p  className="text-justify">
          <span >
            The headings and subheadings herein are included for convenience and
            identification only and are not intended to describe, interpret,
            define or limit the scope, extent or intent of the Terms or the
            right to use the Website by you contained herein or any other
            section or pages of the Website or any Linked Sites in any manner
            whatsoever.
          </span>
        </p>
        <h2>
          <strong>User Account, Password, and Security:</strong>
        </h2>
        <p  className="text-justify">
          <span >
            You will receive a password and account designation upon completing
            the Website's registration process. You are responsible for
            maintaining the confidentiality of the password and account, and are
            fully responsible for all activities that occur under your password
            or account. You agree to (a) immediately notify blissmygift.com of
            any unauthorized use of your password or account or any other breach
            of security, and (b) ensure that you exit from your account at the
            end of each session. blissmygift.com cannot and will not be liable
            for any loss or damage arising from your failure to comply with this
            Section (b).
          </span>
        </p>
        <p  className="text-justify">
          <span >
            You agree that if any information provided by You that is
            inconsistent, inaccurate, not current or incomplete, or We have
            reasonable grounds to suspect that such information is inconsistent,
            inaccurate, not current or incomplete, or not in accordance with
            these Terms of Use, We reserve the right to indefinitely suspend or
            terminate or block access of your membership on the Website and
            refuse You access to the Website.
          </span>
        </p>
        <h2>
          <strong>Communications:</strong>
        </h2>
        <p  className="text-justify">
          <span >
            When You use the Website or send emails or other data, information
            or communication to Company, You agree and understand that You are
            communicating with Company through electronic records and You
            consent to receive communications via electronic records from the
            Company periodically and as and when required. Company may
            communicate with you by email or by such other mode of
            communication, electronic or otherwise.
          </span>
        </p>
        <h2>
          <strong>Services Offered:</strong>
        </h2>
        <p  className="text-justify">
          <span >
            blissmygift.com provides a number of Internet-based services through
            the Web Site (all such services, collectively, the "Service"). One
            such service enables users to purchase original merchandise as gifts
            such as watches, perfumes, sunglasses, belts, and other gift items
            from various brands. (collectively, "Products"). Upon placing order,
            blissmygift.com shall ship the product to you or the person you are
            gifting and you be entitled to its payment for the Services.
          </span>
        </p>
        <h2>
          <strong>Use of the Website:</strong>
        </h2>
        <p  className="text-justify">
          <span >
            You agree, undertake and confirm that your use of Website shall be
            strictly governed by the following binding principles:
          </span>
        </p>
        <h3>
          <strong>Charges</strong>
        </h3>
        <p  className="text-justify">
          <span >
            Membership on the Website is free for buyers. Blissmygift does not
            charge any fee for browsing and buying on the Website. Blissmygift
            reserves the right to change its Fee Policy from time to time. In
            particular, Blissmygift may at its sole discretion introduce new
            services and modify some or all of the existing services offered on
            the Website. In such an event Blissmygift reserves the right to
            introduce fees for the new services offered or amend/introduce fees
            for existing services, as the case may be. Changes to the Fee Policy
            shall be posted on the Website and such changes shall automatically
            become effective immediately after they are posted on the Website.
            Unless otherwise stated, all fees shall be quoted in Indian Rupees.
            You shall be solely responsible for compliance of all applicable
            laws including those in India for making payments to Blissmygift
            private limited.
          </span>
        </p>
        <h3>
          <strong>Privacy Policy:</strong>
        </h3>
        <p  className="text-justify">
          <span >
            The User hereby consents, expresses and agrees that he has read and
            fully understands the Privacy Policy of blissmygift.com in respect
            of the Website. The user further consents that the terms and
            contents of such Privacy Policy are acceptable to him.
          </span>
        </p>
        <h3>
          <strong>Limited User:</strong>
        </h3>
        <p  className="text-justify">
          <span >
            The User agrees and undertakes not to reverse engineer, modify,
            copy, distribute, transmit, display, perform, reproduce, publish,
            license, create derivative works from, transfer, or sell any
            information or software obtained from the Website. Limited
            reproduction and copying of the content of the Website is permitted
            provided that Blissmygift's name is stated as the source and prior
            written permission of blissmygift.com is sought. For the removal of
            doubt, it is clarified that unlimited or wholesale reproduction,
            copying of the content for commercial or non-commercial purposes and
            unwarranted modification of data and information within the content
            of the Website is not permitted.
          </span>
        </p>
        <h3>
          <strong>User Conduct and Rules:</strong>
        </h3>
        <p  className="text-justify">
          <span >
            You agree and undertake to use the Website and the Service only to
            post and upload messages and material that are proper. By way of
            example, and not as a limitation, you agree and undertake that when
            using a Service, you will not:
          </span>
        </p>
        <ol  className="text-justify">
          <li   className="text-justify" >
            <span >
              defame, abuse, harass, stalk, threaten or otherwise violate the
              legal rights of others
            </span>
          </li>
          <li >
            <span   className="text-justify" >
              publish, post, upload, distribute or disseminate any
              inappropriate, profane, defamatory, infringing, obscene, indecent
              or unlawful topic, name, material or information
            </span>
          </li>
          <li >
            <span   className="text-justify" >
              upload files that contain software or other material protected by
              intellectual property laws unless you own or control the rights
              thereto or have received all necessary consents
            </span>
          </li>
          <li >
            <span  className="text-justify" >
              upload or distribute files that contain viruses, corrupted files,
              or any other similar software or programs that may damage the
              operation of the Website or another's computer
            </span>
          </li>
          <li  >
            <span  className="text-justify" >
              conduct or forward surveys, contests, pyramid schemes or chain
              letters
            </span>
          </li>
          <li >
            <span  className="text-justify" >
              download any file posted by another user of a Service that you
              know, or reasonably should know, cannot be legally distributed in
              such manner
            </span>
          </li>
          <li >
            <span   className="text-justify" >
              falsify or delete any author attributions, legal or other proper
              notices or proprietary designations or labels of the origin or
              source of software or other material contained in a file that is
              uploaded
            </span>
          </li>
          <li >
            <span   className="text-justify" >
              violate any code of conduct or other guidelines, which may be
              applicable for or to any particular Service
            </span>
          </li>
          <li >
            <span  className="text-justify"  >
              violate any applicable laws or regulations for the time being in
              force in or outside India; and
            </span>
          </li>
          <li >
            <span  className="text-justify" >
              violate any of the terms and conditions of this Agreement or any
              other terms and conditions for the use of the Website contained
              elsewhere herein
            </span>
          </li>
        </ol>
        <h3>
          <strong>Contents Posted on Site:</strong>
        </h3>
        <p>
          <span  className="text-justify" >
            Except as expressly provided in these Terms of Use, no part of the
            Website and no Content may be copied, reproduced, republished,
            uploaded, posted, publicly displayed, encoded, translated,
            transmitted or distributed in any way (including "mirroring") to any
            other computer, server, Website or other medium for publication or
            distribution or for any commercial enterprise, without Blissmygift's
            express prior written consent.
          </span>
        </p>
        <p  className="text-justify">
          <span >
            You may use information on the products and services purposely made
            available on the Website for downloading, provided that You
          </span>
        </p>
        <p  className="text-justify">
          <span >
            (1) do not remove any proprietary notice language in all copies of
            such documents,
          </span>
        </p>
        <p  className="text-justify">
          <span >
            (2) use such information only for your personal, non-commercial
            informational purpose and do not copy or post such information on
            any networked computer or broadcast it in any media,
          </span>
        </p>
        <p  className="text-justify">
          <span >
            (3) make no modifications to any such information, and
          </span>
        </p>
        <p  className="text-justify">
          <span >
            (4) do not make any additional representations or warranties
            relating to such documents.
          </span>
        </p>
        <p  className="text-justify">
          <span >
            You shall be responsible for any notes, messages, e-mails, billboard
            postings, photos, drawings, profiles, opinions, ideas, images,
            videos, audio files or other materials or information posted or
            transmitted to the Website (collectively, " Content"). Such Content
            will become our property and you grant us the worldwide, perpetual
            and transferable rights in such Content. We shall be entitled to,
            consistent with our Privacy Policy as adopted in accordance with
            applicable law, use the Content or any of its elements for any type
            of use forever, including but not limited to promotional and
            advertising purposes and in any media whether now known or hereafter
            devised, including the creation of derivative works that may include
            the Content You provide. You agree that any Content You post may be
            used by us, consistent with our Privacy Policy and Rules of Conduct
            on Site as mentioned herein, and you are not entitled to any payment
            or other compensation for such use.
          </span>
        </p>
        <h3>
          <strong>User Warranty and Representation:</strong>
        </h3>
        <p  className="text-justify">
          <span >
            The user guarantees, warrants, and certifies that you are the owner
            of the content which you submit or otherwise authorized to use the
            content and that the content does not infringe upon the property
            rights, intellectual property rights or other rights of others. You
            further warrant that to your knowledge, no action, suit, proceeding,
            or investigation has been instituted or threatened relating to any
            content, including trademark, trade name service mark, and copyright
            formerly or currently used by you in connection with the Services
            rendered by blissmygift.com.
          </span>
        </p>
        <h3>
          <strong>Intellectual Property Rights:</strong>
        </h3>
        <ol  className="text-justify">
          <li >
            <span   className="text-justify" >
              Unless otherwise indicated or anything contained to the contrary
              or any proprietary material owned by a third party and so
              expressly mentioned, blissmygift.com owns all Intellectual
              Property Rights to and into the Website, including, without
              limitation, any and all rights, title and interest in and to
              copyright, related rights, patents, utility models, trademarks,
              trade names, service marks, designs, know-how, trade secrets and
              inventions (whether patentable or not), goodwill, source code,
              meta tags, databases, text, content, graphics, icons, and
              hyperlinks. You acknowledge and agree that you shall not use,
              reproduce or distribute any content from the Website belonging to
              blissmygift.com without obtaining authorization from
              blissmygift.com
            </span>
          </li>
          <li >
            <span  className="text-justify"  >
              Notwithstanding the foregoing, it is expressly clarified that you
              will retain ownership and shall solely be responsible for any
              content that you provide or upload when using any Service,
              including any text, data, information, images, photographs, music,
              sound, video or any other material which you may upload, transmit
              or store when making use of our various Service. However, with
              regard to the product customization Service (as against other
              Services like blogs and forums) you expressly agree that by
              uploading and posting content on to the Website for public viewing
              and reproduction/use of your content by third party users, you
              accept the User whereby you grant a non-exclusive license for the
              use of the same
            </span>
          </li>
        </ol>
        <h3>
          <strong>Links To Third Party Sites:</strong>
        </h3>
        <p>
          <span  className="text-justify"  >
            The Website may contain links to other websites ("Linked Sites").The
            Linked Sites are not under the control of blissmygift.com or the
            Website and blissmygift.com is not responsible for the contents of
            any Linked Site, including without limitation any link contained in
            a Linked Site, or any changes or updates to a Linked Site.
            blissmygift.com is not responsible for any form of transmission,
            whatsoever, received by you from any Linked Site. blissmygift.com is
            providing these links to you only as a convenience, and the
            inclusion of any link does not imply endorsement by blissmygift.com
            or the Website of the Linked Sites or any association with its
            operators or owners including the legal heirs or assigns thereof.
            The users are requested to verify the accuracy of all information on
            their own before undertaking any reliance on such information.
          </span>
        </p>
        <h3>
          <strong>Disclaimer Of Warranties/Limitation Of Liability:</strong>
        </h3>
        <p className="text-justify"  >
          <span >
            blissmygift.com has endeavored to ensure that all the information on
            the Website is correct, but blissmygift.com neither warrants nor
            makes any representations regarding the quality, accuracy or
            completeness of any data, information, product or Service. In no
            event shall blissmygift.com be liable for any direct, indirect,
            punitive, incidental, special, consequential damages or any other
            damages resulting from: (a) the use or the inability to use the
            Services; (b) unauthorized access to or alteration of the user's
            transmissions or data; (c) any other matter relating to the
            services; including, without limitation, damages for loss of use,
            data or profits, arising out of or in any way connected with the use
            or performance of the Website or Service. Neither shall
            blissmygift.com be responsible for the delay or inability to use the
            Website or related services, the provision of or failure to provide
            Services, or for any information, software, products, services and
            related graphics obtained through the Website, or otherwise arising
            out of the use of the website, whether based on contract, tort,
            negligence, strict liability or otherwise. Further, blissmygift.com
            shall not be held responsible for non-availability of the Website
            during periodic maintenance operations or any unplanned suspension
            of access to the website that may occur due to technical reasons or
            for any reason beyond Blissmygift's control. The user understands
            and agrees that any material and/or data downloaded or otherwise
            obtained through the Website is done entirely at their own
            discretion and risk and they will be solely responsible for any
            damage to their computer systems or loss of data that results from
            the download of such material and/or data.
          </span>
        </p>
        <p className="text-justify"  >
          <span >
            This Website, all the materials and products (including but not
            limited to software) and services, included on or otherwise made
            available to You through this site are provided on "as is" and "as
            available" basis without any representation or warranties, express
            or implied except otherwise specified in writing. Without prejudice
            to the foregoing paragraph, Blissmygift do not warrant that:
          </span>
        </p>
        <ul className="text-justify"  >
          <li >
            <span >
              This Website will be constantly available, or available at all; or
            </span>
          </li>
          <li >
            <span >
              The information on this Website is complete, true, accurate or
              non-misleading.
            </span>
          </li>
        </ul>
        <p className="text-justify"  >
          <span >
            Blissmygift will not be liable to You in any way or in relation to
            the Contents of, or use of, or otherwise in connection with, the
            Website.
          </span>
        </p>
        <p className="text-justify"  >
          <span >
            Blissmygift do not warrant that this site; information, Content,
            materials, product (including software) or services included on or
            otherwise made available to You through the Website; their servers;
            or electronic communication sent from us are free of viruses or
            other harmful components. Nothing on Website constitutes, or is
            meant to constitute, advice of any kind.
          </span>
        </p>
        <p className="text-justify"  >
          <span >
            You will be required to enter a valid phone number while placing an
            order on the Website. By registering your phone number with us, You
            consent to be contacted by us via phone calls and / or SMS
            notifications. We will not use your personal information to initiate
            any promotional phone calls or SMS.
          </span>
        </p>
        <h3>
          <strong>Indemnification:</strong>
        </h3>
        <p className="text-justify"  >
          <span >
            You agree to indemnify, defend and hold harmless blissmygift.com
            from and against any and all losses, liabilities, claims, damages,
            costs and expenses (including legal fees and disbursements in
            connection therewith and interest chargeable thereon) asserted
            against or incurred by blissmygift.com that arise out of, result
            from, or may be payable by virtue of, any breach or non-performance
            of any representation, warranty, covenant or agreement made or
            obligation to be performed by you pursuant to these Terms.
          </span>
        </p>
        <h3>
          <strong>Termination:</strong>
        </h3>
        <ol className="text-justify"  >
          <li>
            <span >
              a) blissmygift.com may suspend or terminate your use of the
              Website or any Service if it believes, in its sole and absolute
              discretion that you have breached a term of these Terms.{" "}
            </span>
          </li>
          <li>
            <span >
              b) If you or blissmygift.com terminates your use of the Website or
              any Service, blissmygift.com may delete any content or other
              materials relating to your use of the Service and blissmygift.com
              will have no liability to you or any third party for doing so.{" "}
            </span>
          </li>
          <li>
            <span >
              c) You shall be liable to pay for any Service or product that you
              have already ordered till the time of Termination by either party
              whatsoever.
            </span>
          </li>
        </ol>
        <h3>
          <strong>Governing Law:</strong>
        </h3>
        <p className="text-justify"  >
          <span >
            These terms shall be governed by and constructed in accordance with
            the laws of India without reference to conflict of laws principles
            and disputes arising in relation hereto shall be subject to the
            exclusive jurisdiction of the courts at Bangalore.
          </span>
        </p>
        <h3>
          <strong>Interpretation Of Number And Genders:</strong>
        </h3>
        <p className="text-justify"  >
          <span >
            The Terms herein shall apply equally to both the singular and plural
            form of the terms defined. Whenever the context may require, any
            pronoun shall include the corresponding masculine and feminine. The
            words "include", "includes" and "including" shall be deemed to be
            followed by the phrase "without limitation". Unless the context
            otherwise requires, the terms "herein", "hereof", "hereto",
            "hereunder" and words of similar import refer to the Terms as a
            whole.
          </span>
        </p>
        <h3>
          <strong>Severability:</strong>
        </h3>
        <p className="text-justify"  >
          <span >
            If any provision of the Terms is determined to be invalid or
            unenforceable in whole or in part, such invalidity or
            unenforceability shall attach only to such provision or part of such
            provision and the remaining part of such provision and all other
            provisions of these Terms shall continue to be in full force and
            effect.
          </span>
        </p>
        <h3>
          <strong>Report Abuse:</strong>
        </h3>
        <p className="text-justify"  >
          <span >
            As per these Terms, users are solely responsible for every material
            or content uploaded on to the Website. blissmygift.com does not
            review the contents in any way before they appear on the Website.
            blissmygift.com does not verify, endorse or otherwise vouch for the
            contents of any user or any content generally posted or uploaded on
            to the Website. Users can be held legally liable for their contents
            and may be held legally accountable if their contents or material
            include, for example, defamatory comments or material protected by
            copyright, trademark, etc. If you come across any abuse or violation
            of these Terms, please report to info@blissmygift.com
          </span>
        </p>
        <h3>
          <strong>Product Description:</strong>
        </h3>
        <p className="text-justify"  >
          <span >
            Blissmygift we do not warrant that Product description or other
            content of this Website is accurate, complete, reliable, current, or
            error-free and assumes no liability in this regard.
          </span>
        </p>
        <h3>
          <strong>Limitation of Liability:</strong>
        </h3>
        <p className="text-justify"  >
          <span >
            IN NO EVENT SHALL Blissmygift BE LIABLE FOR ANY SPECIAL, INCIDENTAL,
            INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH
            THESE TERMS OF USE, EVEN IF USER HAS BEEN INFORMED IN ADVANCE OF THE
            POSSIBILITY OF SUCH DAMAGES.
          </span>
        </p>
        <h3>
          <strong>Pricing:</strong>
        </h3>
        <p className="text-justify"  >
          <span >
            Prices for products are described on our Website and are
            incorporated into these Terms by reference. All prices are in Indian
            rupees. Prices, products and Services may change at Blissmygift's
            discretion.
          </span>
        </p>
        <h3>
          <strong>Payment:</strong>
        </h3>
        <p className="text-justify"  >
          <span >
            While availing any of the payment method/s available on the Website,
            we will not be responsible or assume any liability, whatsoever in
            respect of any loss or damage arising directly or indirectly to you
            due to:
          </span>
        </p>
        <ol className="text-justify"  >
          <li >
            <span >
              Lack of authorization for any transaction/s, or
            </span>
          </li>
          <li >
            <span >
              Exceeding the preset limit mutually agreed by you and between your
              "Bank/s", or
            </span>
          </li>
          <li >
            <span >
              Any payment issues arising out of the transaction, or
            </span>
          </li>
          <li >
            <span >
              Decline of transaction for any other reason/s.
            </span>
          </li>
        </ol>
        <p className="text-justify"  >
          <span >
            All payments made against the purchases/services on Website by you
            shall be compulsorily in Indian Rupees acceptable in the Republic of
            India. Website will not facilitate transaction with respect to any
            other form of currency with respect to the purchases made on
            Website. Before shipping / delivering your order to you, we may
            request you to provide supporting documents (including but not
            limited to Govt. issued ID and address proof) to establish the
            ownership of the payment instrument used by you for your purchase.
            This is done in the interest of providing a safe online shopping
            environment to our users.
          </span>
        </p>
        <h3>
          <strong>Acceptance of orders:</strong>
        </h3>
        <ol className="text-justify"  >
          <li >
            <span >
              The orders are accepted via website, Android app, IOs app.{" "}
            </span>
          </li>
          <li >
            <span >
              Only in case of special requirements we accept orders on mail and
              Customer care phone.{" "}
            </span>
          </li>
        </ol>
        <h3>
          <strong>Exactness Not Guaranteed:</strong>
        </h3>
        <p>
          <span >
            blissmygift.com hereby disclaims any guarantees of exactness as to
            the finish and appearance of the final Product as delivered to the
            recipient.
          </span>
        </p>
        <p className="text-justify"  >
          <span >
            We DO NOT guarantee delivery of the exact replica of the products
            mentioned on the website.
          </span>
        </p>
        <ol className="text-justify"  >
          <li >
            <span >
              In Case of flower related products - the florists use different
              &nbsp;wrapping paper, baskets, decoration material, so the
              arrangements shown in website look little different from actual
              products delivered. Delivery of exactly same variety or colour of
              flowers is also subject to availability and freshness. We may
              chose to deliver a bouquet or basket of same monetary value due to
              unavoidable circumstances.{" "}
            </span>
          </li>
          <li >
            <span >
              In case of cakes - the bakeries use the pictures as guidelines but
              they have their own style of preparing same flavoured cakes.
              Therefore the cakes though are same flavoured might look
              different. In case the cake you ordered cannot be delivered due to
              unavoidable circumstances, we will deliver similar cake of same
              monetary value.{" "}
            </span>
          </li>
          <li >
            <span >
              In case of any other products, if we can not deliver same products
              due to unavoidable circumstances, we will deliver similar product
              of same monetary value.{" "}
            </span>
          </li>
        </ol>
        <h4>
          <strong>Products and services delivery - Date and Time slots:</strong>
        </h4>
        <p className="text-justify"  >
          <span >
            The time slots provided at the time of checkout can be selected by
            user.These are preferred date and time slots of customer. We strive
            to do deliveries on the selected date and time slot but it is{" "}
          </span>
          <strong>NOT A GUARANTEE</strong>
          <span >
            . We are committed to do delivery of products and services ordered{" "}
          </span>
          <strong>until 24 hours</strong>
          <span >
            {" "}
            of the requested delivery date and time slot. Failing to do so we
            will refund the amount, not before that.{" "}
          </span>
        </p>
        <ol className="text-justify"  >
          <li >
            <span >
              For day time delivery, delivery time ranges from 10 AM to 8 PM
            </span>
          </li>
          <li >
            <span >
              For midnight orders, delivery could be made anytime between 11:00
              PM to 12:30AM.
            </span>
          </li>
          <li >
            <span >
              In case of remote locations/small towns it can be made from 10PM
              to 12:30 AM.
            </span>
          </li>
          <li >
            <span >
              No refund will be made in case orders fails to be delivered within
              provided time slot.
            </span>
          </li>
          <li >
            <span >
              Only one attempt for delivery will be made. Please confirm
              availability of recipient before choosing delivery time. If
              recipient is not available at the time of delivery, second attempt
              will not be made and order will be cancelled. No refund will be
              made in such case.
            </span>
          </li>
          <li >
            <span >
              Delivery timings, if promised cannot be guaranteed in case of
              special circumstances like extreme weather conditions, riots,
              strikes, elections, bandhs, during rush days like Valentine's Day,
              Mother’s Day, Rakhi, New year Eve etc. The order cancellation
              would not be possible in such circumstances and order will be
              executed on the consecutive days.
            </span>
          </li>
          <li>
            <span >
              {" "}
              Special day deliveries are processed until 52 hours of requested
              delivery date and time slots.{" "}
            </span>
          </li>
        </ol>
        <p>
          <strong>Special days for Blissmygift are </strong>
          <span >
            - Mother’s day, Father’s Day, Diwali, Holi, Valentine’s Day, Rose
            day, Teddy day, Proposal Day, Hug day, Kiss Day, Friendship Day,
            Raksha Bandhan, Christmas and New Year.
          </span>
        </p>
        <h3>
          <strong>Delivery</strong>
          <strong>:</strong>
        </h3>
        <p className="text-justify"  >
          <span >
            Title and risk of loss for all products ordered by you shall pass on
            to you upon Blissmygift's shipment. Thereafter Blissmygift will not
            be responsible for any loss or damage to the product.
          </span>
        </p>
        <h3>
          <strong>
            If recipient receives the products in damaged condition
          </strong>
        </h3>
        <ol className="text-justify"  >
          <li >
            <span >
              Recipient should check the products delivered (cakes, flowers, any
              other)before signing the receipt.
            </span>
          </li>
          <li >
            <span >
              If product delivered found damaged, recipient should refuse to
              accept it and immediately inform Customer Care.
            </span>
          </li>
          <li >
            <span >
              Any issues with the products delivered should be reported within 4
              hours of receipt, after that no request of replacement or refund
              will be entertained.{" "}
            </span>
          </li>
          <li >
            <span >
              In case of badly damaged products, replacement will be provided.
              Kindly allow a minimum of 6 hours for the replacement.
            </span>
          </li>
          <li >
            <span >
              Courier product complaints should be informed within 48 hours
              after delivery. Complaints coming after 48 hours will not be
              undertaken.
            </span>
          </li>
        </ol>
        <p>
          <strong>Minimalistic and cosmetic damages like - </strong>
        </p>
        <ol  className="text-justify">
          <li >
            <span >
              Name/ message on cake not written.
            </span>
          </li>
          <li >
            <span >
              Name and message not attached with flowers.{" "}
            </span>
          </li>
          <li >
            <span >
              Cream of cake / decoration on cake / flowers packaging issues -
              will not be eligible for refunds/replacements until unless they
              are severely damaged.{" "}
            </span>
          </li>
        </ol>
        <h3>Keep as surprise</h3>
        <p className="text-justify"  >
          For orders where Keep as Surprise is selected we will deliver the
          order as requested without receiver knowing the sender’s detail. In
          case the receiver refuses to accept the order then we will disclose
          the sender’s details for the order to be acknowledged and accepted by
          the receiver. If still the receiver does not accept the order then the
          order will be returned and it will be considered as Complete from
          Blissmygift’s end. Due to time limitation and the gift items being of
          perishable nature therefore we will attempt our best to have the order
          delivered within a given timeframe.
        </p>
        <h3>
          <a name="cancelPolicy" style={{ color: "#000" }}>
            <strong>Cancellation Policy </strong>
          </a>
        </h3>
        <p>
          <span >
            Please read the below stated points for any cancellations.
          </span>
        </p>
        <p className="text-justify"  >
          <span >
            Please understand that the points mentioned in the policies are with
            reference to the{" "}
          </span>
          <strong>&nbsp;time slots</strong>
          <span > on the </span>
          <a href="https://www.blissmygift.com">
            <span >www.blissmygift.com</span>
          </a>
          <span >
            {" "}
            website selected by the user in checkout process. &nbsp;
          </span>
        </p>
        <p className="text-justify"  >
          <span >
            Orders are processed in time slots and we accept cancellation
            requests with reference to time slot selected by customer in order
            placed. Consider conditions stated below -
          </span>
        </p>
        <ol  className="text-justify">
          <li >
            <span  className="text-justify"  >
              If the cancellation request comes to Blissmygift before 24 hours
              to the start of time slot selected - 10% of order value is charged
              as cancellation charges. 90% refund to Bank Account. NOTE - 100%
              refund can be requested in Blissmygift wallet, where we waive off
              cancellation charges.{" "}
            </span>
          </li>
          <li >
            <span className="text-justify"   >
              If the cancellation request comes to Blissmygift between 12 - 24
              hours before the start of time slot selected then - 20% of order
              value will be deducted as cancellation charges. 80% refund to Bank
              Account. NOTE - 100% refund can be requested in Blissmygift
              wallet, where we waive off cancellation charges.{" "}
            </span>
          </li>
          <li >
            <span className="text-justify"   >
              If cancellation is request comes to Blissmygift within 12 hours to
              the start of time slot selected - 100% of order value will be
              deducted as cancellation charges. 0% refund to Bank account or 0%
              to Blissmygift wallet applicable.
            </span>
          </li>
        </ol>
        <p className="text-justify"  >
          <span >
            In any of the cases above Wallet refunds and Bank refunds can be
            initiated only at the discretion of company considering the reasons
            of cancellation like unfortunate events, calamities or unavoidable
            circumstances. No claims to refund can be made by customer to
            overrule above stated clause.{" "}
          </span>
        </p>
        <p>
          <span >
            {" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </p>
        <p>
          <strong>NO cancellation requests</strong>
          <span >
            {" "}
            are accepted on order placed in following categories.
          </span>
        </p>
        <ol  className="text-justify">
          <li className="text-justify"   >
            <strong>Same day delivery category </strong>
            <span >
              - If your date of placing an order and delivery date of that order
              are same, we do not accept cancellation request. If you cancel it,
              or receiver does not receive it - you are{" "}
            </span>
            <strong>not </strong>
            <span >entitled to any refund. </span>
          </li>
          <li  className="text-justify"    >
            <strong>Same day mid-night</strong>
            <span >
              {" "}
              delivery orders can not be cancelled.{" "}
            </span>
          </li>
          <li className="text-justify"  >
            <strong>Special day orders</strong>
            <span >
              {" "}
              can not be cancelled and no refund is initiated for them.{" "}
            </span>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p  className="text-justify" >
          <strong>Same day Orders</strong>
        </p>
        <p  className="text-justify" >
          <span >
            We allow users to select the same date of delivery on which he/she
            is placing the order i.e. order delivery and order placed date is
            same. We attempt to do same day delivery of the requested products
            but{" "}
          </span>
          <strong>DO NOT PROMISE </strong>
          <span >so. </span>
        </p>
        <p  className="text-justify" >
          <span >
            We will process same day delivery orders
          </span>
          <strong> within 24 hours of requested date and time slot. </strong>
        </p>
        <ol  className="text-justify">
          <li   className="text-justify" >
            <span >
              Blissmygift takes same day orders for day and evening time slots
              till 5 pm. It is attempt to do same day delivery and not a
              promise, though we make our 100% effort to deliver on the same
              date as order placed.
            </span>
          </li>
          <li  className="text-justify"  >
            <span >
              Blissmygift takes order for same day mid-night (00:00) delivery
              till 7:00 pm. It is attempt to do same day mid-night delivery and
              not a promise, though we make our 100% effort to deliver on the
              same date as order placed.
            </span>
          </li>
        </ol>
        <h3>
          <strong>
            <a name="refundPolicy" style={{ color: "#000" }}>
              {" "}
              Refund Policy
            </a>
          </strong>
        </h3>
        <p  className="text-justify" >
          <span >
            Refund in cases of Cancellations are mentioned in Cancellation
            policy, kindly refer the same.{" "}
          </span>
        </p>
        <p  className="text-justify" >
          <span >
            The acceptance of order is subject to availability of products,
            delivery areas and time. The order may or may not be accepted. The
            order might be cancelled after assessing the circumstances and
            communicated to customer. In such case amount paid by customer is
            fully refunded to Blissmygift wallet or bank account.{" "}
          </span>
        </p>
        <p  className="text-justify" >
          <span >
            Only one attempt for delivery will be made. Please confirm
            availability of recipient before choosing delivery time. If
            recipient is not available at the time of delivery, second attempt
            will not be made and order will be cancelled. No refund will be made
            in such case.
          </span>
        </p>
        <p  className="text-justify" >
          <span >
            The refunds are only initiated once the customer confirms over mail
            that where the refund has to be done - Blissmygift wallet or bank
            account.{" "}
          </span>
        </p>
        <p  className="text-justify" >
          <span   className="text-justify" >
            Refund will be credited in Blissmygift Wallet on orders which are
            processing within 48 hours of delivery date
          </span>
        </p>
        <p  className="text-justify" >
          <span >
            Refunds are processed within 30 days period.
          </span>
        </p>
        <p>
          <strong>Address Change requests</strong>
        </p>
        <p  className="text-justify" >
          <span   className="text-justify" >
            Change in the address is permitted free of cost only before 24 hours
            of delivery.
          </span>
        </p>
        <ol  className="text-justify">
          <li  className="text-justify"  >
            <span >
              Within 24 hours period before delivery , if address is changed we
              charge Rs 200/-.
            </span>
          </li>
          <li   className="text-justify" >
            <span >
              Sometimes the address change request can not be accepted, in that
              case order will not be refunded or cancelled.{" "}
            </span>
          </li>
        </ol>
        <h3>
          <strong>Pincode Requirements</strong>
        </h3>
        <p  className="text-justify" >
          <span >
            It is user responsibility to provide correct pincode in the address
            of delivery. The pin code should map to the correct delivery
            address. Sometimes address mentioned does not correspond to the the
            pin code of that area.{" "}
          </span>
        </p>
        <p  className="text-justify" >
          <span >
            If pin code entered in the address section is incorrect then
            following cases arise -{" "}
          </span>
        </p>
        <ol  className="text-justify">
          <li  className="text-justify"  >
            <span >
              Address entered is of Non-Serviceable area, the order stands
              cancelled and NO refund will be initiated - The system checks
              pincode for delivery verification and may accept order when
              pincode entered is of serviceable area and address is not. &nbsp;
            </span>
          </li>
          <li   className="text-justify" >
            <span >
              If our team contacts you regarding wrong pin in address, and you
              want to get delivery done to new pin code, the charges of the
              newly added pin code will apply. The order can not be cancelled
              and refunded in case you do not wish to pay for correct pin code’s
              charges.{" "}
            </span>
          </li>
        </ol>
        <h5>
          <strong>Important Points</strong>
        </h5>
        <p  className="text-justify" >
          <span >
            The products and services displayed on Blissmygift are our best
            offerings and we put in our 100% effort to deliver high quality
            similar products and services. These terms and conditions are put
            across to avoid any possible conflict. &nbsp;
          </span>
        </p>
        <ol  className="text-justify"s>
          <li  className="text-justify"  >
            <span >
              All products listed on the website, their descriptions, and their
              prices are subject to change, time to time according to the
              various conditions prevailing in the market.
            </span>
          </li>
          <li   className="text-justify" >
            <span >
              Orders are accepted and delivered in{" "}
            </span>
            <strong>TIME SLOTS</strong>
            <span >
              {" "}
              which are available in your selected city. All requests are
              processed with reference to time slot of your order.
            </span>
          </li>
        </ol>
        <ol  className="text-justify">
          <li >
            <span    className="text-justify" >
              For midnight orders, delivery could be made anytime between 11:00
              PM to 12:30 AM. In case of remote locations/small towns it can be
              made from 10PM to 12:30 AM.
            </span>
          </li>
          <li >
            <span  className="text-justify"   >
              Blissmygift reserves the right, to modify, suspend, or discontinue
              the sale of any product or services with or without prior notice
              at any time.
            </span>
          </li>
          <li >
            <span   className="text-justify" >
              The product specifications (weight, size, colour etc.) mentioned
              with the product photos displayed are only approximate and
              indicative. There may be a slight variation in the pictures and
              the products delivered.
            </span>
          </li>
          <li >
            <span  className="text-justify"  >
              The acceptance of order is subject to availability of products,
              delivery areas and time. The order may or may not be accepted. The
              order might be cancelled after assessing the circumstances and
              communicated to customer. In such case amount paid is fully
              refunded.
            </span>
          </li>
          <li >
            <span  className="text-justify"  >
              In case we detect fraudulent behaviour, we are liable to cancel
              the order at our discretion and we will not be refund money in
              such cases.
            </span>
          </li>
          <li >
            <span   className="text-justify" >
              If the duplicate order with the similar details are placed, we
              will try to contact the customer, and in case of no response from
              the customer, we will process both the order. Any argument risen
              after that will not be entertained.
            </span>
          </li>
          <li >
            <span   className="text-justify" >
              There is no guarantee of delivery of the products in the time
              slots mentioned on website in case of midnight delivery on special
              occasions like New Year, Christmas, Valentine Day. Mother’s Day
              etc.The orders will be processed and delivered up to 52 hours of
              requested date and time slot of order.{" "}
            </span>
          </li>
          <li >
            <span >
              There is no cancellation for orders placed under the ‘
            </span>
            <strong>Same Day Delivery’</strong>
            <span > category.</span>
          </li>
          <li >
            <span   className="text-justify" >
              Only one attempt for delivery will be made. Please confirm
              availability of recipient before choosing delivery time. If
              recipient is not available at the time of delivery, second attempt
              will not be made and order will be cancelled. No refund will be
              made in such case.
            </span>
          </li>
          <li >
            <span  className="text-justify"   >
              For rescheduling order, you need to inform us at-least 24 hours
              before delivery date.
            </span>
          </li>
          <li >
            <span   className="text-justify" >
              Recipient should check the order before signing the receipt, If
              found damaged, recipient should refuse to accept the order and
              delivery person will return with a replacement. Kindly allow a
              minimum of 6 hours for the replacement.
            </span>
          </li>
          <li >
            <span  className="text-justify"  >
              Execution of the order will be considered successful and complete
              in cases like Wrong Address mentioned by sender, premises where
              delivery has to be made is locked, recipient of order is not
              available, and recipient has refused to receive the order due to
              personal reasons ( NOT due to products or services of
              Blissmygift).{" "}
            </span>
          </li>
          <li >
            <span  className="text-justify"  >
              Execution of the order will be considered successful and complete
              in case of permission not granted to our delivery executive to
              enter the delivery location. For example the orders where delivery
              locations are premises like hospitals, Govt agencies, societies ,
              resorts hotels, any personal or public property, etc. and the
              recipient number is not reachable.
            </span>
          </li>
          <li >
            <span  className="text-justify"  >
              Delivery timings, if promised cannot be guaranteed in case of
              special circumstances like extreme weather conditions, riots,
              strikes, elections, bandhs, during rush days like Valentine's Day,
              Mother’s Day, Rakhi, New year Eve etc. The order cancellation
              would not be possible in such circumstances and order will be
              executed on the consecutive days.
            </span>
          </li>
          <li >
            <span  className="text-justify"  >
              In case you feel that the product received does not meet your
              expectations, you must bring it to the notice of our customer
              service within 4 hours of receiving the product. The Customer
              Service Team after looking into your complaint will take an
              appropriate decision.{" "}
            </span>
          </li>
        </ol>
      </div>
    </main>
    {/* end MAIN */}
  </div>
  {/* FOOTER */}
  <Footer/>

</div>


        
        </>
    )


}

export default TermsAndConditions;