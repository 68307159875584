import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import InnerBackHeader from "../layouts/InnerBackHeader";
import { NavLink, Navigate } from "react-router-dom";
import ProfileCurrentOrder from "./ProfileCurrentOrder";
import ProfilePastOrder from "./ProfilePastOrder";
import { getUserId } from "../../Token";
import { ProfileApi } from "../../apis/ProfileApi";
import Loader from "../layouts/Loader";
function OrderList() {
  // const [ viewCurrentOrder, setViewCurrentOrder] = useState(true);
  // const [ viewPastOrder, setViewPastOrder] = useState(false)
  const [currentTab, setCurrentTab] = useState("currentOrders");
  const [orderList, setOrderList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [activeData, setActiveData] = useState({
    user_id: getUserId(),
    tab_name: currentTab,
    // user_id: 7
  });
  let navigate = useNavigate();

  useEffect(() => {
    getOrderList(activeData);
  }, []);
// {console.log(activeData)}


const handleViewInvoice=(ID)=>{
  let path = `/order-details/` + ID;
  navigate(path);
  
}
 
  const getOrderList = (activeData) => {
    setLoader(true);
    ProfileApi.pastOrderList(activeData)
      .then((res) => {
        if (res.data.status) {
          setOrderList(res.data.data);
        } else {
          setOrderList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
      setLoader(false);
     
  };
  const activeTab = (tabname) => {
    setCurrentTab(tabname);
    setActiveData({
      user_id: getUserId(),
      tab_name: tabname,
    });
    getOrderList({ user_id: getUserId(), tab_name: tabname });
  };

  const orderTracking =(ID)=>{
    let path = `/order-tracking/` + ID;
    navigate(path);
  }
  return (
    <>
      <InnerBackHeader isLogo={true} />
      {loader && <Loader />}
      <div className="container myOrderModal ">
        <div>
          <div className="col">
            <div className="row hideDesktopOrder">
              <div
                className="col s12 modal-trigger"
                id="showAcc"
                style={{
                  marginTop: 8,
                  padding: "10px 6px",
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow: "0px 2px 4px #0000001A",
                }}
              >
                <span className="iconLeft ft-25  logo-color">
                  <i class="fa fa-gift" aria-hidden="true"></i>
                </span>{" "}
                <span className="myAccount logo-color">My Orders</span>{" "}
                {/* <span className="iconRight ft-20">
            <i class="fa fa-chevron-down logo-color" aria-hidden="true"></i>
            </span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* list section */}
      <div>
        <div>
          <div className="col    ">
            {/*  nav-tabs */}
            <ul className="nav col text-center">
              <li
                className={`active col-xs-6  p-15 ${
                  currentTab == "currentOrders" ? "login-hover-effect" : ""
                }`}
                onClick={() => activeTab("currentOrders")}
              >
                <a
                  data-toggle="tab"
                  href="#currentOrders"
                  className="hover-underline-animation no-padding"
                >
                  Current Orders
                </a>
              </li>
              <li
                className={` col-xs-6  p-15  ${
                  currentTab == "pastOrders" ? "login-hover-effect" : ""
                }`}
                onClick={() => activeTab("pastOrders")}
              >
                <a data-toggle="tab" href="#pastOrders" className="no-padding">
                  Past Order
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {orderList && orderList.length > 0 ?  orderList.map((item, i) => {
          return (
            
            <div className="row hideDesktopOrder">
            
              <div
                className="col s12 modal-trigger"
                id="showAcc"
                style={{
                  marginTop: 8,
                  // padding: "10px 6px",
                }}
              >
                <div className="row cart_summary cart-product-box ">
                  <div className="col-xs-12 row">
                    <div className="">
                      <span className="text-bold "> Order ID : </span>
                      <span className="logo-color"> {item.id}</span>

                      <span className="text-bold  text-justify float-right">
                        {" "}
                        ₹ {item.total}/-{" "}
                      </span>
                    </div>
                  </div>

                  <div className="col-xs-12">
                    <div style={{ fontSize: 12, color: "#666666" }}>
                      {item.order_placed_on}
                    </div>
                  </div>

                  <div className="row "> 
                  <div className="col-xs-9">
                    {currentTab === "currentOrders" && item.current_order_status !== "CANCELLED" ? 
                      <div className="btn-small order-button mr-10" onClick={() => orderTracking(item.id)}>
                        <span className="order-text-center">Tracking</span>
                      </div>
                      : <></>
                    }
                  </div>



                  {item.current_order_status === "PENDING" ? (
                    <div className="col-xs-3">
                      <div className="btn-small order-button  order-pending" onClick={()=>handleViewInvoice(item.id)}>
                        <span className="order-text-center" >Pending</span>
                      </div>
                    </div>
                  ) : item.current_order_status === "DELIVERED" ? (
                    <div className="col-xs-3">
                      <div className="btn-small order-button order-Delivered"  onClick={()=>handleViewInvoice(item.id)}>
                        <span className="order-text-center">Delivered</span>
                      </div>
                    </div>
                  ) : item.current_order_status === "CANCELLED" ? (
                    <div className="col-xs-3">
                      <div className="btn-small order-button order-canceled"  onClick={()=>handleViewInvoice(item.id)}>
                        <span className="order-text-center">Cancelled</span>
                      </div>
                    </div>
                  ) : <div className="col-xs-3">
                  <div className="btn-small order-button order-pending"  onClick={()=>handleViewInvoice(item.id)}>
                    <span className="order-text-center"> {item.current_order_status}</span>
                  </div>
                </div>}

                </div>
                </div>
              </div>
            </div>
          );
        }) 
        :       
        
        <div  className="noRecordFound" > 
         No record found.
         
        </div> }

      
      

    
    </>
  );
}
export default OrderList;
