import React from "react";
import InnerBackHeader from "../pages/layouts/InnerBackHeader";
import Footer from "../pages/layouts/Footer";
import { useState } from "react";
import { NavLink } from "react-router-dom";
function FAQs() {

  const [activeIndexes, setActiveIndexes] = useState({});
  const faqData = [
    {
      question: "Will you deliver fresh cakes?",
      answer: `A person can enjoy the actual taste of cake only when it's fresh, this is why all the cakes we deliver are prepared only some time before it reaches to the recipient. To achieve the same, we offer customers to choose delivery time as well, so that we know when we need to prepare a cake and get it delivered. We ensure that customer always enjoys the freshest possible cake. We do not believe in delivering stale cakes.`,
    },
    {
      question: "How can I trust the quality of cake delivered by BlissmyGift?",
      answer: `We procure cakes from top and renowned bakeries in town. This help us to ensure that customer always get quality cakes and consistent taste. We promise to deliver quality products when ordering from BlissmyGift.`,
    },
    {
      question:
        "Does BlissmyGift provide midnight delivery as well? Is there any extra charge for it?",
      answer: `Yes, we do provide midnight delivery. The midnight delivery time is from 11:00 PM to 11:59 PM. Although we try our best to deliver order as close to 23:59 as possible, it is always suggested to have midnight orders placed well on time. We charge Rs. 250 extra for midnight delivery.`,
    },
    {
      question:
        "What if recipient is not available when delivery person reaches there? Will you re-attempt delivery?",
      answer: `We do not attempt to deliver again, but we would call recipient and sender to know if we can deliver their order to any neighbour or nearby place. Unable to do so will lead to cancellation of order and no refund will be made in case of perishable products like cakes, chocolates and flowers.`,
    },
    {
      question: "While placing order, why do I see delivery time disabled?",
      answer: <>Sometimes we get large number of orders for delivery at a particular time slot. In this case, that particular time slot is disabled to prevent taking any more orders. If you need help in finding time slot for that day, please contact our  <NavLink to={"/contact-us"} style={{color: "#4285f4"}}> customer support</NavLink> </>,
    },
    {
      question: "Are there any delivery charges?",
      answer: `Delivery charges vary from location to location. When you provide the pin code of the delivery address, our website will show you the delivery charges for that particular location. Delivery charges range between Rs. 0 – 300 rupees.`,
    },
    {
      question:
        "Are there any hidden charges (Sales Tax) when I make a purchase on BlissmyGift?",
      answer: `There are NO hidden charges when you make a purchase on BlissmyGift. The prices listed for all the items are final and all-inclusive. The price you see is exactly what you need to pay. Delivery charges may be extra depending on the location and other parameters.`,
    },
    {
      question:
        "How do I know whether my gifts can be delivered at the address of my choice?",
      answer: <> We cover urban areas of around 500 cities of India. These cities include 1st Tier, 2nd Tier and 3rd Tier cities. Our team makes sure that we only commit to those services that we can offer like delivery time slots, expected time, delivery charges and more. You can check our <NavLink to={"/delivery-cities"} style={{color: "#4285f4"}}> delivery cities</NavLink>  page to find out whether your city of choice is covered by us or not.</>,
    },
    {
      question: "Do you take same day orders as well?",
      answer: `Yes, we do take same day orders till a fixed time on that particular day. It is always advisable to place same day orders well on time to avoid any delivery problem. The later you place the order, the fewer chances will be there to find an available time for delivery.`,
    },
    {
      question: "When and how can I cancel my order?",
      answer: `You can cancel your order 24 hours before delivery time. After that, order cancellation requests will not be entertained. If you placed an order whose delivery time is within 24 hours of order placing time then order can't be cancelled and no refund will be processed for the same. For cancelling your order, you need to send us an email on  online@blissmygift.com with the order id from the same email address which you have placed that order.`,
    },
    {
      question: "How long will it take to process my cancellation request?",
      answer: `Once you request the cancellation of item(s) in your order, it will take us a maximum of 1-2 business days to cancel the order and initiate a refund. You will be notified of the same by email. If you have paid online for your order, it may take 7-14 business days for the respective banks to process the refund. Please get in touch with the banks directly in case of any delays post confirmation of cancellation/refund by BlissmyGift.`,
    },
    {
      question: "Can I order a product that is 'Out of Stock'?",
      answer: `Unfortunately, products listed as 'Out of Stock' are not available for sale.`,
    },
    {
      question: "Is it necessary to have an account to shop on BlissmyGift?",
      answer: `You can shop on BlissmyGift by providing just your email address. While it is not necessary to have an account to shop for and purchase items, it is recommended that you create one. By creating your own BlissmyGift account, you can enjoy a personalized shopping experience, including recommendations, saved address and a quicker checkout.`,
    },
    {
      question: "How do I pay for products on BlissmyGift?",
      answer: `BlissmyGift offers you multiple payment options ranging from Credit Card, Debit Card, Net Banking from all major banks. Whatever be your online mode of payment, you can rest assured that BlissmyGift's trusted payment gateway partners use secure encryption technology to keep your transaction details confidential at all times. BlissmyGift accepts payments made using Visa, MasterCard, Maestro and American Express. (Cards issued by only Indian banks are supported at present).`,
      // For non-Indian customer, we provide PayPal option as well.
    },
  ];

  const toggleCollapse = (index) => {
    setActiveIndexes((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  return (
    <>
      <InnerBackHeader isLogo={true} />
      <div id="wrapper">
        {/* MAIN */}
        <main className="site-main home-product-main-div viewcartpadding">
          {/* Block  Breadcrumb*/}
          <div className="row">
            {/* Main Content */}
            <h2 className="page-heading">
              <span className="faq_header" >
                Frequently Asked Questions (FAQ)
              </span>
            </h2>
            <br />
            <div className="divider" />
            {/* <dl>
              <dt>1. Will you deliver fresh cakes?</dt>
              <dd className="text-justify">
                A person can enjoy the actual taste of cake only when it's
                fresh, this is why all the cakes we deliver are prepared only
                some time before it reaches to the recipient. To achieve the
                same, we offer customers to choose delivery time as well, so
                that we know when we need to prepare a cake and get it
                delivered. We ensure that customer always enjoys the freshest
                possible cake. We do not believe in delivering stale cakes.
              </dd>
              <br />
              <dt>
                2. How can I trust the quality of cake delivered by blissmygift?
              </dt>
              <dd className="text-justify">
                We procure cakes from top and renowned bakeries in town. This
                help us to ensure that customer always get quality cakes and
                consistent taste. We promise to deliver quality products when
                ordering from blissmygift.com
              </dd>
              <br />
              <dt>
                3. Does blissmygift provides midnight delivery as well? Is there
                any extra charge for it?
              </dt>
              <dd className="text-justify">
                Yes, we do provide midnight delivery. The midnight delivery time
                is from 11:00 PM to 11:59 PM. Although we try our best to
                deliver order as close to 23:59 as possible, it is always
                suggested to have midnight orders placed well on time. We charge
                Rs. 250 extra for midnight delivery.
              </dd>
              <br />
              <dt>
                4. What if recipient is not available when delivery person
                reaches there? Will you re-attempt delivery?
              </dt>
              <dd className="text-justify">
                We do not attempt to deliver again, but we would call recipient
                and sender to know if we can deliver their order to any
                neighbour or near by place. Unable to do so will lead to
                cancellation of order and no refund will be made in case of
                perishable products like cakes, chocolates and flowers.
              </dd>
              <br />
              <dt>
                5. While placing order, why do I see delivery time disabled?
              </dt>
              <dd className="text-justify">
                Sometimes we get large number of orders for delivery at a
                particular time slot. In this case, that particular time slot is
                disabled to prevent taking any more orders. If you are need help
                in finding time slot for that day, please contact our{" "}
                <a href="/contact-us">customer support</a>
              </dd>
              <br />
              <dt>6. Are there any delivery charges?</dt>
              <dd className="text-justify">
                Delivery charges varies from location to location. When you
                provide pin code of the delivery address, our website will show
                you the delivery charges for that particular location. Delivery
                charges ranges between Rs. 0 – 300 rupees.
              </dd>
              <br />
              <dt>
                7. Are there any hidden charges (Sales Tax) when I make a
                purchase on blissmygift?
              </dt>
              <dd className="text-justify">
                There are NO hidden charges when you make a purchase on
                blissmygift. The prices listed for all the items are final and
                all-inclusive. The price you see is exactly what you need to
                pay. Delivery charges may be extra depending on the location and
                other parameters.
              </dd>
              <br />
              <dt>
                8. How do I know whether my gifts can be delivered at address of
                my choice?
              </dt>
              <dd className="text-justify">
                We cover urban areas of around 500 cities of India. These cities
                include 1st Tier, 2nd Tier and 3rd Tier cities. Our team make
                sure that we only commit to those services that we can offer
                like delivery time slots, expected time, delivery charges and
                more. You can check our
                <a href="/delivery-cities">delivery cities</a> page to find out
                whether your city of choice is covered by us or not.
              </dd>
              <br />
              <dt>9. Do you take same day orders as well?</dt>
              <dd className="text-justify">
                Yes, we do take same day orders till a fixed time on that
                particular day. It is always advisable to place same day order
                well on time to avoid any delivery problem. The later you place
                order, less chances will be there to find an available time for
                delivery.
              </dd>
              <br />
              <dt>10. When and how can I cancel my order?</dt>
              <dd className="text-justify">
                You can cancel your order 24 hours before delivery time. After
                that, order cancellation requests will not be entertained. If
                you placed an order whose delivery time is within 24 hours of
                order placing time then order can't be cancelled and no refund
                will be processed for the same. For cancelling your order, you
                need to send us an email on{" "}
                <strong>info@blissmygift.com</strong> with the order id from the
                same email address which you have placed that order.
              </dd>
              <br />
              <dt>
                11. How long will it take to process my cancellation request?
              </dt>
              <dd className="text-justify">
                Once you request the cancellation of item(s) in your order, it
                will take us a maximum of 1-2 business days to cancel the order
                and initiate a refund. You will be notified of the same by
                email. If you have paid online for your order, it may take 7-14
                business days for the respective banks to process the refund.
                Please get in touch with the banks directly in case of any
                delays post confirmation of cancellation/refund by blissmygift.
              </dd>
              <br />
              <dt>12. Can I order a product that is 'Out of Stock'?</dt>
              <dd className="text-justify">
                Unfortunately, products listed as 'Out of Stock' are not
                available for sale.
              </dd>
              <br />
              <dt>
                13. Is it necessary to have an account to shop on blissmygift?
              </dt>
              <dd className="text-justify">
                You can shop on blissmygift by providing just your email
                address. While it is not necessary to have an account to shop
                for and purchase items, it is recommended that you create one.
                By creating your own blissmygift account, you can enjoy a
                personalized shopping experience, including recommendations,
                saved address and a quicker checkout.
              </dd>
              <br />
              <dt>14. How do I pay for products on blissmygift?</dt>
              <dd className="text-justify">
                blissmygift offers you multiple payment options ranging from
                Credit Card, Debit Card, Net Banking from all major banks.
                Whatever be your online mode of payment, you can rest assured
                that blissmygift's trusted payment gateway partners use secure
                encryption technology to keep your transaction details
                confidential at all times. blissmygift accepts payments made
                using Visa, MasterCard, Maestro and American Express. (Cards
                issued by only Indian banks are supported at present). For
                non-indian customer we provide PayPal option as well.
              </dd>
            </dl> */}
   <ul style={{ padding: 0, marginTop: "-5px", textAlign: "justify" }}>
        {faqData.map((faq, index) => (
          <li key={index}>
            <a
              className="collapsible-header"
              style={{ position: "relative", borderBottom: 0 }}
              tabIndex={0}
              data-toggle="collapse"
              href={`#dropDown${index}`}
              role="button"
              aria-expanded="false"
              aria-controls={`dropDown${index}`}
              onClick={() => toggleCollapse(index)}
            >
              <span
                style={{
                  fontSize: "27px",
                  marginTop: "-11px",
                  marginRight: "8px",
                }}
              >
                {activeIndexes[index] ? "-" : "+"}
              </span>
              <span
                style={{
                  paddingLeft: "1%",
                  fontSize: 17,
                  color: "black",
                  width: "90%",
                  fontWeight: "500",
                }}
              >
                {faq.question}
              </span>
            </a>
            <div className="collapse pl-15" id={`dropDown${index}`}>
              <ul style={{ padding: " 0px 8px 8px 18px" }}>
                <li>
                  <span className="inner-header">{faq.answer}</span>
                </li>
              </ul>
            </div>
          </li>
        ))}
      </ul>
            {/* <Faq/> */}
          </div>
        </main>
        {/* end MAIN */}
      </div>
      <Footer />
    </>
  );
}
export default FAQs;
