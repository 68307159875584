import React from "react";


function ProductDiscription(props){



 

    const productDetails = props.productDetails;
    const productDeliveryDetails = props.productDeliveryDetails;
    const productCareDetails = props.productCareDetails;
    
    const pDetailsWithoutTag = productDetails.replace(/<[^>]*>/g, '');
    const pDetailsDeliveryWithoutTag = productDeliveryDetails.replace(/<[^>]*>/g, '');
    const pDetailsCareWithoutTag = productCareDetails.replace(/<[^>]*>/g, '');
    

    return(
        <>
                  <div className="product-desc-main-div">
                   {(pDetailsWithoutTag || pDetailsDeliveryWithoutTag || pDetailsCareWithoutTag) &&
                   <div className="page-product-title">Product Description:</div>
                   }
                  <h5><strong>Country of Origin: India</strong></h5>
                  {pDetailsWithoutTag && (
                    <div className="col-sm-12  mt-15">
                      <p>Product Details:</p>
                      <div
                        className="discription"
                        dangerouslySetInnerHTML={{ __html: productDetails }}
                      />
                    </div>
                  )}
                  {pDetailsDeliveryWithoutTag && (
                    <div className="col-sm-12 mt-15">
                      <p>Delivery Information:</p>
                      <div
                        className="discription"
                        dangerouslySetInnerHTML={{
                          __html: productDeliveryDetails,
                        }}
                      />
                    </div>
                  )}

                  {pDetailsCareWithoutTag && (
                    <div className="mt-15 col-sm-12">
                      <p>Care Instructions:</p>
                      <div
                        className="discription"
                        dangerouslySetInnerHTML={{ __html: productCareDetails }}
                      />
                    </div>
                  )}
                </div>
        </>
    )
}


export default ProductDiscription;
