export const GET_BANNERS = "fetch-banners";
export const GET_HOME_PRODUCT = "fetch-home-products";
export const GET_CATEGORIES = "fetch-categories";
export const GET_CATEGORY_PRODUCT = "fetch-category-product";
export const GET_PRODUCT_DETAILS = "fetch-product-details";
export const CHECK_PINCODE = "check-pincode";
export const GET_DELIVERY_TYPE = "fetch-delivery-type";
export const GET_DELIVERY_TIME = "fetch-delivery-time";
export const ADD_TO_CART = "add-to-cart";
export const FETCH_VIEW_CART = "view-cart";
export const FETCH_VIEW_CART_COUNT = "view-cart-count";
export const GET_CITIES = "fetch-cities";
export const GET_ALL_FEEDBACK = "fetch-all-feedback";
export const GET_COUNTRY_CODE = "get-country-code";
export const CHECK_LOGIN = "user/login";
export const CHECK_VERIFY = "user/login-verify";
export const CHECKOUT = "checkout";
export const PAYMENT_MODE_UPDATE = "update-payment-mode";
export const GET_ORDER_BY_ID = "order/get-order-by-id";
export const ADDONS_ADD_TO_CART = "add-to-cart-addons";
export const REMOVE_CART_ITEM = "remove-cart-item";
export const UPDATE_CART_ITEM = "update-cart-item";
export const GET_RECEIVER_BY_ID = "receiver-details";
export const PAYMENT_STATUS_UPDATE = "update-payment-status";





export const REGISTRATION = "user/registration";
export const FORGOT_PASSWORD = "user/forgot-password";


export const CURRENT_ORDER_LIST = "profile/current-order-list";
export const PAST_ORDER_LIST = "profile/past-order-list";
export const ORDER_DETAILS = "profile/order-details";
export const UPDATE_PASSWORD = "profile/update-password";

// search bar product pai
export const GET_SEARCH_POPULAR = "fetch-search-popular";
export const SEARCH_LIST = "get-search-list"

// Search Unknon Porducts
export const SEARCH_PRODUCT ="fetch-search-products"

// customer review
// export const CUSTOMER_REVIEW ="fetch-customer-review"
 export const CUSTOMER_REVIEW ="fetch-product-review"



// customer Image 
export const CUSTOMER_IMG ="upload-cust-product-photo"

export const PRODUCT_OFFER ="fetch-offers"

export const APPLY_COUPON_CODE ="apply-coupon"

export const REMOVE_COUPON_CODE ="remove-coupon"

export const SUBMIT_REVIEW = "submit-review";

export const GET_ORDER_TRACKING_HISTORY = "order/order-history";
