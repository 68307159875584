import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { getUserId } from "../../../Token";
function CustomerRating(props) {
  const product_id = props.productId;
  const customerRating = props.customerRating;
  const navigate = useNavigate();
  
  

 
  return (
    <>
      <div className="row mb-2">
        <div
          className="col-xs-6 page-product-title mt-15 mb-15 no-padding"
          id="customer_review"
        >
          Product Reviews
        </div>

        <div className="col-xs-6  mt-15 mb-15 text-right">
          {customerRating.length > 5 &&
              <NavLink to="/customer-reviews" className="review-view-all">
              {" "}
              VIEW ALL
            </NavLink>
          }
         
        </div>
      </div>
      <div className="">
        <div className="">
          {" "}
          {/*  contentScroller */}
          {customerRating.map((review, i) => {
            return (
              <div key={i}>
                <div className="col s12 m12 l12 comment-1">
                  <span className="comment-2">
                    <span className="comment-3"> {review.star} </span>
                    <strong className="left-rating-star">★</strong>
                  </span>
                  <span className="comment-4"> {review.customer_name} </span>
                  <div className="comment-5">
                    <span>Reviewed on </span>
                    {review.review_date}
                  </div>
                  <div className="review-text text-justify">
                    {review.feedback}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <br />
      </div>

    </>
  );
}

export default CustomerRating;
