import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate,NavLink, Link } from 'react-router-dom';
import InnerBackHeader from '../layouts/InnerBackHeader';
import BottomNav from '../layouts/BottomNav';
import { HomeApi } from "../../apis/HomeApi";
import * as Yup from "yup";
import { ProfileApi } from "../../apis/ProfileApi";
import { getUserId,setUserInfo,setUserId,setCartId, getCartId } from "../../Token";
import config from "../../config";
import ProfileSideBar from './components/ProfileSideBar';


function ProfileCurrentOrder() {

    const [activeData, setActiveData] = useState({
      user_id: 7//getUserId(),
    });
    const [orderList, setOrderList] = useState([]);


    let navigate = useNavigate(); 
     useEffect(() => {
      getOrderList(activeData);
    }, []);

  const getOrderList = (activeData) => {
    ProfileApi.currentOrderList(activeData)
      .then((res) => {
        if (res.data.status) {
          setOrderList(res.data.data);
        } else {
          setOrderList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    };


    return (
        <>
    {/* <InnerBackHeader /> */}

  <>
  <main className="site-main">
    <div className="columns container  home-product-main-div viewcartpadding">
      {/* Block  Breadcrumb*/}
      {/* <ol className="breadcrumb no-hide">
        <li>
          <a href="#">Home </a>
        </li>
        <li className="active"> My Account</li>
      </ol> */}
      {/* Block  Breadcrumb*/}
      <div className="page-content page-order">
        <div className="order-detail-content">
          <div className="row profile">
            <div className="col-md-3">
              {/* END SIDEBAR USERPIC */}
              {/* SIDEBAR USER TITLE */}
              {/* <div className="profile-usertitle-name text-capital">Welcome</div> */}
              {/* END SIDEBAR BUTTONS */}
              {/* SIDEBAR MENU */}
             {/* <ProfileSideBar/> */}
              {/* END MENU */}
            </div>
            <div className="col-md-12">
                <div className="table-responsive">
                    <table className="table table-bordered">
                    <thead>
                        <tr>
                        <th scope="col">Order ID</th>
                        <th scope="col">Order Date</th>
                        <th scope="col">Delivery Date</th>
                        <th scope="col">Delivery Time</th>
                        <th scope="col">Recipient Name</th>
                        <th scope="col">Recipient Contact</th>
                        </tr>
                    </thead>
                    <tbody></tbody>
                    <tbody>

                        {orderList && orderList.map((item, i) => {
                        return (
                        <tr>
                        <th scope="row">{item.id}</th>
                        <td>{item.order_placed_on}</td>
                        <td>{item.delivery_date}</td>
                        <td>{item.delivery_time}</td>
                        <td>{item.recipient_first_name} {item.recipient_last_name}</td>
                        <td>{item.recipient_contact_no}</td>
                        <td>
                            <NavLink to={`/order-details/${item.id}`}>
                            View Order
                            </NavLink>
                        </td>
                        </tr>
                        );
                       })}


                    </tbody>
                    </table>
                </div>
                </div>

          </div>
        </div>
      </div>
    </div>
  </main>
  {/* end MAIN */}
  <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        "\n/* Profile container */\n.profile {\n  margin: 20px 0;\n}\n/* Profile sidebar */\n.profile-sidebar {\n  padding: 20px 0 10px 0;\n  background: #fff;\n}\n.profile-userpic img {\n  float: none;\n  margin: 0 auto;\n  width: 50%;\n  height: 50%;\n  -webkit-border-radius: 50% !important;\n  -moz-border-radius: 50% !important;\n  border-radius: 50% !important;\n}\n.profile-usertitle {\n  text-align: center;\n  margin-top: 20px;\n}\n.profile-usertitle-name {\n  color: #5a7391;\n  font-size: 16px;\n  font-weight: 600;\n  margin-bottom: 7px;\n}\n.profile-usertitle-job {\n  text-transform: uppercase;\n  color: #5b9bd1;\n  font-size: 12px;\n  font-weight: 600;\n  margin-bottom: 15px;\n}\n.profile-userbuttons {\n  text-align: center;\n  margin-top: 10px;\n}\n.profile-userbuttons .btn {\n  text-transform: uppercase;\n  font-size: 11px;\n  font-weight: 600;\n  padding: 6px 15px;\n  margin-right: 5px;\n}\n.profile-userbuttons .btn:last-child {\n  margin-right: 0px;\n}\n.profile-usermenu {\n  margin-top: 30px;\n}\n.profile-usermenu ul li {\n  border-bottom: 1px solid #f0f4f7;\n}\n.profile-usermenu ul li:last-child {\n  border-bottom: none;\n}\n.profile-usermenu ul li a {\n  color: #93a3b5;\n  font-size: 14px;\n  font-weight: 400;\n}\n.profile-usermenu ul li a i {\n  margin-right: 8px;\n  font-size: 14px;\n}\n.profile-usermenu ul li a:hover {\n  background-color: #fafcfd;\n  color: #5b9bd1;\n}\n.profile-usermenu ul li.active {\n  border-bottom: none;\n}\n.profile-usermenu ul li.active a {\n  color: #5b9bd1;\n  background-color: #f6f9fb;\n  border-left: 2px solid #5b9bd1;\n  margin-left: -2px;\n}\n/* Profile Content */\n.profile-content {\n  padding: 20px;\n  background: #fff;\n  min-height: 460px;\n}    \n"
    }}
  />
</>

        </>


    );
}

export default ProfileCurrentOrder;
